<template>
	<div>
		<div v-if="orderStatus.READY !== status" class="order-card mb-4" :id="id"
			:class="{ 'overlay': isStatusChanging, 'pre-order': isPreorder }" @click="onCardClickHandler"
			:style="orderBackColor">
			<div v-if="hasErrorMessage" class="error-info-block">
				<p>{{ orderErrorMsg }}</p>
			</div>
			<div v-if="hasErrorMessage && isOpen" class="error-info-detail">
				<v-row no-gutters class="error-info-detail-row"
					v-for="(      error, idx      ) in Object.entries(this.order.error)      " :key="idx">
					<v-col class="error-info-detail-col" cols="4">{{ error[0] }}</v-col>
					<v-col class="error-info-detail-col" cols="8">{{ error[1] }}</v-col>
				</v-row>
			</div>
			<div>
				<p class="font-weight-bold" v-if="isPreorder">PRE-ORDER</p>
			</div>
			<v-row no-gutters>
				<v-col :cols="order.isCreatedInRKeeper && (order.statusId === 6 || order.statusId === 7) ? 7 : 8">
					<h3 class="order-title mb-1">{{ consumerName }}</h3>
					<span v-if="isOpen" class="order-phone">{{ phone }}</span>
				</v-col>

				<v-col :cols="courier !== null && aggregator === 'boltOrder' ? 3 : 4" class="text-center pr-2">
					<span class="order-title d-flex justify-end">#{{ orderNumber }}</span>
				</v-col>

				<v-col v-if="courier !== null && aggregator === 'boltOrder'" cols="1">
					<v-icon large color="blue">mdi-moped</v-icon>
				</v-col>

				<v-col v-if="order.isCreatedInRKeeper && (order.statusId === 6 || order.statusId === 7)" cols="1">
					<v-icon large color="red"> mdi-alert-circle</v-icon>
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="mt-2" cols="7">
					<p v-if="!isOpen" class="order-price-items">

						<v-chip v-if="status === 1" :class="consumerComment ? 'mr-1' : 'mr-4'" text-color="white"
							color="green">new
						</v-chip>
						<v-chip v-if="allergy_info.length" class="mx-1 " color="blue darken-2" text-color="white">
							<v-icon color="darken-2">mdi-medical-bag</v-icon>
						</v-chip>
						<v-chip v-if="isCommentExist" class="mx-1 mr-4" color="blue darken-2" text-color="white">
							<v-icon color="white darken-2"> mdi-comment-processing</v-icon>
						</v-chip>


						<span v-if="orderItems">{{ orderItems.length }}
							<span v-if="orderItems.length < 2">item</span>
							<span v-else>items</span>
						</span>
						<span v-if="aggregator === 'boltOrder'" class="price">
							<span v-if="+originalPrice > 0">
								<i>&nbsp;{{ formatPrice(originalPrice) }} &nbsp;</i>&nbsp;{{ formatPrice(price)
								}}&nbsp;<span class="text-uppercase">{{ priceCurrency }}</span>
							</span>
							<span v-else>
								{{ formatPrice(price) }}&nbsp;<span class="text-uppercase">{{ priceCurrency }}</span>
							</span>
						</span>
						<span v-if="['woltOrder', 'fudyOrder'].includes(aggregator)">
							<span v-if="price">
								{{ formatPrice(price) }} <span class="text-uppercase">{{ priceCurrency }}</span>
							</span>
						</span>

						<span v-if="['glovoOrder'].includes(aggregator)">
							<span v-if="price">
								{{ formatPrice(price) }} <span class="text-uppercase">{{ priceCurrency }}</span>
							</span>
						</span>
					</p>
					<div class="order-type d-flex align-center mb-2">
						<img :src="require(`@/assets/images/${logo}.svg`)" alt="" />
						<h4 class="text-capitalize">{{ deliveryHow }}</h4>
						<v-icon
							v-if="order[aggregator].payment_type === 'cash' && order[aggregator].dine_in_details && order[aggregator].payment_method === 'cash'"
							large color="green">mdi-cash</v-icon>
					</div>
					<div class="d-flex align-center">
						<span>{{ order.venue.instanceDisplayName }}</span>
					</div>
				</v-col>
				<v-col class="d-flex flex-column justify-start align-end">
					<template v-if="pickupEta">
						<v-progress-circular v-if="[1, 2, 10, 11].includes(status)" :rotate="-90" :size="50" :width="6"
							:value="progressTime.diffValue" :color="timerColor" class="float-right mr-2">
							{{ progressTime.showValue }}
						</v-progress-circular>
					</template>
					<span class="mt-2" v-if="timeToShow">{{ timeToShow }}</span>
					<template v-if="isAsap">
						<span class="mt-2 font-weight-bold">ASAP</span>
					</template>
					<span class="mt-7" v-if="entityOrderFields?.table">Table: <b>{{ entityOrderFields?.table
							}}</b></span>
				</v-col>
			</v-row>

			<Button class="mb-4 mt-3" :text="deliveryWhen === 'preorder' ? 'Confirm' : 'Accept'"
				:isDisabled="isStatusChanging || isAcceptDisabled" @handleClick="editOrderStatus"
				v-if="orderState.BAD !== state && orderStatus.RECEIVED === status && page === 1" />

			<Button class="mt-3" text="Edit" :isDisabled="isStatusChanging" @handleClick="editOrderStatus"
				v-if="(orderStatus.PREORDER === status || orderStatus.PREORDER === status) && page === 2 && isOpen" />

			<Button class="mt-3" text="Confirm" :isDisabled="isStatusChanging" @handleClick="confirmPreorder"
				v-if="orderStatus.PREORDER === status && page === 2 && isOpen" />

			<div v-if="page == 1 || (page == 2 && status === 10)">
				<div v-if="page == 1">
					<Button class="mb-4 mt-3" text="Ready" :isDisabled="isStatusChanging"
						v-if="orderStatus.PRODUCTION === status" @handleClick="editOrderStatus" />
					<template
						v-if="(orderStatus.RECEIVED === status || orderStatus.PREORDER === status) && orderState.BAD === state">
						<v-row>
							<v-col v-if="!order.isCreatedInRKeeper || state === 2">
								<Button class="mb-4" text="Retry" :isDisabled="isStatusChanging"
									@handleClick="retryValidateOrder" />
							</v-col>
							<v-col>
								<Button class="mb-4" text="Force" :isDisabled="isStatusChanging"
									@handleClick="forceOrder" />
							</v-col>
						</v-row>
						<Button class="mb-4" text="Reject" styleType="danger" :isDisabled="isStatusChanging"
							@handleClick="rejectOrderHandler" v-if="orderStatus.PRODUCTION !== status" />
					</template>
				</div>

				<div v-if="isOpen" class="my-4">
					<Button class="mb-4" text="Reject" styleType="warning" :isDisabled="isStatusChanging"
						@handleClick="rejectOrderHandler"
						v-if="orderState.BAD !== state && orderStatus.PRODUCTION !== status && page === 1" />
					<p class="mt-4 order-price-items">
						<span v-if="orderItems">{{ orderItems.length }}
							<span v-if="orderItems.length < 2">item</span>
							<span v-else>items</span>
						</span>
						<span v-if="price">{{ formatPrice(price) }} <span class="text-uppercase">{{ priceCurrency
								}}</span></span>
					</p>
					<div v-for="item in orderItems" :key="item.id" class="order-product-item mb-2">
						<span>{{ item.count || item.qty }} x </span><span>{{ item.name }}</span><span
							class="text-uppercase">
							({{ getItemPrice(item) }})
						</span>
						<div v-if="item.user_note" class="order-product-item-comment">{{ item.user_note }}</div>
						<template v-if="item.options.length > 0">
							<p class="pl-4 mt-2 mb-2">OPTIONS:</p>
							<p class="pl-4 mt-2 mb-2" v-for="option in item.options" :key="option.id">
								<span v-if="!(/\d\sx\s/.test(option.value) || /\d\sx\s/.test(option.name))">
									{{ option.count || option.qty }} x
								</span>
								<span>{{ option.value || option.name }}</span>
								<span class="text-uppercase"> ({{ getOptionPrice(option) }})</span>
							</p>
						</template>
					</div>
				</div>
			</div>
			<div v-if="isStatusChanging" class="overlay-loader">
				<v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
			</div>
		</div>

		<div v-if="orderStatus.READY === status" class="order-card-small mb-4 allow-click" @click="onCardClickHandler"
			:style="orderBackColor" :class="isStatusChanging ? 'overlay' : ''">
			<div>
				<p class="font-weight-bold" v-if="deliveryWhen === 'preorder'">PRE-ORDER</p>
			</div>
			<v-row class="mb-2" no-gutters>
				<v-col cols="8">
					<span class="order-title">#{{ orderNumber }}</span>
				</v-col>
				<v-col class="d-flex justify-end" cols="2">
					<div class="order-success-icon">
						<v-icon> mdi-check</v-icon>
					</div>
				</v-col>
				<v-col v-if="courier !== null && aggregator === 'boltOrder'" cols="2">
					<v-icon large color="blue">mdi-moped</v-icon>
				</v-col>
			</v-row>
			<h3 class="order-title mb-2">{{ consumerName }}</h3>
			<div class="order-type mb-2 d-flex align-center">
				<img :src="require(`@/assets/images/${logo}.svg`)" alt="" />
				<h4 class="text-capitalize">{{ deliveryHow }}</h4>
				<v-icon
					v-if="order[aggregator]?.payment_type === 'cash' && order[aggregator]?.dine_in_details && order[aggregator]?.payment_method === 'cash'"
					large color="green">mdi-cash</v-icon>
				<h4 class="ml-auto" v-if="entityOrderFields?.table">Table: <b>{{ entityOrderFields?.table }}</b></h4>
			</div>
			<div class="d-flex align-center">
				<span>{{ order.venue.instanceDisplayName }}</span>
			</div>
			<div v-if="tips?.value" class="d-flex align-center">
				<strong style="margin: 5px 5px 5px 0;"><v-icon>mdi-cash-plus</v-icon></strong> <span>{{ tips.value }} {{
					tips.currency }}</span>
			</div>
			<Button class="mb-2" text="Pick up" :isDisabled="isStatusChanging" v-if="orderState.GOOD === state"
				@handleClick="editOrderStatus" />
			<template v-else>
				<Button v-if="order.isCreatedInRKeeper" class="mb-2" text="Retry" :isDisabled="isStatusChanging"
					@handleClick="retryOrder" />
				<Button text="Force" :isDisabled="isStatusChanging" @handleClick="forceOrder" />
			</template>
			<div v-if="isStatusChanging" class="overlay-loader">
				<v-progress-circular :size="70" :width="5" color="primary" indeterminate></v-progress-circular>
			</div>
		</div>
		<ProductionTimeModal v-model="isModalOpen" :status="status" :order="order" :entityOrder="entityOrder"
			:entityOrderFields="entityOrderFields" :chosenCookingTime="order.cookingTimeInMinutes"
			:defaultCookingTime="defaultCookingTime" :isBoltOrWoltOrder="aggregator" @acceptModal="acceptModal"
			title="Production time" />
		<OrderInfoModal v-model="isOrderInfoModalOpen" :order="order" :entityOrder="entityOrder"
			:entityOrderFields="entityOrderFields" :page="page" :orderErrorMsg="orderErrorMsg"
			:isPrintBtnVisible="isPrintBtnVisible" :isAcceptDisabled="isAcceptDisabled" :courier="courier" :tips="tips"
			@infoModalEvent="infoModalHandler" />
		<v-dialog v-model="dineInModal" max-width="350">
			<v-card class="py-3 px-5 border-radius-5">
				<v-card-title class="text-h5">
					Confimation
				</v-card-title>

				<v-card-text class="font-weight-bold">
					Payment for this order must be collected by staff!
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<Button text="Ok" @handleClick="closeDineInModal" />
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import dayjs from '@/main.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ORDER_STATUS, ORDER_STATE, TOAST_TYPES } from '@/data';
import Button from '@/components/commonComponents/Button';
import ProductionTimeModal from './modals/ProductionTimeModal.vue';
import OrderInfoModal from './modals/OrderInfoModal.vue';
import apiMixin from '../mixins/api-mixin';
import dateFormat from '../mixins/date-format';
import { throttle } from '../utils';

export default {
	name: 'OrderCard',
	components: { Button, ProductionTimeModal, OrderInfoModal },
	props: {
		id: {
			type: String,
		},
		order: {
			type: Object,
		},
		draggable: {
			type: Boolean,
		},
		status: {
			type: Number,
			default: 0,
		},
		state: {
			type: Number,
		},
		page: {
			type: Number,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		colValue: {
			type: Number,
		},
		orderErrorMsg: {
			type: String,
			default: '',
		},
		isCreatedInRKeeper: {
			type: Boolean,
		},
		adjustedTime: {
			type: Number,
		},
	},
	mixins: [apiMixin, dateFormat],
	data() {
		return {
			isAcceptDisabled: false,
			isOpen: false,
			orderStatus: ORDER_STATUS,
			isStatusChanging: false,
			orderState: ORDER_STATE,
			consumerName: '',
			orderNumber: '',
			consumerComment: '',
			allergy_info: '',
			orderItems: [],
			price: '',
			originalPrice: '',
			phone: '',
			tips: {
				currency: '',
				value: ''
			},
			deliveryHow: '',
			deliveryWhen: '',
			priceCurrency: '',
			courier: null,
			isModalOpen: false,
			isOrderInfoModalOpen: false,
			buttonPressed: '',
			autoAcceptTime: 0, // seconds/minutes until date auto accept
			timerDiffSec: 0,
			updatedCookingTime: 0,
			defaultCookingTime: 0,
			pickupEta: 0,
			modifiedTime: 0,
			progressTime: {
				diffValue: 0,
				showValue: 0,
			},
			cookingTime: 0,
			dateTimeFormats: {
				dateOrder: 0,
				dateSeparator: '.',
				timeFormat: 0,
			},
			showExpiredAlert: false,
			expiredAlertMsg: '',
			entityOrder: {},
			entityOrderFields: {
				customerName: '',
				allergy_info: '',
				comment: '',
				phone: '',
				orderNumber: '',
				price: '',
				originalPrice: '',
				priceCurrency: '',
				pickupEta: '',
				delivery: {
					how: '',
					when: '',
				},
				table: ''
			},
			dineInModal: false,
			isCommentExist: false,
		};
	},
	mounted() {
		if (Object.prototype.hasOwnProperty.call(this.entityOrder, 'pre_order')) {
			this.pickupEta = this.entityOrder.pre_order.preorder_time;
		}
		if (Object.prototype.hasOwnProperty.call(this.entityOrder, 'pickup_eta')) {
			this.pickupEta = this.entityOrder.pickup_eta;
		}
		if (Object.prototype.hasOwnProperty.call(this.entityOrder, 'orderTime')) {
			this.pickupEta = this.entityOrder.orderTime;
		}
		if (this.order.orderExtraProperties && this.order.orderExtraProperties.Bolt_Final_Delivery_Time) {
			if (this.deliveryWhen === 'scheduled' || this.deliveryWhen === 'preorder') {
				this.pickupEta = this.order.boltOrder.due_datetime
			} else {
				this.pickupEta = this.order.orderExtraProperties.Bolt_Final_Delivery_Time
			}
		}

		let settings;

		if (Object.prototype.hasOwnProperty.call(this.getCurrentVenue, 'settings')) {
			settings = this.getCurrentVenue.settings.venueSelfSettings;
		}

		if (Object.prototype.hasOwnProperty.call(this.getCurrentRest, 'settings')) {
			settings = this.getCurrentRest.settings.venueSelfSettings;
		}
		if (settings) {
			this.timerDiffSec = settings.autoAcceptTimeInSeconds;
			this.cookingTime = this.order.cookingTimeInMinutes;
			this.defaultCookingTime = settings.maxCookingTimeInMinutes;

			if (this.orderStatus.RECEIVED === this.status) {
				if (settings.isAutoAcceptEnabled) this.timerDiffSec = settings.autoAcceptTimeInSeconds;
				else this.timerDiffSec = this.aggregator === 'boltOrder' ? 480 : 180;

				this.autoAcceptTime = Math.floor(
					dayjs.utc(this.order.createUtc).local().add(this.timerDiffSec, 'second').diff(dayjs()) / 1000,
				);
				this.progressTime.showValue = this.timerShowTime(this.autoAcceptTime);
				this.progressTime.diffValue = Math.floor(100 * ((this.timerDiffSec - this.autoAcceptTime) / this.timerDiffSec));
				this.timerTick('autoAcceptTime');
			}

			if (this.orderStatus.PREORDER === this.status) {
				this.updatePreorderTimer();
			}
			if (this.orderStatus.DELAYED === this.status) {
				const currentTime = dayjs().unix()
				let diffTime
				if (settings.alwaysDefaultDelayTime) {
					diffTime = dayjs(this.pickupEta).unix() - (settings.defaultDelayTimeInMinutes * 60)
				} else if (this.aggregator === 'boltOrder' && this.cookingTime <= 0) {
					diffTime = dayjs(this.pickupEta).unix() - (settings.defaultDelayTimeInMinutes * 60)
				} else {
					diffTime = dayjs(this.pickupEta).unix() - (this.cookingTime * 60)
				}
				this.autoAcceptTime = diffTime - currentTime

				this.progressTime.showValue = this.timerShowTime(this.autoAcceptTime);
				this.progressTime.diffValue = Math.floor(this.autoAcceptTime)
				this.timerTick('autoAcceptTime');
			}

			if (this.orderStatus.PRODUCTION === this.status) {
				let tillTime = dayjs.utc(this.pickupEta).local();
				let modTime = this.aggregator === 'boltOrder' ? dayjs.utc().local() : dayjs.utc(this.modifiedTime).local();

				this.timerDiffSec = tillTime.subtract(modTime).unix();
				this.autoAcceptTime = tillTime.subtract(dayjs()).unix();

				this.progressTime.showValue = this.timerShowTime(this.autoAcceptTime);
				this.progressTime.diffValue = Math.floor(
					100 * ((this.timerDiffSec - this.autoAcceptTime) / this.timerDiffSec),
				);
				this.timerTick('autoAcceptTime');
			}
		}
	},
	computed: {
		...mapGetters({
			getCurrentVenue: 'app/getCurrentVenue',
			getRoles: 'app/getRoles',
			getGeneralSettings: 'app/getGeneralSettings',
			getCurrentRest: 'app/getCurrentRest',
		}),
		orderBackColor() {
			return this.orderState.BAD === this.state ? 'background-color: #ffe6e6' : '';
		},
		timerColor() {
			let color = '';
			switch (this.status) {
				case 1:
					color = 'red';
					break;
				case 2:
					if (this.autoAcceptTime >= 300) return 'green';
					else if (this.autoAcceptTime < 300 && this.autoAcceptTime >= 60) return '#f5be3f';
					else return 'red';
				case 10:
					if (this.autoAcceptTime >= 300) return 'green';
					else if (this.autoAcceptTime < 300 && this.autoAcceptTime >= 60) return '#f5be3f';
					else return 'red';
				case 11:
					if (this.autoAcceptTime >= 300) return 'green';
					else if (this.autoAcceptTime < 300 && this.autoAcceptTime >= 60) return '#f5be3f';
					else return 'red';
			}
			return color;
		},
		isPrintBtnVisible() {
			if (this.getRoles && this.getRoles[0]) {
				return (
					(this.getRoles[0].toLowerCase() === 'manager' || this.getRoles[0].toLowerCase() === 'operator') &&
					this.getCurrentVenue.settings &&
					this.getCurrentVenue.settings.venueSelfSettings.isPrintOrderEnabled
				);
			} else return false;
		},
		aggregator() {
			if (Object.prototype.hasOwnProperty.call(this.order, 'woltOrder')) {
				return 'woltOrder';
			} else if (Object.prototype.hasOwnProperty.call(this.order, 'fudyOrder')) {
				return 'fudyOrder'
			} else if (Object.prototype.hasOwnProperty.call(this.order, 'glovoOrder')) {
				return 'glovoOrder'
			}
			return 'boltOrder';
		},
		logo() {
			if (this.aggregator === 'woltOrder') {
				return 'wolt';
			} else if (this.aggregator === 'fudyOrder') {
				return 'fudy';
			} else if (this.aggregator === 'boltOrder') {
				return 'bolt'
			} else if (this.aggregator === 'glovoOrder') {
				return 'glovo';
			} else return ''
		},
		isAsap() {
			if (this.deliveryHow == 'takeaway' && this.deliveryWhen === 'instant') {
				return true;
			}
			return false;
		},
		timeToShow() {
			if ((this.deliveryHow == 'takeaway' || this.deliveryHow === 'homedelivery') && this.deliveryWhen === 'preorder') {
				return this.formatDateTime(this.dateTimeFormats, this.entityOrder.pre_order.preorder_time);
			}
			if (this.deliveryHow === 'homedelivery' || this.deliveryHow === 'eatin') {
				return this.formatDateTime(this.dateTimeFormats, this.entityOrder.pickup_eta);
			}
			if (this.aggregator === 'boltOrder') {
				return this.formatDateTime(this.dateTimeFormats, this.pickupEta);
			}
			if (this.aggregator === 'glovoOrder') {
				return this.formatDateTime(this.dateTimeFormats, this.pickupEta);
			}
			return false;
		},
		isPreorder() {
			return this.deliveryWhen === 'preorder' || this.deliveryWhen === 'scheduled'
		},
		isDelayed() {
			return this.order.status.name === 'Delayed';
		},
		hasErrorMessage() {
			return this.orderStatus.INCORRECT == this.colValue && this.orderErrorMsg;
		},
		circValueVar() {
			let value = 100 / this.timerDiffSec;
			return value;
		},
	},
	watch: {
		order: {
			immediate: true,
			handler() {
				this.entityOrder = this.order[this.aggregator];

				if (this.aggregator === 'woltOrder') {
					this.consumerName = this.entityOrder.consumer_name;
					this.consumerComment = this.entityOrder.consumer_comment;
					this.orderNumber = this.entityOrder.order_number;
					this.phone = this.entityOrder.consumer_phone_number;
					this.orderItems = this.entityOrder.items;
					this.price = this.splitPrice(this.entityOrder.basket_price?.total?.amount);
					this.originalPrice =
						this.entityOrder.items.reduce((itemsPrice, item) => {
							const { originalPrice } = JSON.parse(item.pos_id);

							const optionsPrice = item.options.reduce((sum, option) => {
								sum += option.price.amount;
								return sum;
							}, 0);

							itemsPrice += (originalPrice * 100 + optionsPrice) * item.count;
							return itemsPrice;
						}, 0) / 100;
					this.priceCurrency = this.entityOrder.basket_price?.total.currency;
					this.deliveryHow = this.entityOrder.delivery.type;
					this.deliveryWhen = this.entityOrder.type;
					this.modifiedTime = this.entityOrder.modified_at;
				}

				if (this.aggregator === 'glovoOrder') {
					console.log('woooooork', JSON.parse(JSON.stringify(this.entityOrder)));
					this.consumerName = this.entityOrder.customer.name;
					this.consumerComment = this.entityOrder?.special_requirements;
					this.allergy_info = this.entityOrder?.allergy_info;
					this.orderNumber = this.entityOrder?.order_id
					this.phone = this.entityOrder.customer.phone_number
					this.orderItems = this.entityOrder.products;
					this.price = this.entityOrder.estimated_total_price;
					this.originalPrice = this.entityOrder.products.reduce((itemsPrice, item) => {
						// const { originalPrice } = JSON.parse(item.pos_id);

						// const optionsPrice = item.options.reduce((sum, option) => {
						// 	sum += option.price;
						// 	return sum;
						// }, 0);
						// console.log(itemsPrice);

						itemsPrice += (item.price * 100) * item.quantity;
						return itemsPrice;
					}, 0) / 100
					this.priceCurrency = this.entityOrder.currency;
					this.deliveryHow = 'Delivery';
					this.deliveryWhen = this.entityOrder.orderTime;
					this.modifiedTime = this.entityOrder.orderTime;
				}

				if (this.aggregator === 'boltOrder') {
					this.consumerName = this.entityOrder.customer.partial_name;
					this.consumerComment = this.entityOrder.user_note;
					this.orderNumber = this.entityOrder.order_reference_id;
					this.phone = this.entityOrder.customer.phone;
					this.tips.value = this?.order?.orderExtraProperties?.markIsPaidFromBolt?.waiter_tip?.value
					this.tips.currency = this?.order?.orderExtraProperties?.markIsPaidFromBolt?.waiter_tip?.currency
					this.orderItems = this.entityOrder.items;
					this.price = this.entityOrder.total_order_price.value;
					this.originalPrice = this.entityOrder.total_order_price.original_value;
					this.priceCurrency = this.entityOrder.total_order_price.currency;
					this.deliveryHow = this.entityOrder.order_type;
					this.modifiedTime = this.entityOrder.created_datetime;
					this.deliveryWhen = this.entityOrder.order_dispatch_type;
					this.courier = Object.prototype.hasOwnProperty.call(this.entityOrder, 'courier')
						? this.entityOrder.courier
						: null;
					this.entityOrderFields.table = this.entityOrder?.dine_in_details?.table ?? ''

				}
				if (this.aggregator === 'fudyOrder') {
					this.consumerName = this.entityOrder.consumer_name;
					this.consumerComment = this.entityOrder.consumer_comment;
					this.orderNumber = this.entityOrder.order_number;
					this.phone = this.entityOrder.consumer_phone_number;
					this.orderItems = this.entityOrder.items;
					this.price = this.splitPrice(this.entityOrder.price.amount);
					this.originalPrice =
						this.entityOrder.items.reduce((itemsPrice, item) => {
							const { originalPrice } = JSON.parse(item.pos_id);
							const optionsPrice = item.options.reduce((sum, option) => {
								sum += option.price.amount;
								return sum;
							}, 0);
							itemsPrice += (originalPrice * 100 + optionsPrice) * item.count;
							return itemsPrice;
						}, 0) / 100;
					this.priceCurrency = this.entityOrder.price.currency;
					this.deliveryHow = this.entityOrder.delivery.type;
					this.deliveryWhen = this.entityOrder.type;
					this.modifiedTime = this.entityOrder.modified_at;
					this.entityOrderFields.table = this.entityOrder.table ?? ''
				}
				this.entityOrderFields.customerName = this.consumerName;
				this.entityOrderFields.comment = this.consumerComment;
				this.entityOrderFields.orderNumber = this.orderNumber;
				this.entityOrderFields.phone = this.phone;
				this.entityOrderFields.orderItems = this.orderItems;
				this.entityOrderFields.price = this.price;
				this.entityOrderFields.originalPrice = this.originalPrice;
				this.entityOrderFields.priceCurrency = this.priceCurrency;
				this.entityOrderFields.delivery.how = this.deliveryHow;
				this.entityOrderFields.delivery.when = this.deliveryWhen;
				this.entityOrderFields.allergy_info = this.allergy_info;

				if (this.consumerComment) {
					this.isCommentExist = true;
				} else {
					if (this.aggregator === 'boltOrder') {
						let doesItemHaveComment = this.orderItems.some(item => !!item.user_note);
						this.isCommentExist = doesItemHaveComment;
					}
				}
			},
		},
		isLoading(val) {
			this.isStatusChanging = val;
		},
		autoAcceptTime(val) {
			if (
				val <= 16 &&
				this.aggregator === 'boltOrder' &&
				this.getCurrentVenue.settings.venueSelfSettings.isAutoAcceptEnabled
			) {
				this.isAcceptDisabled = true;
			}
		},
		courier(val) {
			if (val) {
				this.courier = val;
			}
		},
	},
	methods: {
		...mapActions('orders', [
			'rejectOrder',
			'editOrderTime',
			'validateOrder',
			'forceOrderStatus',
			'retryOrderStatus',
			'fetchOrders',
			'fetchOrder',
			'printOrder',
			'setAdjustedTime',
			'updateOrder',
			'forceRejectOrder',
			'forceAccept'
		]),
		...mapMutations('orders', ['updateOrder']),
		closeDineInModal() {
			this.dineInModal = false
			this.openModalWindow();
		},
		async forceRejectOrderHandler() {
			if (!this.isLoading) {
				let res = await this.$dialog.confirm({
					text: 'Do you really want to Force Reject this order? This will NOT automatically cancel RK order, you will have to refund it manually. If receipt was already printed, you will have to refund it manually.',
					title: 'Warning',
				});
				if (!res) {
					return;
				}
				this.isStatusChanging = true;
				this.forceRejectOrder(this.id).catch(() => {
					this.isStatusChanging = false;
				});
			}
		},
		splitPrice(value) {
			return (value / 100).toFixed(2);
		},
		getItemPrice(item) {
			if (this.logo === 'wolt') {
				return `${this.splitPrice(item.item_price.total.amount)} ${item.item_price.total.currency}`;
				// return `${this.splitPrice(item.total_price.amount)} ${item.total_price.currency}`;
			}
			if (this.logo === 'fudy') {
				return `${this.splitPrice(item.total_price.amount)} ${item.total_price.currency}`;
			}
			if (this.logo === 'bolt') {
				return `${this.format(item.unit_item_price.value)} ${item.unit_item_price.currency}`;
			}
		},
		getOptionPrice(option) {
			if (this.logo === 'wolt') {
				return `${this.splitPrice(option.price.amount)} ${option.price.currency}`;
			}
			if (this.logo === 'fudy') {
				return `${this.splitPrice(option.price.amount)} ${option.price.currency}`;
			}
			if (this.logo === 'bolt') {
				return `${this.formatPrice(option.unit_item_price.value)} ${option.unit_item_price.currency}`;
			}
		},
		timerShowTime(time) {
			if (time < 0) {
				return 0;
			} else if (time > 60) {
				return Math.floor(time / 60);
			} else if (time < 60 && time > 0) {
				return Number(time / 100).toFixed(2);
			}
		},
		timerTick(value) {
			let _this = this;
			if (_this[value] > 0) {
				setInterval(function () {
					_this[value]--;
					_this.progressValue(_this[value]);
				}, 1000);
			}
		},
		progressValue(time) {
			this.progressTime.showValue = this.timerShowTime(time);
			this.progressTime.diffValue += this.circValueVar;
		},
		infoModalHandler(event) {
			this[event]();
			this.isOrderInfoModalOpen = false;
		},
		editOrderStatus() {
			let isRestricted = this.checkRestriction();
			if (isRestricted) {
				return;
			}

			if (this.orderStatus.RECEIVED === this.status) {
				console.log('1');

				this.buttonPressed = 'accept';
				const showModal = this.order?.[this.aggregator]?.payment_type === 'cash' && this.order[this.aggregator].dine_in_details && this.order[this.aggregator].payment_method === 'cash'
				if (showModal) {
					this.dineInModal = true
					return;
				}
				this.openModalWindow();
			} else if (this.orderStatus.PREORDER === this.status || this.orderStatus.DELAYED === this.status) {
				console.log('2');

				this.buttonPressed = 'edit';
				this.openModalWindow();
			} else {
				console.log(3);

				this.changeAcceptOrderStatus(null);
			}
		},
		async rejectOrderHandler() {
			let isRestricted = this.checkRestriction();

			if (isRestricted) {
				return;
			}

			if (!this.isLoading) {
				let res = true;
				if (this.status === ORDER_STATUS.RECEIVED) {
					res = await this.$dialog.confirm({
						text: 'Are you sure you want to reject this order?',
						title: 'Warning',
					});
				}
				if (res) {
					this.isStatusChanging = true;
					this.rejectOrder({
						id: this.id,
						reason: 'Unfortunately, we couldn’t process your order at this time.',
					}).catch(() => {
						this.isStatusChanging = false;
					});
				}
			}
		},
		retryOrder() {
			let isRestricted = this.checkRestriction();

			if (isRestricted) {
				return;
			}

			this.buttonPressed = 'retry';
			this.changeRetryOrderStatus();
		},
		async forceOrder() {
			let isRestricted = this.checkRestriction();

			if (isRestricted) {
				return;
			}

			const res = await this.$dialog.confirm({
				text: 'Are you sure you want to force accept this order?',
				title: 'Warning',
			});
			if (res) {
				this.buttonPressed = 'force';
				if (this.orderStatus.RECEIVED === this.status) {
					this.openModalWindow();
				} else {
					this.changeForceOrderStatus(null);
				}
			}
		},
		changeAcceptOrderStatus(cookingTime) {

			this.updatedCookingTime = cookingTime;
			this.isStatusChanging = true;
			if (this.orderStatus.RECEIVED === this.status) {

				let dataToSend = {
					id: this.id,
					isForce: false,
					type: 'accept',
					cookingTimeInMinutes: cookingTime,
				};
				this.acceptOrderApi(dataToSend);
			}

			if (this.orderStatus.PRODUCTION === this.status) {
				console.log('2222');
				this.readyOrderApi(this.id);
			}

			if (this.orderStatus.READY === this.status) {
				console.log('33333');
				this.pickupOrderApi({ id: this.id, isForce: false });
			}
		},
		changeRetryOrderStatus() {
			this.isStatusChanging = true;

			if (this.orderStatus.RECEIVED === this.status) {
				let dataToSend = {
					id: this.id,
					type: 'retry',
				};
				this.acceptOrderApi(dataToSend);
			}

			if (this.orderStatus.READY === this.status) {
				this.pickupOrderApi({ id: this.id, isForce: false });
			}
		},
		changeForceOrderStatus(cookingTime) {
			this.isStatusChanging = true;

			if (this.orderStatus.RECEIVED === this.status) {
				let dataToSend = {
					id: this.id,
					isForce: true,
					type: 'force',
					cookingTimeInMinutes: cookingTime,
				};
				this.acceptOrderApi(dataToSend);
			}

			if (this.orderStatus.READY === this.status) {
				this.pickupOrderApi({ id: this.id, isForce: true });
			}
		},
		acceptModal({ cookingTime }) {
			if (this.buttonPressed === 'accept') {
				throttle(this.changeAcceptOrderStatus(cookingTime), 5000)
			}

			if (this.buttonPressed === 'force') {
				throttle(this.changeForceOrderStatus(cookingTime));
			}

			if (this.buttonPressed === 'edit') {
				this.editPreorder(cookingTime);
			}
			this.closeModalWindow();
		},
		async forceToProd() {
			this.isStatusChanging = true;
			this.forceAccept(this.id).catch(() => {
				this.isStatusChanging = false;
			});
		},
		confirmPreorder() {
			let isRestricted = this.checkRestriction();

			if (isRestricted) {
				return;
			}

			this.isStatusChanging = true;
			let dataToSend = {
				id: this.id,
			};

			if (this.orderState.BAD !== this.state) {
				dataToSend.type = 'confirm';
			}

			if (this.orderState.BAD === this.state) {
				dataToSend.type = 'force';
				dataToSend.isForce = true;
			}

			this.acceptOrderApi(dataToSend);
		},
		editPreorder(cookingTime) {
			let dataToSend = {
				id: this.id,
				cookingTime: cookingTime,
			};
			this.editOrderTime(dataToSend).then(() => {
				this.cookingTime = cookingTime;
				this.updatePreorderTimer();
			});
		},
		retryValidateOrder() {
			let isRestricted = this.checkRestriction();

			if (isRestricted) {
				return;
			}

			this.isStatusChanging = true;
			this.validateOrder({ id: this.id })
				.then(data => {
					if (data === 1) {
						this.isStatusChanging = false;
					}
				})
				.catch(() => {
					this.isStatusChanging = false;
				});
		},
		onPrint() {
			this.printOrder(this.id).then(() => {
				this.$toast.open({ message: 'Successfully sent to print!', type: TOAST_TYPES.SUCCESS });
			});
		},
		onCardClickHandler() {
			// Refused to use card expanding mode
			// let mode = this.getGeneralSettings.orderCardMode;
			// if (mode === 1) {
			//   this.isOpen = !this.isOpen;
			// } else {
			//   this.isOrderInfoModalOpen = !this.isOrderInfoModalOpen;
			// }
			this.isOrderInfoModalOpen = !this.isOrderInfoModalOpen;
		},
		toggleIsOpen() {
			this.isOpen = !this.isOpen;
		},
		openModalWindow() {
			this.isModalOpen = true;
		},
		closeModalWindow() {
			this.isModalOpen = false;
		},
		updatePreorderTimer() {
			let timeToUse;
			if (this.deliveryHow === 'homedelivery' || this.deliveryHow === 'delivery') {
				if (this.entityOrder.pre_order) {
					timeToUse = this.entityOrder.pre_order.preorder_time;
				} else if (this.entityOrder.due_datetime) {
					timeToUse = this.entityOrder.due_datetime
				}

			} else {
				if (this.entityOrder.pre_order) {
					timeToUse = this.entityOrder.pre_order.preorder_time;
				}
			}

			let tillTime = dayjs(dayjs.utc(timeToUse).local().subtract(this.cookingTime > 0 ? this.cookingTime : this.getCurrentVenue.settings.venueSelfSettings.defaultDelayTimeInMinutes, 'minute'));

			this.autoAcceptTime = tillTime.subtract(dayjs()).unix();

			let modTime = dayjs.utc(this.modifiedTime).local();
			this.timerDiffSec = tillTime.subtract(modTime).unix();

			this.progressTime.showValue = this.timerShowTime(this.autoAcceptTime);
			this.progressTime.diffValue = Math.floor(100 * ((this.timerDiffSec - this.autoAcceptTime) / this.timerDiffSec));


			this.timerTick('autoAcceptTime');
		},
		checkRestriction() {
			const isBoltActive = Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings, 'boltSettings');
			const isWoltActive = Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings, 'woltSettings');
			const isFudyActive = Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings, 'fudySettings');
			const isGlovoActive = Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings, 'glovoSettings');
			let isWoltLicensed = this.getCurrentVenue.isWoltLicensed;
			let isBoltLicensed = this.getCurrentVenue.isBoltLicensed;
			let isFudyLicensed = this.getCurrentVenue.isFudyLicensed;
			let isGlovoLicensed = this.getCurrentVenue.isGlovoLicensed;

			if (!Object.prototype.hasOwnProperty.call(this.getCurrentVenue, 'settings')) {
				this.$toast.open({ message: 'Action not allowed!', type: TOAST_TYPES.ERROR });
				return true;
			}

			if (isBoltActive && !isBoltLicensed && this.aggregator === 'boltOrder') {
				this.$toast.open({ message: 'Bolt license is expired!', type: TOAST_TYPES.ERROR });
				return true;
			}

			if (isWoltActive && !isWoltLicensed && this.aggregator === 'woltOrder') {
				this.$toast.open({ message: 'Wolt license is expired!', type: TOAST_TYPES.ERROR });
				return true;
			}

			if (isGlovoActive && !isGlovoLicensed && this.aggregator === 'glovoOrder') {
				this.$toast.open({ message: 'Glovo license is expired!', type: TOAST_TYPES.ERROR });
				return true;
			}

			if (isFudyActive && !isFudyLicensed && this.aggregator === 'fudyOrder') {
				this.$toast.open({ message: 'Fudy license is expired!', type: TOAST_TYPES.ERROR });
				return true;
			}

			return false;
		},
		formatPrice(price) {
			const isGlovo = this.aggregator === 'glovoOrder';
			const finalPrice = isGlovo ? parseFloat(price) / 100 : parseFloat(price);
			return finalPrice.toFixed(2);
		},
	},
};
</script>

<style lang="scss" scoped>
.order-card {
	border: 1px solid #ededed;
	border-radius: 8px;
	padding: 24px 32px;
}

.pre-order {
	background-color: #d5eef9;
}

@media screen and (max-width: 1536px) {
	.order-card {
		padding: 16px;
	}
}

.cursor_move {
	cursor: move;
}

.order-title {
	font-size: 1.25rem;
	font-weight: 600;
	color: #333;
	letter-spacing: 0.15px;
}

.order-phone {
	color: #636363;
}

.order-comment {
	position: relative;
	background-color: #333;
	color: #f6f5fa;
	padding: 12px 16px;
	border-radius: 10px;
	font-size: .875rem;
	letter-spacing: 0.3px;

	&:after {
		content: '';
		position: absolute;
		height: 0;
		width: 0;
		top: -10px;
		left: 40px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;

		border-bottom: 10px solid #333;
	}
}

.order-type {
	display: inline-block;
	text-align: center;
	color: #333;
	letter-spacing: 0.15px;

	&-icon {
		display: inline-block;
		margin-bottom: 10px;

		i {
			color: #333;
		}
	}

	h4 {
		font-weight: 400;
		display: inline-block;
		margin-left: 10px;
	}

	&-closed {
		.order-type-icon {
			margin-bottom: 0;
		}

		h4 {
			margin-left: 10px;
		}
	}
}

.order-product-item {
	color: #333;
	font-size: 1rem;
	font-weight: 600;

	&-comment {
		display: inline-block;
		background-color: #333;
		color: #f6f5fa;
		padding: 4px 16px;
		border-radius: 10px;
		font-size: .875rem;
		letter-spacing: 0.3px;
		margin-left: 10px;
	}
}

.order-price-items {
	font-size: 1rem;
	color: #636363;

	span {
		display: inline-block;
		margin-right: 10px;
	}
}

.price i {
	position: relative;
	color: gray;

	&::after {
		border-bottom: 0.2em solid gray;
		content: '';
		left: 0;
		margin-top: calc(0.2em / 2 * -1);
		position: absolute;
		right: 0;
		top: 50%;
		rotate: -5deg;
	}
}

.order-status-tag {
	font-weight: 400;
	font-size: .75rem;
	color: #fff;
	background-color: #02b66f;
	padding: 4px 8px;
	border-radius: 24px;
}

.order-card-small {
	padding: 16px;
	border: 1px solid #ededed;
	border-radius: 12px;
	background-color: #fff;

	.order-type-icon {
		i {
			font-size: 1rem;
		}
	}
}

.order-success-icon {
	width: 25px;
	height: 25px;
	background-color: #02b66f;
	position: relative;
	border-radius: 50%;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
	}
}

.overlay {
	position: relative;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-size: 300px;
	z-index: 100000000;
	opacity: 0.5; // no opacity!
}

.overlay-loader {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 100000000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.progress {
	position: relative;
	padding-bottom: 50px;
	z-index: 1000;
	opacity: 1 !important;
}

.error-info-block {
	background-color: #f9302c;
	color: #fff;
	font-weight: 500;
	width: 100%;
	border-radius: 4px;
	padding: 4px 8px;
	margin-bottom: 16px;
	height: 30px;

	p {
		margin: 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
}

.error-info-detail {
	&-row {
		margin-bottom: 10px;
	}

	&-col {
		font-weight: bold;
	}
}

.time-changer {
	border: 1px black solid;
	border-radius: 20px;
	max-width: 200px;

	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}
</style>
