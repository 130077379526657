var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "app-wrapper" },
    [
      _c(
        "v-main",
        [
          _vm.showProgress
            ? _c(
                "div",
                { staticClass: "preloader" },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      size: 70,
                      width: 7,
                      color: "primary",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("transition", [_c("router-view")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }