<template>
  <v-dialog v-model="syncedOpened" width="1200" overlay-opacity="0.8" hidden>
    <div class="v-card">
      <div ref="menu" class="menues">
        <h2>{{ filteredProviders[activeProvider][activeMenu]?.name }}</h2>
        <!-- <div v-if="menuItem?.children" class="menu"> -->
        <div class="menu">
          <div id="categories-scroll" class="categories">
            <v-btn v-on:click="download" class="ml-1" :isLoading="isLoading" icon>
              <v-icon>mdi-file-arrow-up-down</v-icon>
            </v-btn>
            <h3>Provider</h3>
            <ul class="categories__list">
              <li v-for="(provider, menukey) in filteredProviders" :key="menukey"
                :class="activeProvider === menukey ? 'categories__item categories__item_active' : 'categories__item'"
                v-on:click="setActiveProvider(menukey)">
                <p>{{ menukey.slice(0, 4) | capitalize }}</p>
              </li>
            </ul>
            <h3>Page</h3>
            <ul class="categories__list">
              <li v-for="(menuItem, menuItemIndex) in filteredProviders[activeProvider]"
                :class="activeMenu === menuItemIndex ? 'categories__item categories__item_active' : 'categories__item'"
                :key="menuItem.id" v-on:click="setActiveMenu(menuItemIndex)">
                <p>{{ menuItem?.name }}</p>

              </li>
            </ul>
            <h3>Categories</h3>
            <ul class="categories__list">
              <li :class="activeCategory === 'All disabled' ? 'categories__item categories__item_active' : 'categories__item'
                " v-on:click="setActiveCategory('All disabled')">
                <p>All disabled</p>
                <span>{{ allDisabled?.length }}</span>
              </li>

              <li :class="activeCategory === 'All disabled from POS'
                ? 'categories__item categories__item_active'
                : 'categories__item'
                " v-on:click="setActiveCategory('All disabled from POS')">
                <p>All disabled from POS</p>
                <span>{{ allDisabledFromPOS.length }}</span>
              </li>

              <li :class="activeCategory === 'Broken Combos' ? 'categories__item categories__item_active' : 'categories__item'
                " v-on:click="setActiveCategory('Broken Combos')">
                <p>Broken Combos</p>
                <span>{{ allBrokenCombos.length }}</span>
              </li>
              <li :class="activeCategory === 'Options/Modifiers'
                ? 'categories__item categories__item_active'
                : 'categories__item'
                " v-on:click="setActiveCategory('Options/Modifiers')">
                <p>Options/Modifiers</p>
                <span>{{ allOptionsModifiers.length }}</span>
              </li>

              <!-- <li
                :class="activeCategory === 'Options/ComboComponents' ? 'categories__item categories__item_active' : 'categories__item'"
                v-on:click="setActiveCategory('Options/ComboComponents')">
                <p>Options/Combo Components</p>
                <span>{{ allOptionsComboComponents.length }}</span>
              </li> -->

              <li v-for="(category, catIndex) in filteredProviders[activeProvider][activeMenu].children?.[0].children"
                :class="activeCategory === catIndex ? 'categories__item categories__item_active' : 'categories__item'"
                :key="category.id" v-on:click="setActiveCategory(catIndex)">
                <p>{{ category.name }}</p>
                <span>{{ category.children.length }}</span>
              </li>
            </ul>
          </div>
          <div id="dishes-scroll" class="dishes">
            <Button v-if="
              !activeDish[0] &&
              ![
                'All disabled from POS',
                'All disabled',
                'Broken Combos',
                'Options/Modifiers',
                'Options/ComboComponents',
              ].includes(activeCategory)
            " text="Disable/Enable All dishes in Category" class="standart-button-content-width float-right mb-4 mx-3"
              type="button" @handleClick="disableAllDishesInCategory">
            </Button>
            <Button v-if="activeDish[0] && activeDish[0]?.children && activeDish[0].id[0] !== 'i'"
              text="Disable/Enable All Items" class="standart-button-content-width float-right mb-4 mx-3" type="button"
              @handleClick="disableAllDishesInCombo">
            </Button>
            <div v-if="
              activeDish &&
              !activeDish[0] &&
              ![
                'All disabled',
                'All disabled from POS',
                'Broken Combos',
                'Options/Modifiers',
                'Options/ComboComponents',
              ].includes(activeCategory)
            " class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="dish in filteredProviders[this.activeProvider][this.activeMenu]?.children?.[0]?.children[activeCategory]
                  .children" :key="dish.id" @click="setActiveDish(dish)">
                  <p>{{ dish.name }}</p>
                  <span v-if="dish.isBrokenCombo || dish.isEmpty"><v-icon large
                      color="red">mdi-alert-circle</v-icon></span>
                  <span v-if="dish.children && dish.children?.length">+Options</span>
                  <span v-if="dish.children && dish.children?.length">+Options</span>
                  <span v-if="dish.isRequiredCombo">Required</span>
                  <button :class="getDishClass(dish)" :disabled="getButtonDisabledState(dish)"
                    v-on:click.stop="setIsEnabled(dish)">
                    {{ getDishStatus(dish) }}
                  </button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'All disabled'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="disabled in allDisabled" :key="disabled.dish.id"
                  @click="setActiveDish(disabled.dish)">
                  <p>{{ disabled.dish.name }}</p>
                  <span>{{ disabled.status }}</span>
                  <button :class="disabled.dish.isEnabled ? 'enabled' : 'disabled'"
                    v-on:click.stop="setIsEnabled(disabled.dish)">
                    {{ disabled.dish.isEnabled ? 'Enabled' : 'Disabled' }}
                  </button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'All disabled from POS'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="disabled in allDisabledFromPOS" :key="disabled.dish.id"
                  @click="setActiveDish(disabled.dish)">
                  <p>{{ disabled.dish.name }}</p>
                  <span>{{ disabled.status }}</span>
                  <button class="disabled" disabled="true">Disabled from POS</button>
                  <button class="disabled" disabled="true">Disabled from POS</button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'Broken Combos'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="broken in allBrokenCombos" :key="broken.dish.id"
                  @click="setActiveDish(broken.dish)">
                  <span>{{ broken.dish.name }}</span>
                  <span>{{ broken.status }}</span>
                  <span><v-icon large color="red">mdi-alert-circle</v-icon></span>
                  <button class="disabled" disabled="true">Broken</button>
                  <button class="disabled" disabled="true">Broken</button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'Options/Modifiers'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="modifier in sortedModifiers" :key="modifier.id"
                  @click="setActiveDish(modifier)">
                  <span>{{ modifier.name }}</span>
                  <span v-if="modifier.isBrokenCombo || modifier.isEmpty">
                    <v-icon large color="red">mdi-alert-circle</v-icon>
                  </span>
                  <button :class="getDishClass(modifier)" :disabled="getButtonDisabledState(modifier)"
                    v-on:click.stop="toggleModiEnabled(modifier)">
                    {{ getDishStatus(modifier) }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- asdadasd -->
            <!-- asdadasd -->
            <!-- <div v-if="activeDish && !activeDish[0] && activeCategory === 'Options/ComboComponents'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="item in allOptionsComboComponents" :key="item.id"
                  @click="setActiveDish(item)">
                  <span>{{ item.name }}</span>
                  <span v-if="item.isBrokenCombo || item.isEmpty"><v-icon large
                      color="red">mdi-alert-circle</v-icon></span>
                  <button :class="getDishClass(item)" :disabled="getButtonDisabledState(item)"
                    v-on:click.stop="toggleComboComponentEnabled(item)">
                    {{ getDishStatus(item) }}
                  </button>
                </li>
              </ul>
            </div> -->

            <div v-if="activeDish[0]">
              <div class="disha-detail">
                <div class="disha-detail__header">
                  <button class="disha-detail__header_back" @click="closeDish">
                    <img :src="require(`@/assets/icons/back.svg`)" alt="" />
                    <span> back</span>
                  </button>
                  <p>{{ activeDish[0].name }}</p>
                  <span v-if="activeDish[0].isRequiredCombo">Required</span>
                  <button :class="getDishClass(activeDish[0])" disabled="true"
                    @click.stop="setIsEnabled(activeDish[0])">
                    {{ getDishStatus(activeDish[0]) }}
                  </button>
                </div>
                <div v-if="activeDish[0].children">
                  <ul>
                    <li class="dish__item" v-for="i in activeDish[0].children" :key="i.id" @click="setActiveDish(i)">
                      <p>{{ i.name }}</p>
                      <span v-if="i.children && i.children?.length">+Options</span>
                      <span v-if="i.isRequiredCombo">Required</span>
                      <button :class="getDishClass(i)" :disabled="getButtonDisabledState(i)"
                        v-on:click.stop="setIsEnabled(i)">
                        {{ getDishStatus(i) }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-else class="noMenuItems">
          <p class="text-center">No menu items</p>
        </div> -->
      </div>
      <div class="v-card-actions">
        <div class="buttons-actions">
          <div class="up-down">
            <button styleType="secondary" class="standart-button-content-width mr-2 px-2" type="button"
              @click="scrollSidebarByUp">
              <img :src="require(`@/assets/icons/up.svg`)" alt="Scroll up" />
            </button>
            <button styleType="secondary" class="standart-button-content-width mr-2 px-2" type="button"
              @click="scrollSidebarByDown">
              <img :src="require(`@/assets/icons/down.svg`)" alt="Scroll down" />
            </button>
          </div>
          <div class="up-down">
            <button styleType="secondary" class="standart-button-content-width mr-2 px-2" type="button"
              @click="scrollByUp">
              <img :src="require(`@/assets/icons/up.svg`)" alt="Scroll up" />
            </button>
            <button styleType="secondary" class="standart-button-content-width mr-2 px-2" type="button"
              @click="scrollByDown">
              <img :src="require(`@/assets/icons/down.svg`)" alt="Scroll down" />
            </button>
          </div>
          <div>
            <p>Last update:</p>
            <span>{{ lastUpdateTime }}</span>
          </div>
          <div class="d-flex align-center justify-end">
            <Button text="Update menu" class="standart-button-content-width mr-2" type="button" :isLoading="isLoading"
              @handleClick="updateMenu" />
            <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
              @handleClick="closeModal" />
            <Button text="Save" class="standart-button-content-width" type="button" :isLoading="isLoading"
              @handleClick="save" />
          </div>
          <VenueInstanceModal :data="woltsInCorrectDiscounts" :showInnerModal="showInnerModal"
            @close="showInnerModal = false" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import * as dayjs from 'dayjs';
import FileSaver from 'file-saver';
import VenueInstanceModal from './VenueInstanceModal.vue';

export default {
  name: 'MenuModal',
  data() {
    return {
      disableToggleFlag: false,
      activeProvider: '',
      activeMenu: 0,
      activeCategory: 0,
      activeDish: [],
      showInnerModal: false,
    };
  },

  async mounted() {
    console.log('test menu 2');

  },

  beforeDestroy() {
    this.$orderHub2.client.off('UpdateMenu');
    this.$orderHub2.stop();
  },

  components: {
    Button,
    VenueInstanceModal,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: {
      type: Boolean,
    },
    venueId: {
      type: String,
    },
  },


  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  watch: {
    async opened() {
      this.setActiveProvider(Object.keys(this.filteredProviders)?.[0] || '');

      const actualVenues = await this.fetchActualVenues(this.venueId);
      if (actualVenues.menuVersion !== localStorage.getItem('menuVersion') && localStorage.getItem(this.venueId) &&
        JSON.stringify(JSON.parse(localStorage.getItem(this.venueId)).menu) !== '{}') {
        await this.fetchMenu(this.venueId)
      } else {
        const obj = this.getNewUpdatedMenu(actualVenues);
        const date = new Date();

        this.setLastUpdateTime(date);
        this.setMenu(obj.menu);
        localStorage.setItem(this.venueId, JSON.stringify({ menu: obj.menu, lastUpdateTime: date }));
        localStorage.setItem('menuVersion', actualVenues.menuVersion);
      }
      try {
        this.$orderHub2.start(this.venueId);
        this.$orderHub2.client.on('UpdateMenu', async (data, newMenu, ...args) => {
          if (data === 'Started') {
            this.setLoadingBoolean(true)
            return;
          }

          if (data === 'MenuGetStarted') {
            this.setLoadingBoolean(true)
            return
          }
          if (data === 'MenuGetFinished' && newMenu) {
            localStorage.setItem('menuVersion', newMenu.menuVersion)
            const date = new Date();
            this.setLastUpdateTime(date);
            this.setMenu(newMenu);
            this.setWoltsInCorrectDiscounts(newMenu.woltsInCorrectDiscounts || []);
            localStorage.setItem(this.venueId, JSON.stringify({ menu: newMenu, lastUpdateTime: date }));
            this.setLoadingBoolean(false)
            return;
          }

          if (data === 'Finished' && newMenu) {
            const removeKeys = (obj, keysToRemove) => {
              return Object.keys(obj)
                .filter(key => !keysToRemove.includes(key))
                .reduce((acc, key) => {
                  acc[key] = obj[key];
                  return acc;
                }, {});
            };
            const date = new Date();
            this.setLastUpdateTime(date);
            this.setWoltsInCorrectDiscounts(newMenu.woltsInCorrectDiscounts || []);
            const menu = removeKeys(newMenu, ['woltsInCorrectDiscounts', 'lastMenuVersion', 'menuVersion']);

            this.setMenu(menu);
            localStorage.setItem(this.venueId, JSON.stringify({ menu: menu, lastUpdateTime: date }));
            this.setLoadingBoolean(false)
          }
        });
      } catch (error) {
        console.log(error);

      }

    },
    menu(items) {
      this.showInnerModal = !!this.woltsInCorrectDiscounts?.length;
    },

    closeModal() {
      this.$orderHub.stop();
    },
  },


  computed: {
    ...mapGetters({
      getMenu: 'venues/getMenu',
      getGeneralSettings: 'app/getGeneralSettings',
      getLastUpdateTime: 'venues/getLastUpdateTime',
      isLoading: 'venues/isLoading',
      getWoltsInCorrectDiscounts: 'venues/getWoltsInCorrectDiscounts',
    }),


    menu() {
      return this.getMenu;
    },

    woltsInCorrectDiscounts() {
      return this.getWoltsInCorrectDiscounts;
    },

    filteredProviders() {
      return Object.fromEntries(
        Object.entries(this.menu).filter(
          ([key, value]) =>
            value?.length > 0 &&
            key !== 'woltsInCorrectDiscounts' &&
            key !== 'menuVersion'
        )
      );
    },

    lastUpdateTime() {
      if (localStorage.getItem('settings')) {
        this.setGeneralSettings(JSON.parse(localStorage.getItem('settings')));
      }
      const settings = { ...this.getGeneralSettings };
      const date = this.getLastUpdateTime;

      if (settings.timeFormat === 2) {
        return dayjs(date).format('MMMM D, YYYY HH:mm');
      } else {
        return dayjs(date).format('MMMM D, YYYY h:mm A');
      }
    },
    sortedModifiers() {
      // Возвращаем отсортированный массив модификаторов по имени
      return [...this.allOptionsModifiers].sort((a, b) => a.name.localeCompare(b.name));
    },
    // menuItem() {
    //   return this.menu.children?.[this.activeMenu];
    // },
    allDisabled() {
      function findDisabledDishId(children, arrId = [], disabled = []) {
        children.forEach(item => {
          if (item.isEnabled === false && !arrId.includes(item.id)) {
            switch (item.id[0]) {
              case 'i':
                disabled.push({ dish: item, status: 'Item' });
                break;
              case 'm':
                disabled.push({ dish: item, status: 'Modifier' });
                break;
              case 'g':
                disabled.push({ dish: item, status: 'Modifier group' });
                break;
            }
            arrId.push(item.id);
          }
          if (item.children) {
            findDisabledDishId(item.children, arrId, disabled);
          }
        });
        return disabled;
      }

      return findDisabledDishId(
        this.filteredProviders[this.activeProvider][this.activeMenu].children?.[0]?.children || []
      );
    }
    ,
    allDisabledFromPOS() {
      function findDisabledDishId(children, arrId = []) {
        const disabled = [];

        children.forEach(item => {
          if (item.isDisabledInRk && !arrId.includes(item.id)) {
            switch (item.id[0]) {
              case 'i':
                disabled.push({ dish: item, status: 'Item' });
                break;
              case 'm':
                disabled.push({ dish: item, status: 'Modifier' });
                break;
              case 'g':
                disabled.push({ dish: item, status: 'Modifier group' });
                break;
            }
            arrId.push(item.id);
            // if (item.children) {
            //   disabled.push(...findDisabledDishId(item.children, arrId));
            // }
          } else {
            if (item.children) {
              disabled.push(...findDisabledDishId(item.children, arrId));
            } else {
              return [];
            }
          }
        });

        return disabled;
      }

      return findDisabledDishId(this.filteredProviders[this.activeProvider][this.activeMenu]?.children?.[0]?.children);
    },
    allBrokenCombos() {
      function findBrokenDishes(children, arrId = []) {
        const broken = [];

        children.forEach(item => {
          if (item.isBrokenCombo) {
            switch (item.id[0]) {
              case 'i':
                broken.push({ dish: item, status: 'Item' });
                break;
            }
            arrId.push(item.id);
            if (item.children) {
              broken.push(...findBrokenDishes(item.children, arrId));
            }
          } else {
            if (item.children) {
              broken.push(...findBrokenDishes(item.children, arrId));
            } else {
              return [];
            }
          }
        });

        return broken;
      }
      return findBrokenDishes(this.filteredProviders[this.activeProvider][this.activeMenu]?.children?.[0]?.children);
    },
    allOptionsModifiers() {
      function findModis(children, arrId = []) {
        const modis = [];

        children.forEach(item => {
          const itemId = item.id.split('_').at(-1);
          const isItemModifier = item.isModifier && !arrId.includes(itemId) && item.id[0] !== 'i';
          if (isItemModifier) {
            modis.push(item);
            arrId.push(itemId);
            if (item.children) {
              modis.push(...findModis(item.children, arrId));
            }
          } else {
            if (item.children) {
              modis.push(...findModis(item.children, arrId));
            } else {
              return [];
            }
          }
        });
        return modis;
      }
      return findModis(this.filteredProviders[this.activeProvider][this.activeMenu]?.children?.[0]?.children);
    },
    // allOptionsComboComponents() {
    //   function findComboComponents(children, arrId = []) {
    //     const modis = [];

    //     children.forEach(item => {
    //       const itemId = item.id.split('_').at(-1);
    //       const isItemComboComponent = item.isComboComponent && !arrId.includes(itemId)
    //       if (isItemComboComponent) {
    //         modis.push(item);
    //         arrId.push(itemId);
    //         if (item.children) {
    //           modis.push(...findComboComponents(item.children, arrId));
    //         }
    //       }
    //       else {
    //         if (item.children) {
    //           modis.push(...findComboComponents(item.children, arrId));
    //         } else {
    //           return [];
    //         }
    //       }
    //     });
    //     return modis;
    //   }
    //   return findComboComponents(this.menuItem.children);
    // },
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setEnabledModifier: 'venues/setEnabledModifier',
      setEnabledComboComponent: 'venues/setEnabledComboComponent',
      setEnabladDish: 'venues/setEnabladDish',
      toggleDisableStatus: 'venues/toggleDisableStatus',
      setGeneralSettings: 'app/setGeneralSettings',
      setLastUpdateTime: 'venues/setLastUpdateTime',
      setMenu: 'venues/setMenu',
      setWoltsInCorrectDiscounts: 'venues/setWoltsInCorrectDiscounts',
      setLoadingBoolean: 'venues/setLoadingBoolean',
    }),

    ...mapActions({
      changeMenu: 'venues/changeMenu',
      fetchMenu: 'venues/fetchMenu',
      downloadMenu: 'venues/downloadMenu',
      fetchActualVenues: 'venues/fetchActualVenues',
    }),
    getDishStatus(dish) {
      if (dish.isEmpty) return 'Empty';
      if (dish.isBrokenCombo) return 'Broken';
      if (dish.isDisabledInRk) return 'Disabled from POS';
      return dish.isEnabled ? 'Enabled' : 'Disabled';
    },
    getDishClass(dish) {
      if (dish.isEmpty) return 'disabled';
      if (dish.isBrokenCombo) return 'disabled';
      if (dish.isDisabledInRk) return 'disabled';
      return dish.isEnabled ? 'enabled' : 'disabled';
    },
    getButtonDisabledState(dish) {
      if (dish.isEmpty) return true;
      if (dish.isBrokenCombo) return true;
      if (dish.isDisabledInRk) return true;
      return false;
    },
    closeModal() {
      this.syncedOpened = false;
      this.$emit('closeMenuModal');
    },
    async download() {
      const obj = {
        venueId: this.venueId,
      };
      Object.keys(this.filteredProviders).forEach(provider => {
        obj[provider] = this.filteredProviders[provider].map(menu => {
          return {
            id: menu.id,
            identifiers: findDisabledDish(menu.children[0].children),
          };
        });
      });

      function findDisabledDish(arr) {
        const disabled = [];
        arr.forEach(element => {
          if (element.isEnabled === false) {
            disabled.push(element.id);
          } else {
            if (element.children) {
              disabled.push(...findDisabledDish(element.children));
            } else {
              return [];
            }
          }
        });
        return disabled;
      }

      const links = await this.downloadMenu(obj);
      links.forEach(link => {
        const fileName = link.substring(link.lastIndexOf('/') + 1);
        FileSaver.saveAs(link, fileName);
      });
    },
    async save() {
      const obj = {
        venueId: this.venueId,
      };

      Object.keys(this.filteredProviders).forEach(provider => {
        obj[provider] = this.filteredProviders[provider].map(menu => {
          return {
            id: menu.id,
            identifiers: findDisabledDish(menu.children[0]?.children || []),
          };
        });
      });

      function findDisabledDish(arr) {
        const disabled = [];
        arr.forEach(element => {
          if (element.isEnabled === false) {
            disabled.push(element.id);
          }

          if (element.children) {
            disabled.push(...findDisabledDish(element.children));
          }

          if (element.children) {
            disabled.push(...findDisabledDish(element.children));
          }
        });
        return disabled;
      }


      await this.changeMenu(obj).then(data => {
        const date = new Date();
        this.setLastUpdateTime(date);
        if (data?.['woltsInCorrectDiscounts']?.length) {
          this.showInnerModal = true;
        }
        this.setWoltsInCorrectDiscounts(data.woltsInCorrectDiscounts || []);
        localStorage.setItem(
          this.venueId,
          JSON.stringify({
            menu: data,
            lastUpdateTime: date,
          }),
        );
      });
    },
    setActiveProvider(provider) {
      this.activeProvider = provider;
      this.activeMenu = 0;
      this.activeDish = [];
      this.activeCategory = 0;
    },
    setActiveCategory(categoryIndex) {
      this.activeDish = [];
      this.activeCategory = categoryIndex;
    },
    setActiveMenu(menuIndex) {
      this.activeMenu = menuIndex;
      this.activeDish = [];
      this.activeCategory = 0;
    },
    setIsEnabled(item) {

      item.isEnabled = !item.isEnabled;
      // this.setEnabladDish({ dishId: item.id, provider: this.activeProvider, menuIndex: this.activeMenu });
    },
    toggleModiEnabled(item) {
      console.log('work');
      item.isEnabled = !item.isEnabled;
      // this.setEnabledModifier({ dishId: item.id, provider: this.activeProvider, menuIndex: this.activeMenu });
    },
    toggleComboComponentEnabled(item) {
      this.setEnabledComboComponent({ dishId: item.id, menuIndex: this.activeMenu });
    },
    setActiveDish(dish) {
      this.activeDish = [dish, ...this.activeDish];
    },
    closeDish() {
      this.activeDish.shift();
    },
    scrollSidebarByUp() {
      const target = document.getElementById('categories-scroll');

      if (target) {
        target.scrollBy({
          top: -400,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    scrollSidebarByDown() {
      const target = document.getElementById('categories-scroll');

      if (target) {
        target.scrollBy({
          top: 400,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    scrollByUp() {
      const target = document.getElementById('dishes-scroll');

      if (target) {
        target.scrollBy({
          top: -446.7,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    getNewUpdatedMenu(actualMenu) {
      const obj = {
        venueId: this.venueId,
        menu: {},
      };

      Object.keys(actualMenu).forEach(provider => {
        const providerInstances = actualMenu[provider];

        // Пропускаем не нужные ключи
        if (['woltsInCorrectDiscounts', 'venueId', 'menuVersion'].includes(provider)) {
          return;
        }

        if (providerInstances && providerInstances.length > 0) {
          obj.menu[provider] = this.filteredProviders?.[provider]?.map(menu => {
            // Ищем соответствующий instance по menu.id
            const matchingInstance = providerInstances.find(instance => instance.id === menu.id);

            // Рекурсивная функция для обработки дерева
            const updateChildren = children => {
              return children.map(child => {
                // Проверяем совпадение id
                const isDisabled = matchingInstance ? matchingInstance.identifiers.includes(child.id) : false;

                // Рекурсивно обрабатываем вложенные элементы
                return {
                  ...child,
                  isEnabled: !isDisabled, // Выключаем элемент, если он совпадает
                  children: child.children ? updateChildren(child.children) : [], // Обрабатываем детей
                };
              });
            };

            return {
              id: menu.id,
              name: menu.name,
              children: updateChildren(menu.children), // Обрабатываем children через рекурсию
            };
          });
        } else {
          obj.menu[provider] = this.filteredProviders?.[provider]?.length ? this.filteredProviders?.[provider] : [];
        }
      });

      return obj;
    },
    scrollByDown() {
      const target = document.getElementById('dishes-scroll');

      if (target) {
        target.scrollBy({
          top: 446.7,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    async updateMenu() {
      const menu = await this.fetchMenu(this.venueId);
      const date = new Date();
      this.setLastUpdateTime(date);
      this.setWoltsInCorrectDiscounts(menu.woltsInCorrectDiscounts || []);
      localStorage.setItem(this.venueId, JSON.stringify({ menu: menu, lastUpdateTime: date }));
    },
    disableAllDishesInCategory() {
      this.toggleDisableStatus({
        provider: this.activeProvider,
        menuIndex: this.activeMenu,
        categoryIndex: this.activeCategory,
        status: this.disableToggleFlag,
      });
      this.disableToggleFlag = !this.disableToggleFlag;
    },
    disableAllDishesInCombo() {
      this.activeDish[0].children.forEach(item => {
        if (!item.isBrokenCombo && !item.isDisabledInRk) item.isEnabled = !item.isEnabled;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.menues {
  max-height: 600px;
  background-color: #fff;
  overflow-y: hidden;

  h2 {
    text-transform: uppercase;
    text-align: center;
    margin: 20px 0;
  }
}

.menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.125rem;
}

.noMenuItems {
  font-size: 2rem;
  margin: 0 auto 110px auto;
}

.categories {
  overflow-y: scroll;
  max-height: 500px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h3 {
    color: #5b7ffe;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 5px;
    padding: 3px 15px;
    border-radius: 5px;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    span {
      margin-left: 20px;
    }

    &_active {
      background-color: rgb(183, 197, 247);

      color: #5b7ffe;
    }
  }
}

.dishes {
  overflow-y: scroll;
  max-height: 500px;
  flex: 1;
  margin: 0 15px;
  padding: 0 10px;
}

.disha-detail {
  &__header {
    width: 100%;
    margin: 20px 0;
    font-size: 1.125rem;
    list-style-type: none;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;

    &_back {
      display: flex;
      align-items: center;
      margin: 20px;
      line-height: 24px;
      background-color: #5170e0;
      color: #fff;
      display: block;
      cursor: pointer;

      img path {
        fill: #fff;
      }
    }

    p {
      margin-bottom: 0;
    }

    button {
      border-radius: 5px;
      padding: 5px 10px;
    }

    .enabled {
      background-color: #5b7ffe;
      color: #fff;
    }

    .disabled {
      background-color: red;
      color: #fff;
    }
  }
}

.dish {
  ul {
    padding: 0;
  }

  &__item {
    width: 100%;
    margin: 20px 0;
    font-size: 1.125rem;
    list-style-type: none;
    border: 0.5px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    button {
      border-radius: 5px;
      padding: 5px 10px;
    }

    .enabled {
      background-color: #5170e0;
      color: #fff;
    }

    .disabled {
      background-color: red;
      color: #fff;
    }
  }
}

.up-down {
  button {
    background-color: #5170e0;
    border-radius: 5px;
  }
}

.buttons-actions {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 25px;
}
</style>
