var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.orderStatus.READY !== _vm.status
        ? _c(
            "div",
            {
              staticClass: "order-card mb-4",
              class: {
                overlay: _vm.isStatusChanging,
                "pre-order": _vm.isPreorder,
              },
              style: _vm.orderBackColor,
              attrs: { id: _vm.id },
              on: { click: _vm.onCardClickHandler },
            },
            [
              _vm.hasErrorMessage
                ? _c("div", { staticClass: "error-info-block" }, [
                    _c("p", [_vm._v(_vm._s(_vm.orderErrorMsg))]),
                  ])
                : _vm._e(),
              _vm.hasErrorMessage && _vm.isOpen
                ? _c(
                    "div",
                    { staticClass: "error-info-detail" },
                    _vm._l(
                      Object.entries(this.order.error),
                      function (error, idx) {
                        return _c(
                          "v-row",
                          {
                            key: idx,
                            staticClass: "error-info-detail-row",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "error-info-detail-col",
                                attrs: { cols: "4" },
                              },
                              [_vm._v(_vm._s(error[0]))]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "error-info-detail-col",
                                attrs: { cols: "8" },
                              },
                              [_vm._v(_vm._s(error[1]))]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _c("div", [
                _vm.isPreorder
                  ? _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v("PRE-ORDER"),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols:
                          _vm.order.isCreatedInRKeeper &&
                          (_vm.order.statusId === 6 || _vm.order.statusId === 7)
                            ? 7
                            : 8,
                      },
                    },
                    [
                      _c("h3", { staticClass: "order-title mb-1" }, [
                        _vm._v(_vm._s(_vm.consumerName)),
                      ]),
                      _vm.isOpen
                        ? _c("span", { staticClass: "order-phone" }, [
                            _vm._v(_vm._s(_vm.phone)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center pr-2",
                      attrs: {
                        cols:
                          _vm.courier !== null && _vm.aggregator === "boltOrder"
                            ? 3
                            : 4,
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "order-title d-flex justify-end" },
                        [_vm._v("#" + _vm._s(_vm.orderNumber))]
                      ),
                    ]
                  ),
                  _vm.courier !== null && _vm.aggregator === "boltOrder"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "blue" } },
                            [_vm._v("mdi-moped")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.order.isCreatedInRKeeper &&
                  (_vm.order.statusId === 6 || _vm.order.statusId === 7)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c("v-icon", { attrs: { large: "", color: "red" } }, [
                            _vm._v(" mdi-alert-circle"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c("v-col", { staticClass: "mt-2", attrs: { cols: "7" } }, [
                    !_vm.isOpen
                      ? _c(
                          "p",
                          { staticClass: "order-price-items" },
                          [
                            _vm.status === 1
                              ? _c(
                                  "v-chip",
                                  {
                                    class: _vm.consumerComment
                                      ? "mr-1"
                                      : "mr-4",
                                    attrs: {
                                      "text-color": "white",
                                      color: "green",
                                    },
                                  },
                                  [_vm._v("new ")]
                                )
                              : _vm._e(),
                            _vm.allergy_info.length
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "mx-1",
                                    attrs: {
                                      color: "blue darken-2",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "darken-2" } },
                                      [_vm._v("mdi-medical-bag")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isCommentExist
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "mx-1 mr-4",
                                    attrs: {
                                      color: "blue darken-2",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white darken-2" } },
                                      [_vm._v(" mdi-comment-processing")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.orderItems
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.orderItems.length) + " "),
                                  _vm.orderItems.length < 2
                                    ? _c("span", [_vm._v("item")])
                                    : _c("span", [_vm._v("items")]),
                                ])
                              : _vm._e(),
                            _vm.aggregator === "boltOrder"
                              ? _c("span", { staticClass: "price" }, [
                                  +_vm.originalPrice > 0
                                    ? _c("span", [
                                        _c("i", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatPrice(
                                                  _vm.originalPrice
                                                )
                                              ) +
                                              "  "
                                          ),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formatPrice(_vm.price)) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v(_vm._s(_vm.priceCurrency))]
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formatPrice(_vm.price)) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v(_vm._s(_vm.priceCurrency))]
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                            ["woltOrder", "fudyOrder"].includes(_vm.aggregator)
                              ? _c("span", [
                                  _vm.price
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formatPrice(_vm.price)) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v(_vm._s(_vm.priceCurrency))]
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            ["glovoOrder"].includes(_vm.aggregator)
                              ? _c("span", [
                                  _vm.price
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formatPrice(_vm.price)) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v(_vm._s(_vm.priceCurrency))]
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "order-type d-flex align-center mb-2" },
                      [
                        _c("img", {
                          attrs: {
                            src: require(`@/assets/images/${_vm.logo}.svg`),
                            alt: "",
                          },
                        }),
                        _c("h4", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s(_vm.deliveryHow)),
                        ]),
                        _vm.order[_vm.aggregator].payment_type === "cash" &&
                        _vm.order[_vm.aggregator].dine_in_details &&
                        _vm.order[_vm.aggregator].payment_method === "cash"
                          ? _c(
                              "v-icon",
                              { attrs: { large: "", color: "green" } },
                              [_vm._v("mdi-cash")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.order.venue.instanceDisplayName)),
                      ]),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column justify-start align-end",
                    },
                    [
                      _vm.pickupEta
                        ? [
                            [1, 2, 10, 11].includes(_vm.status)
                              ? _c(
                                  "v-progress-circular",
                                  {
                                    staticClass: "float-right mr-2",
                                    attrs: {
                                      rotate: -90,
                                      size: 50,
                                      width: 6,
                                      value: _vm.progressTime.diffValue,
                                      color: _vm.timerColor,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.progressTime.showValue) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.timeToShow
                        ? _c("span", { staticClass: "mt-2" }, [
                            _vm._v(_vm._s(_vm.timeToShow)),
                          ])
                        : _vm._e(),
                      _vm.isAsap
                        ? [
                            _c(
                              "span",
                              { staticClass: "mt-2 font-weight-bold" },
                              [_vm._v("ASAP")]
                            ),
                          ]
                        : _vm._e(),
                      _vm.entityOrderFields?.table
                        ? _c("span", { staticClass: "mt-7" }, [
                            _vm._v("Table: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.entityOrderFields?.table)),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.orderState.BAD !== _vm.state &&
              _vm.orderStatus.RECEIVED === _vm.status &&
              _vm.page === 1
                ? _c("Button", {
                    staticClass: "mb-4 mt-3",
                    attrs: {
                      text:
                        _vm.deliveryWhen === "preorder" ? "Confirm" : "Accept",
                      isDisabled: _vm.isStatusChanging || _vm.isAcceptDisabled,
                    },
                    on: { handleClick: _vm.editOrderStatus },
                  })
                : _vm._e(),
              (_vm.orderStatus.PREORDER === _vm.status ||
                _vm.orderStatus.PREORDER === _vm.status) &&
              _vm.page === 2 &&
              _vm.isOpen
                ? _c("Button", {
                    staticClass: "mt-3",
                    attrs: { text: "Edit", isDisabled: _vm.isStatusChanging },
                    on: { handleClick: _vm.editOrderStatus },
                  })
                : _vm._e(),
              _vm.orderStatus.PREORDER === _vm.status &&
              _vm.page === 2 &&
              _vm.isOpen
                ? _c("Button", {
                    staticClass: "mt-3",
                    attrs: {
                      text: "Confirm",
                      isDisabled: _vm.isStatusChanging,
                    },
                    on: { handleClick: _vm.confirmPreorder },
                  })
                : _vm._e(),
              _vm.page == 1 || (_vm.page == 2 && _vm.status === 10)
                ? _c("div", [
                    _vm.page == 1
                      ? _c(
                          "div",
                          [
                            _vm.orderStatus.PRODUCTION === _vm.status
                              ? _c("Button", {
                                  staticClass: "mb-4 mt-3",
                                  attrs: {
                                    text: "Ready",
                                    isDisabled: _vm.isStatusChanging,
                                  },
                                  on: { handleClick: _vm.editOrderStatus },
                                })
                              : _vm._e(),
                            (_vm.orderStatus.RECEIVED === _vm.status ||
                              _vm.orderStatus.PREORDER === _vm.status) &&
                            _vm.orderState.BAD === _vm.state
                              ? [
                                  _c(
                                    "v-row",
                                    [
                                      !_vm.order.isCreatedInRKeeper ||
                                      _vm.state === 2
                                        ? _c(
                                            "v-col",
                                            [
                                              _c("Button", {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  text: "Retry",
                                                  isDisabled:
                                                    _vm.isStatusChanging,
                                                },
                                                on: {
                                                  handleClick:
                                                    _vm.retryValidateOrder,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        [
                                          _c("Button", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              text: "Force",
                                              isDisabled: _vm.isStatusChanging,
                                            },
                                            on: { handleClick: _vm.forceOrder },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.orderStatus.PRODUCTION !== _vm.status
                                    ? _c("Button", {
                                        staticClass: "mb-4",
                                        attrs: {
                                          text: "Reject",
                                          styleType: "danger",
                                          isDisabled: _vm.isStatusChanging,
                                        },
                                        on: {
                                          handleClick: _vm.rejectOrderHandler,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.isOpen
                      ? _c(
                          "div",
                          { staticClass: "my-4" },
                          [
                            _vm.orderState.BAD !== _vm.state &&
                            _vm.orderStatus.PRODUCTION !== _vm.status &&
                            _vm.page === 1
                              ? _c("Button", {
                                  staticClass: "mb-4",
                                  attrs: {
                                    text: "Reject",
                                    styleType: "warning",
                                    isDisabled: _vm.isStatusChanging,
                                  },
                                  on: { handleClick: _vm.rejectOrderHandler },
                                })
                              : _vm._e(),
                            _c("p", { staticClass: "mt-4 order-price-items" }, [
                              _vm.orderItems
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.orderItems.length) + " "),
                                    _vm.orderItems.length < 2
                                      ? _c("span", [_vm._v("item")])
                                      : _c("span", [_vm._v("items")]),
                                  ])
                                : _vm._e(),
                              _vm.price
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.formatPrice(_vm.price)) + " "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-uppercase" },
                                      [_vm._v(_vm._s(_vm.priceCurrency))]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._l(_vm.orderItems, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "order-product-item mb-2",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.count || item.qty) + " x "
                                    ),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                  _c(
                                    "span",
                                    { staticClass: "text-uppercase" },
                                    [
                                      _vm._v(
                                        " (" +
                                          _vm._s(_vm.getItemPrice(item)) +
                                          ") "
                                      ),
                                    ]
                                  ),
                                  item.user_note
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "order-product-item-comment",
                                        },
                                        [_vm._v(_vm._s(item.user_note))]
                                      )
                                    : _vm._e(),
                                  item.options.length > 0
                                    ? [
                                        _c(
                                          "p",
                                          { staticClass: "pl-4 mt-2 mb-2" },
                                          [_vm._v("OPTIONS:")]
                                        ),
                                        _vm._l(item.options, function (option) {
                                          return _c(
                                            "p",
                                            {
                                              key: option.id,
                                              staticClass: "pl-4 mt-2 mb-2",
                                            },
                                            [
                                              !(
                                                /\d\sx\s/.test(option.value) ||
                                                /\d\sx\s/.test(option.name)
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          option.count ||
                                                            option.qty
                                                        ) +
                                                        " x "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    option.value || option.name
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-uppercase",
                                                },
                                                [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        _vm.getOptionPrice(
                                                          option
                                                        )
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.isStatusChanging
                ? _c(
                    "div",
                    { staticClass: "overlay-loader" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 100,
                          width: 10,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.orderStatus.READY === _vm.status
        ? _c(
            "div",
            {
              staticClass: "order-card-small mb-4 allow-click",
              class: _vm.isStatusChanging ? "overlay" : "",
              style: _vm.orderBackColor,
              on: { click: _vm.onCardClickHandler },
            },
            [
              _c("div", [
                _vm.deliveryWhen === "preorder"
                  ? _c("p", { staticClass: "font-weight-bold" }, [
                      _vm._v("PRE-ORDER"),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-row",
                { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("span", { staticClass: "order-title" }, [
                      _vm._v("#" + _vm._s(_vm.orderNumber)),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end", attrs: { cols: "2" } },
                    [
                      _c(
                        "div",
                        { staticClass: "order-success-icon" },
                        [_c("v-icon", [_vm._v(" mdi-check")])],
                        1
                      ),
                    ]
                  ),
                  _vm.courier !== null && _vm.aggregator === "boltOrder"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "blue" } },
                            [_vm._v("mdi-moped")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("h3", { staticClass: "order-title mb-2" }, [
                _vm._v(_vm._s(_vm.consumerName)),
              ]),
              _c(
                "div",
                { staticClass: "order-type mb-2 d-flex align-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/images/${_vm.logo}.svg`),
                      alt: "",
                    },
                  }),
                  _c("h4", { staticClass: "text-capitalize" }, [
                    _vm._v(_vm._s(_vm.deliveryHow)),
                  ]),
                  _vm.order[_vm.aggregator]?.payment_type === "cash" &&
                  _vm.order[_vm.aggregator]?.dine_in_details &&
                  _vm.order[_vm.aggregator]?.payment_method === "cash"
                    ? _c("v-icon", { attrs: { large: "", color: "green" } }, [
                        _vm._v("mdi-cash"),
                      ])
                    : _vm._e(),
                  _vm.entityOrderFields?.table
                    ? _c("h4", { staticClass: "ml-auto" }, [
                        _vm._v("Table: "),
                        _c("b", [_vm._v(_vm._s(_vm.entityOrderFields?.table))]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex align-center" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.order.venue.instanceDisplayName)),
                ]),
              ]),
              _vm.tips?.value
                ? _c("div", { staticClass: "d-flex align-center" }, [
                    _c(
                      "strong",
                      { staticStyle: { margin: "5px 5px 5px 0" } },
                      [_c("v-icon", [_vm._v("mdi-cash-plus")])],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.tips.value) + " " + _vm._s(_vm.tips.currency)
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.orderState.GOOD === _vm.state
                ? _c("Button", {
                    staticClass: "mb-2",
                    attrs: {
                      text: "Pick up",
                      isDisabled: _vm.isStatusChanging,
                    },
                    on: { handleClick: _vm.editOrderStatus },
                  })
                : [
                    _vm.order.isCreatedInRKeeper
                      ? _c("Button", {
                          staticClass: "mb-2",
                          attrs: {
                            text: "Retry",
                            isDisabled: _vm.isStatusChanging,
                          },
                          on: { handleClick: _vm.retryOrder },
                        })
                      : _vm._e(),
                    _c("Button", {
                      attrs: {
                        text: "Force",
                        isDisabled: _vm.isStatusChanging,
                      },
                      on: { handleClick: _vm.forceOrder },
                    }),
                  ],
              _vm.isStatusChanging
                ? _c(
                    "div",
                    { staticClass: "overlay-loader" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 70,
                          width: 5,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("ProductionTimeModal", {
        attrs: {
          status: _vm.status,
          order: _vm.order,
          entityOrder: _vm.entityOrder,
          entityOrderFields: _vm.entityOrderFields,
          chosenCookingTime: _vm.order.cookingTimeInMinutes,
          defaultCookingTime: _vm.defaultCookingTime,
          isBoltOrWoltOrder: _vm.aggregator,
          title: "Production time",
        },
        on: { acceptModal: _vm.acceptModal },
        model: {
          value: _vm.isModalOpen,
          callback: function ($$v) {
            _vm.isModalOpen = $$v
          },
          expression: "isModalOpen",
        },
      }),
      _c("OrderInfoModal", {
        attrs: {
          order: _vm.order,
          entityOrder: _vm.entityOrder,
          entityOrderFields: _vm.entityOrderFields,
          page: _vm.page,
          orderErrorMsg: _vm.orderErrorMsg,
          isPrintBtnVisible: _vm.isPrintBtnVisible,
          isAcceptDisabled: _vm.isAcceptDisabled,
          courier: _vm.courier,
          tips: _vm.tips,
        },
        on: { infoModalEvent: _vm.infoModalHandler },
        model: {
          value: _vm.isOrderInfoModalOpen,
          callback: function ($$v) {
            _vm.isOrderInfoModalOpen = $$v
          },
          expression: "isOrderInfoModalOpen",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350" },
          model: {
            value: _vm.dineInModal,
            callback: function ($$v) {
              _vm.dineInModal = $$v
            },
            expression: "dineInModal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "py-3 px-5 border-radius-5" },
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Confimation "),
              ]),
              _c("v-card-text", { staticClass: "font-weight-bold" }, [
                _vm._v(" Payment for this order must be collected by staff! "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("Button", {
                    attrs: { text: "Ok" },
                    on: { handleClick: _vm.closeDineInModal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }