var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-md-4 pt-md-0", attrs: { fluid: "" } },
    [
      _c(
        "h2",
        { staticClass: "sidebar-title mb-2 d-flex align-center" },
        [
          _c("span", { staticClass: "mr-4" }, [
            _vm._v(_vm._s(_vm.getCurrentVenue.name)),
          ]),
          _c("v-switch", {
            staticClass: "standart-switch",
            attrs: { label: "Ascending" },
            model: {
              value: _vm.isAsc,
              callback: function ($$v) {
                _vm.isAsc = $$v
              },
              expression: "isAsc",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "order-tab-flex pr-md-4" },
        _vm._l(_vm.showColumns, function (data, colName) {
          return _c(
            "Container",
            {
              key: data.value,
              staticClass: "order-tab-content",
              class: `col-lg-${data.cols} col-md-${data.mdCols}`,
              attrs: {
                "group-name": _vm.page === 1 ? "orders" : colName,
                "lock-axis": "x",
                "should-accept-drop": _vm.shouldAcceptDrop,
                "get-child-payload": _vm.getChildPayload(colName),
              },
              on: {
                drop: function ($event) {
                  return _vm.drop(colName, $event)
                },
              },
            },
            [
              _c("div", { staticClass: "order-tab-header" }, [
                _c("h3", [
                  _vm._v(" " + _vm._s(data.title) + " "),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          _vm.getOrders(data.value)
                            ? _vm.getOrders(data.value).length
                            : 0
                        ) +
                        ")"
                    ),
                  ]),
                ]),
              ]),
              _vm._l(_vm.getOrders(data.value), function (order) {
                return _c(
                  "Draggable",
                  { key: order.id + Date.now() },
                  [
                    _c("OrderCard", {
                      attrs: {
                        id: order.id,
                        status: order.statusId
                          ? order.statusId
                          : order.status && order.status.id
                          ? order.status.id
                          : null,
                        order: order,
                        state: order.stateId,
                        isLoading: order.isLoading,
                        page: _vm.page,
                        colValue: data.value,
                        orderErrorMsg: order.error
                          ? order.error.message
                            ? order.error.message
                            : ""
                          : "",
                        isCreatedInRKeeper: order.isCreatedInRKeeper,
                        adjustedTime: order.adjustedTimeInMinutes,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        1
      ),
      _c("ProductionTimeModal", {
        attrs: {
          order: _vm.order,
          status: _vm.order.statusId,
          chosenCookingTime: _vm.order.cookingTimeInMinutes,
          title: "Production time",
        },
        on: { acceptModal: _vm.acceptModal },
        model: {
          value: _vm.isModalOpen,
          callback: function ($$v) {
            _vm.isModalOpen = $$v
          },
          expression: "isModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }