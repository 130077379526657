var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "col-lg-3 col-md-12 col-12" }, [
            _c("h2", { staticClass: "sidebar-title mb-lg-6" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _c("v-col", { staticClass: "col-lg-8 col-xl-6 col-md-12" }, [
            _c(
              "div",
              { staticClass: "standart-card" },
              [
                _c("VenueForm", {
                  key: _vm.venueFormKey,
                  attrs: {
                    venue: _vm.venue,
                    isFormEditable: true,
                    venueGroupFormId: _vm.venueGroupFormId,
                    shouldPopulateForm: _vm.shouldPopulateForm,
                    isFormLoading: _vm.isLoading,
                    isCheckingRk: _vm.isCheckingRk,
                    isCheckingWolt: _vm.isCheckingWolt,
                    isCheckingBolt: _vm.isCheckingBolt,
                    isCheckingFudy: _vm.isCheckingFudy,
                    "is-schedule-loading": _vm.scheduleLoad,
                  },
                  on: {
                    submitForm: _vm.submitForm,
                    resetForm: _vm.handleCancel,
                    checkConnection: _vm.checkEntityConnection,
                    changeCurrentVenueStatus: _vm.changeCurrentVenueStatus,
                    changeSchedule: _vm.changeCurrentVenueSchedule,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }