var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-tab" },
    [
      _vm.isArea
        ? _vm._l(_vm.list, function (area) {
            return _c("div", { key: area.id }, [
              _c(
                "div",
                {
                  staticClass:
                    "group-tab-item d-flex align-center justify-space-between",
                  class:
                    _vm.activeFolderId === area.id
                      ? "group-tab-item-active"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.onFolderClick(area.id)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(area.name))]),
                  area.child?.length
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-auto",
                          attrs: { text: "", icon: "" },
                        },
                        [
                          _vm.activeFolderId === area.id
                            ? _c("v-icon", [_vm._v("mdi-arrow-up-drop-circle")])
                            : _c("v-icon", [
                                _vm._v("mdi-arrow-down-drop-circle"),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showDots
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            left: "",
                            "nudge-left": "-15",
                            "nudge-top": "-20",
                            transition: "none",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "", icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-vertical"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "list-item-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAreaEdit(area)
                                    },
                                  },
                                },
                                [_vm._v(" Edit restaurant group ")]
                              ),
                              area.isDeletable
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "red--text list-item-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteArea(area.id)
                                        },
                                      },
                                    },
                                    [_vm._v(" Delete restaurant group ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              area.id === _vm.activeFolderId
                ? _c(
                    "div",
                    _vm._l(area.child, function (item) {
                      return _c("div", { key: item.id }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "group-tab-item d-flex align-center justify-space-between ml-5",
                            class:
                              _vm.activeTabId === item.id
                                ? "group-tab-item-active"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.clickHandler(item.id)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.name || item.userName)),
                            ]),
                            _vm.showDots
                              ? _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "offset-y": "",
                                      left: "",
                                      "nudge-left": "-15",
                                      "nudge-top": "-20",
                                      transition: "none",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        icon: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-dots-vertical"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "list-item-button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" Edit restaurant subgroup ")]
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "red--text list-item-button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(item.id)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Delete restaurant subgroup "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ])
          })
        : _vm._l(_vm.list, function (item) {
            return _c("div", { key: item.id }, [
              _c(
                "div",
                {
                  staticClass:
                    "group-tab-item d-flex align-center justify-space-between",
                  class:
                    _vm.activeTabId === item.id ? "group-tab-item-active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.clickHandler(item.id)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(item.name || item.userName))]),
                  _vm.showDots
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            left: "",
                            "nudge-left": "-15",
                            "nudge-top": "-20",
                            transition: "none",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "", icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-vertical"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "list-item-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(item)
                                    },
                                  },
                                },
                                [_vm._v(" Edit restaurant subgroup ")]
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "red--text list-item-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(item.id)
                                    },
                                  },
                                },
                                [_vm._v(" Delete restaurant group ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
      _c("Modal", {
        attrs: {
          formData: _vm.activeListItem,
          isLoading: _vm.isVenueGroupLoading,
          areas: _vm.list,
          title: "Edit restaurant subgroup",
        },
        on: { saveForm: _vm.saveEdit },
        model: {
          value: _vm.isModalOpen,
          callback: function ($$v) {
            _vm.isModalOpen = $$v
          },
          expression: "isModalOpen",
        },
      }),
      _c("AreaModal", {
        attrs: {
          formData: _vm.activeAreaItem,
          isLoading: _vm.isAreaLoading,
          title: "Edit restaurant group",
        },
        on: { saveAreaForm: _vm.saveArea },
        model: {
          value: _vm.isAreaModalOpen,
          callback: function ($$v) {
            _vm.isAreaModalOpen = $$v
          },
          expression: "isAreaModalOpen",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }