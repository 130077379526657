<template>
  <v-dialog v-model="syncedOpened" width="800" overlay-opacity="0.8">
    <div class="standart-modal-card">
      <div class="standart-modal-card-top d-flex align-center justify-space-between">
        <h3>Provider status settings</h3>
        <v-btn @click="closeModal" icon color="black"><v-icon>mdi-close</v-icon> </v-btn>
      </div>
      <div class="standart-modal-card-middle">
        <div>
          <v-row class="d-flex justify-space-between">
            <v-col cols="4" v-if="availablities?.woltInstances">
              <div v-for="(instance, index) in availablities.woltInstances" :key="instance.id + instance.providerName"
                class="status-tag mb-1">
                <div :style="`background-color: ${statusColor('wolt', index)}`" class="status-circle"></div>
                <h3>{{ instance.providerName }}</h3>
                <Button :text="instance.isAvailable ? 'Close ' : 'Open '" class="mb-1" type="button"
                  @handleClick="changeVenueStatus(1, !instance.isAvailable, instance.id)" />
              </div>
            </v-col>
            <v-col cols="4" v-if="availablities?.boltInstances">
              <div v-for="(instance, index) in availablities.boltInstances" :key="instance.id + instance.providerName"
                class="status-tag mb-1">
                <div :style="`background-color: ${statusColor('bolt', index)}`" class="status-circle"></div>
                <h3>{{ instance.providerName }}</h3>
                <Button :text="instance.isAvailable ? 'Close ' : 'Open '" class="mb-1" type="button"
                  @handleClick="changeVenueStatus(2, !instance.isAvailable, instance.id)" />
              </div>
            </v-col>
            <v-col cols="4" v-if="availablities?.glovoInstances">
              <div v-for="(instance, index) in availablities.glovoInstances" :key="instance.id + instance.providerName"
                class="status-tag mb-1">
                <div :style="`background-color: ${statusColor('glovo', index)}`" class="status-circle"></div>
                <h3>{{ instance.providerName }}</h3>
                <Button :text="instance.isAvailable ? 'Close ' : 'Open '" class="mb-1" type="button"
                  @handleClick="changeVenueStatus(4, !instance.isAvailable, instance.id)" />
              </div>
            </v-col>
            <!-- <v-col cols="4" v-if="availablities?.fudyInstances">
              <div v-for="(instance, index) in availablities.fudyInstances" :key="instance.id + instance.providerName"
                class="status-tag mb-1">
                <div :style="`background-color: ${statusColor('bolt', index)}`" class="status-circle"></div>
                <h3>{{ instance.providerName }}</h3>
                <Button :text="instance.isAvailable ? 'Close ' : 'Open '" class="mb-1" type="button"
                  @handleClick="changeVenueStatus(3, !instance.isAvailable, instance.id)" />
              </div>
            </v-col> -->
          </v-row>
        </div>
      </div>
      <div class="standart-modal-card-bottom">
        <div class="d-flex align-center justify-end">
          <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
            @handleClick="closeModal" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';
import { mapGetters } from 'vuex';

export default {
  name: 'VenueModal',
  components: {
    Button,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: {
      type: Boolean,
    },
    action: {
      type: String,
    },
    changeStatus: {
      type: Function,
    },
    availablities: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    isWoltSettingsPresent: {
      type: Boolean,
    },
    isBoltSettingsPresent: {
      type: Boolean,
    },
    isFudySettingsPresent: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('app', ['getCurrentVenue']),
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    closeModal() {
      this.syncedOpened = false;
      this.$emit('closeVenueModal');
    },
    changeVenueStatus(disId, isOnline, instanceId) {
      this.changeStatus(disId, isOnline, instanceId);
    },
    statusColor(provider, index) {
      console.log(provider);

      if (provider === 'wolt') {
        return this.availablities.woltInstances[index].isAvailable ? 'green' : 'red';
      }
      if (provider === 'bolt') {
        return this.availablities.boltInstances[index].isAvailable ? 'green' : 'red';
      }
      if (provider === 'fudy') {
        return this.availablities.fudyInstances[index].isAvailable ? 'green' : 'red';
      } if (provider === 'glovo') {
        return this.availablities.glovoInstances[index].isAvailable ? 'green' : 'red';
      }
      return '#ccc';
    },
  },
};
</script>

<style lang="scss" scoped>
.status-circle {
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid gray;
}

.status-tag {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 10px;

  button {
    background-color: #5170e0;
    border-radius: 5px;
    width: unset;
    padding: 5px 15px;
    color: #fff;
    font-weight: 700;
  }
}
</style>
