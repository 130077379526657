var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { position: "relative", top: "40%", "text-align": "center" },
    },
    [
      _c("h1", [_vm._v("404")]),
      _c("h1", [_vm._v("Page not found")]),
      _c(
        "router-link",
        { staticStyle: { "font-size": "1.625rem" }, attrs: { to: _vm.link } },
        [_vm._v("Go home")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }