import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { HUB_RETRY_DELAY } from '../data';
import { ORDER_HUB_URL } from './apiVars';
import { generateReconnectDelays } from '../utils';

class OrderHubClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl; // Базовый URL хаба
    this.client = null;
  }

  async start(id) {
    try {
      this.client = new HubConnectionBuilder()
        .withUrl(`${this.baseUrl}${id ? `?venueId=${id}` : ''}`)
        .withAutomaticReconnect(generateReconnectDelays())
        .configureLogging(LogLevel.Information)
        .build();

      await this.client.start();
      console.log('Connection started with ID:', id);
    } catch (err) {
      console.error('start', err);
      setTimeout(() => this.start(id), HUB_RETRY_DELAY); // Повторное подключение
    }
  }

  async stop() {
    if (!this.client) return;
    try {
      await this.client.stop();
      console.log('Connection stopped');
    } catch (err) {
      console.error('stop', err);
    }
  }
}
const OrderHub = new OrderHubClient(ORDER_HUB_URL);
const OrderHub2 = new OrderHubClient(ORDER_HUB_URL);

OrderHub?.client?.onclose(async () => {
  await OrderHub.start();
});

export { OrderHub2 };
export default OrderHub;
