<template>
  <div>
    <Header :drawer-show="setInputToggleDrawer" />
    <router-view class="content" />
    <v-navigation-drawer v-model="isDrawerShow" temporary right fixed width="340">
      <div class="drawer-block">
        <div class="drawer-block-top pa-5 d-flex align-center justify-space-between">
          <h2>Settings</h2>
          <v-btn @click="setInputToggleDrawer(false)" large icon color="black"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>

        <v-switch @change="switchSoundNotifSet" class="standart-switch px-4" v-model="generalSettings.soundNotif" inset
          label="Sound notifications"></v-switch>

        <div class="pa-2">
          <p>Change Font-Size of Application</p>
          <v-row class="mb-1">
            <v-col cols="5"><Button text="-" type="button" styleType="secondary"
                @handleClick="decreaseFontSize" /></v-col>
            <v-col class="my-auto text-center">{{ getFontSize }}</v-col>
            <v-col cols="5"><Button text="+" type="button" styleType="secondary"
                @handleClick="increaseFontSize" /></v-col>
          </v-row>
          <Button text="Apply" type="button" styleType="primary" @handleClick="applyFontSize" />
        </div>

        <div class="drawer-block-middle pa-2">
          <Button text="Reload Page" type="button" styleType="secondary" @handleClick="reloadPage" />
        </div>

        <v-divider />

        <div v-if="isDrawerFieldShow('status')" class="drawer-block-middle pa-2">
          <div v-if="hasAvailability">
            <div v-for="(wolt, index) in currentVenue.availablities.woltInstances" :key="wolt.id + wolt.providerName"
              class="d-flex align-center mb-2">
              <div :style="`background-color: ${statusColor('wolt', index)}`" class="status-circle"></div>
              <h3>{{ wolt.providerName }}</h3>
            </div>
            <div v-for="(bolt, index) in currentVenue.availablities.boltInstances" :key="bolt.id + bolt.providerName"
              class="d-flex align-center mb-2">
              <div :style="`background-color: ${statusColor('bolt', index)}`" class="status-circle"></div>
              <h3>{{ bolt.providerName }}</h3>
            </div>
            <div v-for="(fudy, index) in currentVenue.availablities.fudyInstances" :key="fudy.id + fudy.providerName"
              class="d-flex align-center mb-2">
              <div :style="`background-color: ${statusColor('fudy', index)}`" clgass="status-circle"></div>
              <h3>{{ fudy.providerName }}</h3>
            </div>
            <div v-for="(fudy, index) in currentVenue.availablities.glovoInstances" :key="fudy.id + fudy.providerName"
              class="d-flex align-center mb-2">
              <div :style="`background-color: ${statusColor('glovo', index)}`" clgass="status-circle"></div>
              <h3>{{ fudy.providerName }}</h3>
            </div>
          </div>

          <Button
            v-if="(isWoltSettingsPresent || isBoltSettingsPresent || isFudySettingsPresent || isGlovoSettingsPresent) && hasAvailability"
            text="Change status" type="button" styleType="secondary" @handleClick="openVenueModal" />
          <Button v-if="isDrawerFieldShow('checkRk')" class="mt-3" text="Check Rkeeper" type="button"
            :isLoading="isCheckingRk" @handleClick="checkEntityConnection('rk')" />
          <Button v-if="isDrawerFieldShow('checkProvider')" text="Check provider" class="mt-3" type="button"
            @handleClick="openInstanceCheckModal" />
          <Button v-if="isDrawerFieldShow('updateMenu')" class="mt-3" text="Update menu now" type="button"
            styleType="secondary" :isLoading="isLoading" @handleClick="openMenuModal" />
        </div>

        <div class="drawer-block-middle pa-2">
          <Button :text="showDateTimeSettings ? 'Hide date/time settings' : 'Show date/time settings'" type="button"
            styleType="secondary" @handleClick="showDateTimeSettings = !showDateTimeSettings" />
        </div>

        <div v-if="showDateTimeSettings"
          style="border: 1px #a2a2a2 solid; border-radius: 15px; margin: 0 5px 10px 5px; padding: 5px">
          <div class="px-5 d-flex align-center justify-space-between">
            <p>Time format</p>
            <div>
              <v-radio-group v-model="generalSettings.timeFormat" class="ma-0">
                <v-radio label="12 hours (AM/PM)" :value="1" />
                <v-radio label="24 hours" :value="2" />
              </v-radio-group>
            </div>
          </div>

          <div class="px-5 d-flex align-center justify-space-between">
            <p>Order in date</p>
            <div>
              <v-radio-group v-model="generalSettings.dateOrder" class="ma-0">
                <v-radio label="DD MM YYYY" :value="1" />
                <v-radio label="MM DD YYYY" :value="2" />
              </v-radio-group>
            </div>
          </div>

          <div class="px-5 d-flex align-center justify-space-between">
            <p>Separator in date</p>
            <div>
              <v-radio-group v-model="generalSettings.dateSeparator" class="ma-0">
                <v-radio label="Point '.'" value="." />
                <v-radio label="Slash '/'" value="/" />
              </v-radio-group>
            </div>
          </div>
          <div class="px-2 d-flex align-center justify-space-between">
            <Button text="Save" type="button" @handleClick="saveGeneralSettings" />
          </div>
        </div>

        <!-- <div class="drawer-block-middle pa-2">
          <Button
            :text="showOrderCardSettings ? 'Hide order card settings' : 'Show order card settings'"
            type="button"
            styleType="secondary"
            @handleClick="showOrderCardSettings = !showOrderCardSettings"
          />
        </div>

        <div
          v-if="showOrderCardSettings"
          style="border: 1px #a2a2a2 solid; border-radius: 15px; margin: 0 5px 10px 5px; padding: 5px"
        >
          <div class="px-5 d-flex align-center justify-space-between">
            <p>Order card mode: Popup</p> 
            <div>
              <v-radio-group v-model="generalSettings.orderCardMode" class="ma-0">
                <v-radio label="Popup" :value="2" />
                <v-radio label="Extended" :value="1" />
              </v-radio-group>
            </div>
          </div> 

          <div class="px-2 d-flex align-center justify-space-between">
            <Button text="Save" type="button" @handleClick="saveGeneralSettings" />
          </div>
        </div> -->
      </div>
      <div v-if="getUserData.roleNames?.[0].toLowerCase() === 'operator'" class="d-flex justify-center align-end">
        <button class="standart-button-secondary logout-btn mb-3" @click.stop="logout">
          <v-icon>mdi-logout</v-icon>Sign out
        </button>
      </div>
    </v-navigation-drawer>
    <VenueModal v-model="isVenueModalShow" :change-status="changeVenueStatus"
      :availablities="getCurrentVenue.availablities" :isLoading="getLoading" />
    <MenuModal v-model="isMenuModalShow" :venueId="getUserData.venueId" />
    <CheckInstancesModal v-model="isCheckInstancesModalShow" :availablities="getCurrentVenue.availablities"
      @checkWolt="instanceId => checkEntityConnection('wolt', { instanceId })"
      @checkBolt="instanceId => checkEntityConnection('bolt', { instanceId })"
      @checkFudy="instanceId => checkEntityConnection('fudy', { instanceId })" :isCheckingFudy="isCheckingFudy"
      :isCheckingBolt="isCheckingBolt" :isCheckingWolt="isCheckingWolt" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Header from '@/components/Header';
import Button from '@/components/commonComponents/Button';
import { VENUE_STATUSES, TOAST_TYPES, NAVIGATION_DRAWER_FIELDS } from '@/data';
import VenueModal from '@/components/modals/VenueModal';
import MenuModal from '@/components/modals/MenuModal';
import CheckInstancesModal from '@/components/modals/CheckInstancesModal';

console.log('VENUE_STATUSES', 'okey 1111');


export default {
  name: 'MainLayout',
  components: { Header, Button, VenueModal, MenuModal, CheckInstancesModal },
  data() {
    return {
      isVenueModalShow: false,
      isCheckInstancesModalShow: false,
      isCheckingConnection: false,
      isUpdatingMenu: false,
      showDateTimeSettings: false,
      generalSettings: {},
      isDrawerShow: false,
      isCheckingRk: false,
      isCheckingWolt: false,
      isCheckingBolt: false,
      isCheckingFudy: false,
      showOrderCardSettings: false,
      isMenuModalShow: false,
    };
  },
  mounted() {

    if (localStorage.getItem('settings')) {
      this.setGeneralSettings(JSON.parse(localStorage.getItem('settings')));
    }
    this.generalSettings = { ...this.getGeneralSettings };

    document.documentElement.style.fontSize = `${this.getFontSize}px`;

    this.$orderHub.client.on('SendVenueStatus', status => {
      let dataToSend = {
        venueId: status.id,
        availablities: status.availablities,
        field: 'availablities',
      };
      this.checkAndEditCurrentVenue(dataToSend);
      this.checkAndEditVenue(dataToSend);
    });

    try {
      this.$orderHub2.start(this.getUserData.venueId);
      this.$orderHub2.client.on('UpdateMenu', async (data, newMenu, ...args) => {
        if (data === 'Started') {
          this.setLoadingBoolean(true)
          return;
        }

        if (data === 'MenuGetStarted') {
          this.setLoadingBoolean(true)
          return
        }
        if (data === 'MenuGetFinished' && newMenu) {
          localStorage.setItem('menuVersion', newMenu.menuVersion)
          const date = new Date();
          this.setLastUpdateTime(date);
          this.setMenu(newMenu);
          this.setWoltsInCorrectDiscounts(newMenu.woltsInCorrectDiscounts || []);
          localStorage.setItem(this.getUserData.venueId, JSON.stringify({ menu: newMenu, lastUpdateTime: date }));
          this.setLoadingBoolean(false)
          return;
        }

        if (data === 'Finished' && newMenu) {
          const removeKeys = (obj, keysToRemove) => {
            return Object.keys(obj)
              .filter(key => !keysToRemove.includes(key))
              .reduce((acc, key) => {
                acc[key] = obj[key];
                return acc;
              }, {});
          };
          const date = new Date();
          this.setLastUpdateTime(date);
          this.setWoltsInCorrectDiscounts(newMenu.woltsInCorrectDiscounts || []);
          const menu = removeKeys(newMenu, ['woltsInCorrectDiscounts', 'lastMenuVersion', 'menuVersion']);

          this.setMenu(menu);
          localStorage.setItem(this.getUserData.venueId, JSON.stringify({ menu: menu, lastUpdateTime: date }));
          this.setLoadingBoolean(false)
        }
      });
    } catch (error) {
      console.log(error);

    }
  },


  beforeDestroy() {
    this.$orderHub.client.off('SendVenueStatus');
    this.$orderHub2.client.off('UpdateMenu')
  },
  computed: {
    ...mapGetters('app', [
      'getUserData',
      'getInit',
      'getRoles',
      'getLoading',
      'getAuth',
      'getCurrentVenue',
      'getGeneralSettings',
      'getAdditionalUserSettings',

    ]),
    ...mapGetters({
      isLoading: 'venues/isLoading',
    }),

    showProgress() {
      return this.getLoading;
    },
    getFontSize() {
      return this.getAdditionalUserSettings('FontSize');
    },
    isInitApp() {
      return this.getInit;
    },
    getUserAuth() {
      return this.getAuth;
    },
    currentVenue() {
      return this.getCurrentVenue;
    },
    venueStatus() {
      if (!Object.prototype.hasOwnProperty.call(this.getCurrentVenue, 'isOnline')) {
        return { color: 'white' };
      }
      return VENUE_STATUSES.find(el => el.value === this.getCurrentVenue.isOnline);
    },
    isCurrentVenueFetched() {
      let isFetched = Object.prototype.hasOwnProperty.call(this.getCurrentVenue, 'id');
      return isFetched;
    },
    isWoltSettingsPresent() {
      if (!this.isCurrentVenueFetched) return false;
      let isPresent = this.getCurrentVenue.woltSettings.length > 0;
      return isPresent;
    },
    isBoltSettingsPresent() {
      if (!this.isCurrentVenueFetched) return false;
      let isPresent = this.getCurrentVenue.boltSettings.length > 0;
      return isPresent;
    },
    isGlovoSettingsPresent() {
      if (!this.isCurrentVenueFetched) return false;
      let isPresent = this.getCurrentVenue.glovoSettings.length > 0;
      return isPresent;
    },
    isFudySettingsPresent() {
      if (!this.isCurrentVenueFetched) return false;
      let isPresent = this.getCurrentVenue.fudySettings.length > 0;
      return isPresent;
    },
    hasAvailability() {
      const isPresent = Object.prototype.hasOwnProperty.call(this.getCurrentVenue || {}, 'availablities');
      return isPresent;
    },
  },
  methods: {
    ...mapMutations({
      setGeneralSettings: 'app/setGeneralSettings',
      setSoundNotifSetting: 'app/setSoundNotifSetting',
      checkAndEditCurrentVenue: 'app/checkAndEditCurrentVenue',
      checkAndEditVenue: 'venues/checkAndEditVenue',
      setMenu: 'venues/setMenu',
      setLastUpdateTime: 'venues/setLastUpdateTime',
      logout: 'app/logout',
      changeAdditionalUserSettings: 'app/changeAdditionalUserSettings',
      setLoadingBoolean: 'venues/setLoadingBoolean',
      setWoltsInCorrectDiscounts: 'venues/setWoltsInCorrectDiscounts',
    }),
    ...mapActions({
      changeAvailability: 'venues/changeAvailability',
      checkConnection: 'venues/checkConnection',
      checkAggregatorStatus: 'venues/checkAggregatorStatus',
      fetchMenu: 'venues/fetchMenu',
      updateAdditionalUserSettings: 'app/updateAdditionalUserSettings',
    }),
    openInstanceCheckModal() {
      this.isCheckInstancesModalShow = true;
    },
    increaseFontSize() {
      const updatedFontSize = this.getFontSize + 1;
      this.changeAdditionalUserSettings({ field: 'FontSize', value: updatedFontSize });
      // this.setFontSize(updatedFontSize)
      document.documentElement.style.fontSize = `${updatedFontSize}px`;
    },
    decreaseFontSize() {
      const updatedFontSize = this.getFontSize - 1;
      this.changeAdditionalUserSettings({ field: 'FontSize', value: updatedFontSize });
      // this.setFontSize(updatedFontSize)
      document.documentElement.style.fontSize = `${updatedFontSize}px`;
    },
    applyFontSize() {
      this.updateAdditionalUserSettings()
        .then(() => {
          this.$toast.open({ message: 'User settings has been successfully edited!', type: TOAST_TYPES.SUCCESS });
        })
        .catch(() => {
          this.$toast.open({ message: "User settings hasn't been edited!", type: TOAST_TYPES.ERROR });
        });
    },
    switchSoundNotifSet(val) {
      this.setSoundNotifSetting(val);
    },
    setInputToggleDrawer(val) {
      this.isDrawerShow = val;
    },
    statusColor(provider, index) {
      const has = Object.prototype.hasOwnProperty.call(this.currentVenue, 'availablities');
      if (provider === 'wolt' && has) {
        return this.currentVenue.availablities.woltInstances[index].isAvailable ? 'green' : 'red';
      }
      if (provider === 'bolt' && has) {
        return this.currentVenue.availablities.boltInstances[index].isAvailable ? 'green' : 'red';
      }
      if (provider === 'fudy' && has) {
        return this.currentVenue.availablities.fudyInstances[index].isAvailable ? 'green' : 'red';
      }
      return '#ccc';
    },
    isDrawerFieldShow(field) {
      if (!this.getRoles) {
        return false;
      }
      return NAVIGATION_DRAWER_FIELDS[this.getRoles?.[0].toUpperCase()].includes(field);
    },
    saveGeneralSettings() {
      try {
        this.setGeneralSettings(this.generalSettings);
        this.$toast.open({ message: 'Settings has been successfully edited!', type: TOAST_TYPES.SUCCESS });
      } catch (err) {
        this.$toast.open({ message: "Settings hasn't been edited!", type: TOAST_TYPES.ERROR });
      }
    },
    async reloadPage() {
      try {
        // Удаление всех зарегистрированных Service Workers
        if ('serviceWorker' in navigator) {
          const registrations = await navigator.serviceWorker.getRegistrations();
          for (const registration of registrations) {
            await registration.unregister();
          }
          console.log('Service Worker cache cleared.');
        }

        // Очистка кэша через Cache Storage API
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          for (const cacheName of cacheNames) {
            await caches.delete(cacheName);
          }
          console.log('Browser cache cleared.');
        }

        // Очистка localStorage, кроме версии
        const version = localStorage.getItem('version'); // Сохраняем версию
        localStorage.clear(); // Полностью очищаем localStorage
        if (version) {
          localStorage.setItem('version', version); // Восстанавливаем версию
        }
        console.log('LocalStorage cleared, version preserved.');

        // Полная очистка cookies
        // const cookies = document.cookie.split(';');
        // for (const cookie of cookies) {
        //   const [key] = cookie.split('=');
        //   const trimmedKey = key.trim();

        //   // Удаление cookie
        //   document.cookie = `${trimmedKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        // }
        // console.log('All cookies cleared.');

        // Перезагрузка страницы с обходом кэша
        window.location.href = window.location.origin + `?timestamp=${Date.now()}`;
      } catch (error) {
        console.error('Failed to clear cache:', error);
      }
    },
    openVenueModal() {
      this.isVenueModalShow = true;
    },
    closeVenueModal() {
      this.isVenueModalShow = false;
    },
    async changeVenueStatus(disId, isOnline, instanceId) {
      try {
        let dataToSend = {
          id: this.getCurrentVenue.id,
          distributorId: disId,
          isOnline: isOnline,
          instanceId: instanceId,
        };
        await this.changeAvailability(dataToSend);
      } catch (err) {
        console.log('err', err);
        this.$toast.open({ message: 'Unexpected error!', type: TOAST_TYPES.ERROR });
      }
    },
    async checkEntityConnection(type, payload) {
      let entityToCheck = {};

      let role = this.getRoles?.[0].toLowerCase();

      if (role === 'operator' || role === 'manager') {
        entityToCheck.id = this.getCurrentVenue.id;
      } else {
        entityToCheck.id = this.venueId;
      }

      if (type === 'rk') {
        entityToCheck.loading = 'isCheckingRk';
        entityToCheck.distributorId = 20;
      } else if (type === 'wolt') {
        entityToCheck.loading = 'isCheckingWolt';
        entityToCheck.distributorId = 1;
      } else if (type === 'fudy') {
        entityToCheck.loading = 'isCheckingFudy';
        entityToCheck.distributorId = 3;
      } else if (type === 'bolt') {
        entityToCheck.loading = 'isCheckingBolt';
        entityToCheck.distributorId = 2;
        entityToCheck.instanceId = payload.instanceId;
      }

      this[entityToCheck.loading] = true;
      try {
        if (type === 'wolt') {
          const data = await this.checkAggregatorStatus({
            url: `venues/${this.getCurrentVenue.id}/check-wolt`,
            params: { instanceId: payload.instanceId },
          });
          if (data['is_online']) {
            this.$toast.open({ message: data, type: TOAST_TYPES.SUCCESS });
          } else {
            this.$toast.open({ message: data, type: TOAST_TYPES.ERROR });
          }
        } else if (type === 'fudy') {
          const data = await this.checkAggregatorStatus({
            url: `venues/${this.getCurrentVenue.id}/check-fudy`,
            params: { instanceId: payload.instanceId },
          });
          if (data['is_online']) {
            this.$toast.open({ message: data, type: TOAST_TYPES.SUCCESS });
          } else {
            this.$toast.open({ message: data, type: TOAST_TYPES.ERROR });
          }
        } else {
          let data = await this.checkConnection(entityToCheck);
          if (Object.prototype.hasOwnProperty.call(data, 'isOk')) {
            if (data.isOk) {
              this.$toast.open({ message: 'Ok!', type: TOAST_TYPES.SUCCESS });
            } else {
              this.$toast.open({ message: data.errorText, type: TOAST_TYPES.ERROR });
            }
          } else {
            this.$toast.open({ message: data, type: TOAST_TYPES.ERROR });
          }
        }
        this[entityToCheck.loading] = false;
      } catch (err) {
        console.log(err);
        this[entityToCheck.loading] = false;
        this.$toast.open({ message: 'Unexpected error!', type: TOAST_TYPES.ERROR });
      }
    },
    async openMenuModal() {
      if (
        localStorage.getItem(this.getUserData.venueId)
        // JSON.stringify(localStorage.getItem(this.getUserData.venueId).menu) === '{}'
      ) {
        console.log('from localstorage', JSON.parse(localStorage.getItem(this.getUserData.venueId)).menu);

        this.setMenu(JSON.parse(localStorage.getItem(this.getUserData.venueId)).menu);
        this.setLastUpdateTime(JSON.parse(localStorage.getItem(this.getUserData.venueId)).lastUpdateTime);
        this.isMenuModalShow = true;
      } else {
        const menu = await this.fetchMenu(this.getUserData.venueId);
        const date = new Date();
        this.setLastUpdateTime(date);
        localStorage.setItem(this.getUserData.venueId, JSON.stringify({ menu, lastUpdateTime: date }));
        this.isMenuModalShow = true;
      }
    },
    closeMenuModal() {
      this.isMenuModalShow = false;
    },
  },
  watch: {
    isLoading(bol) {
    }
  }
};
</script>

<style>
.content {
  margin-top: 60px;
}

.status-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.logout-btn {
  margin: auto 8px;
  padding: 16px 0;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: 700;
}
</style>
