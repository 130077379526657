<template>
  <div>
    <span class="aggregator">{{ aggregator }}</span>
    <v-text-field :value="value" @input="onInputChange" :label="label"
      class="standart-input-filled standart-input-no-message mb-6" :disabled="isDisabled" :rules="rules" type="text"
      color="dark_grey" filled rounded dense>
      <template v-slot:append-outer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              mdi-help-circle
            </v-icon>
          </template>
          {{ tootltipText }}
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    field: String,
    aggregator: String,
    value: String,
    label: String,
    tootltipText: String,
    rules: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInputChange(e) {
      this.$emit('changeRKeeperSettingsField', this.field, e)
    }
  }
};
</script>

<style lang="scss" scoped>
.aggregator {
  margin-left: 15px;
  font-size: 13px;
  font-weight: bold;
}
</style>
