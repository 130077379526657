var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "480", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c("div", { staticClass: "standart-modal-card" }, [
        _c(
          "div",
          {
            staticClass:
              "standart-modal-card-top d-flex align-center justify-space-between",
          },
          [
            _c("h3", [_vm._v("Are you sure?")]),
            _c(
              "v-btn",
              {
                attrs: { icon: "", color: "black" },
                on: { click: _vm.closeModal },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "standart-modal-card-middle" }, [
          _c("p", [
            _vm._v(
              " Pre-order is due " +
                _vm._s(_vm.date) +
                " , after " +
                _vm._s(_vm.minutes) +
                " minutes, are you sure you want to move it to production now? "
            ),
          ]),
        ]),
        _c("div", { staticClass: "standart-modal-card-bottom" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-end" },
            [
              _c("Button", {
                staticClass: "standart-button-content-width mr-2",
                attrs: { styleType: "secondary", text: "No", type: "button" },
                on: { handleClick: _vm.closeModal },
              }),
              _c("Button", {
                staticClass: "standart-button-content-width mr-2",
                attrs: { styleType: "primary", text: "Yes", type: "button" },
                on: { handleClick: _vm.handler },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }