var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "col-lg-3 col-md-12 col-12" }, [
            _c("h2", { staticClass: "sidebar-title mb-lg-6" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _c("v-col", { staticClass: "col-lg-6 col-md-12" }, [
            _c(
              "div",
              { staticClass: "standart-card" },
              [
                _c("RoleForm", {
                  key: _vm.tabRoles.DEALER + this.roleFormKey,
                  attrs: {
                    role: _vm.tabRoles.DEALER,
                    populatedForm: _vm.entityItem,
                    venueGroupFormId: _vm.getActiveVenueGroupId,
                    activeTabId: _vm.tabRoles.DEALER,
                    isFormLoading: _vm.isLoading,
                  },
                  on: {
                    submitForm: _vm.submitForm,
                    handleCancel: _vm.handleCancel,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }