<template>
  <v-container class="pa-6" fluid>
    <v-row class="justify-center align-center">
      <v-col lg="8" md="10" sm="12">
        <v-tabs v-model="tab" align-with-title class="tabs-bar">
          <v-tab>
            <h2 class="sidebar-title my-6">Requests</h2>
          </v-tab>
          <v-tab v-if="isAdmin">
            <h2 class="sidebar-title my-6">Licences</h2>
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col lg="8" md="10" sm="12">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <LicenseRequestsTable />
          </v-tab-item>
          <v-tab-item>
            <LicenseTable />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import LicenseRequestsTable from '../components/LicenseRequestsTable.vue';
import LicenseTable from '../components/LicenseTable.vue';

export default {
  name: 'LicRequests',
  data() {
    return {
      tab: null
    };
  },
  components: {
    LicenseRequestsTable,
    LicenseTable
  },
  computed: {
    ...mapGetters({
      getRoles: 'app/getRoles',
    }),
    isAdmin() {
      return this.getRoles[0].toLowerCase() === 'admin';
    },
  },
  methods: {
    convertDateToLocal(date) {
      return this.formatDateTime(this.dateTimeFormats, date);
    },
  }
};
</script>

<style lang="scss">
.tabs-bar {
  &>div {
    background-color: transparent !important;
  }
}
</style>
