import 'vuetify-dialog/dist/vuetify-dialog.css';
import './assets/style.scss';
import 'vue-toast-notification/dist/theme-default.css';

import * as dayjs from 'dayjs';

import OrderHubPlugin, { orderHub2 } from './plugins/orderHubPlugin';

import App from './App.vue';
import DatetimePicker from 'vuetify-datetime-picker';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VuetifyDialog from 'vuetify-dialog';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import router from './router';
import store from './store';
import utc from 'dayjs/plugin/utc';
import vuetify from './plugins/vuetify';

async function clearCacheAndReload() {
  try {
    // Удаление всех зарегистрированных Service Workers
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
      console.log('Service Worker cache cleared.');
    }

    // Очистка кэша через Cache Storage API
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        await caches.delete(cacheName);
      }
      console.log('Browser cache cleared.');
    }

    // Очистка localStorage, кроме версии
    const version = localStorage.getItem('version'); // Сохраняем версию
    localStorage.clear(); // Полностью очищаем localStorage
    if (version) {
      localStorage.setItem('version', version); // Восстанавливаем версию
    }
    console.log('LocalStorage cleared, version preserved.');

    // Полная очистка cookies
    // const cookies = document.cookie.split(';');
    // for (const cookie of cookies) {
    //   const [key] = cookie.split('=');
    //   const trimmedKey = key.trim();

    //   // Удаление cookie
    //   document.cookie = `${trimmedKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    // }
    // console.log('All cookies cleared.');

    // Перезагрузка страницы с обходом кэша
    window.location.href = window.location.origin + `?timestamp=${Date.now()}`;
  } catch (error) {
    console.error('Failed to clear cache:', error);
  }
}

function checkVersion() {
  // Получаем текущую версию из мета-тега
  const metaTag = document.querySelector('meta[name="app-version"]');
  const newVersion = metaTag ? metaTag.content : null;

  if (!newVersion) {
    console.error('Version meta tag not found.');
    return;
  }

  // Проверяем сохранённую версию
  const storedVersion = localStorage.getItem('version');
  if (storedVersion !== newVersion) {
    // Обновляем версию в localStorage
    localStorage.setItem('version', newVersion);
    clearCacheAndReload();
  }
}

checkVersion();

Vue.use(OrderHubPlugin);
Vue.use(DatetimePicker);

Vue.use(VueToast, {
  dissmissable: true,
  duration: 2500,
  position: 'top',
  pauseOnHover: true,
});

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: {
      false: {
        text: 'Cancel',
        color: 'error',
      },
      true: {
        text: 'Yes',
        color: 'primary',
      },
    },
  },
});

Vue.config.productionTip = false;

dayjs.extend(localizedFormat);
dayjs.extend(utc);

export default dayjs;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
