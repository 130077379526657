var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c("div", { staticClass: "standart-modal-card" }, [
        _c(
          "div",
          {
            staticClass:
              "standart-modal-card-top d-flex align-center justify-space-between",
          },
          [
            _c("h3", [_vm._v(_vm._s(_vm.title))]),
            _c(
              "v-btn",
              {
                attrs: { icon: "", color: "black" },
                on: { click: _vm.closeModal },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "standart-modal-card-middle" },
          [
            _vm.isAsap
              ? _c(
                  "h3",
                  {
                    staticClass:
                      "standart-modal-card-subtitle font-weight-bold",
                  },
                  [_vm._v("ASAP")]
                )
              : _vm._e(),
            _vm.timeToShow
              ? _c("h3", { staticClass: "standart-modal-card-subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.timeToShow) + " "),
                ])
              : _vm._e(),
            _vm.tillEtaTime
              ? _c("h3", { staticClass: "standart-modal-card-subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.tillEtaTimeText) + " "),
                ])
              : _vm._e(),
            _vm.orderStatus.PREORDER === this.status ||
            _vm.orderStatus.DELAYED === this.status
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-space-between",
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "standart-modal-card-subtitle" },
                        [
                          _vm._v(" Chosen cooking time: "),
                          _c("span", [_vm._v(_vm._s(_vm.inputTimeValue))]),
                          _vm._v(" minutes "),
                        ]
                      ),
                      _c("Button", {
                        staticClass: "standart-button-content-width rounded",
                        attrs: { text: "Edit time", type: "button" },
                        on: { handleClick: _vm.showGrid },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isShowGrid
              ? [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "standart-modal-card-subtitle text-center mb-3",
                    },
                    [_vm._v("Choose cooking time")]
                  ),
                  _vm.isBoltOrWoltOrder === "boltOrder"
                    ? _c(
                        "button",
                        {
                          staticClass: "mb-1 no-delay-button",
                          class: _vm.isNoDelayActive
                            ? "no-delay-button-active"
                            : "",
                          on: { click: _vm.noDelayHandler },
                        },
                        [_vm._v(" No Delay ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "time-grid" },
                    _vm._l(_vm.timeGridData, function (item) {
                      return _c(
                        "v-btn",
                        {
                          key: item,
                          staticClass: "time-grid-item",
                          class:
                            _vm.inputTimeValue == item
                              ? "time-grid-item-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.setTimeGrid(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item) + " "), _c("br"), _vm._v("min ")]
                      )
                    }),
                    1
                  ),
                  _c("Button", {
                    staticClass: "mt-2 rounded",
                    attrs: {
                      styleType: "secondary",
                      text: "Choose custom time",
                      type: "button",
                    },
                    on: {
                      handleClick: function ($event) {
                        _vm.isCustomTimeShow = !_vm.isCustomTimeShow
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCustomTimeShow,
                          expression: "isCustomTimeShow",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "custom-input-number mt-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "custom-input-number-btn custom-input-number-btn-left",
                              on: {
                                click: function ($event) {
                                  return _vm.setInputTime("-")
                                },
                              },
                            },
                            [_vm._v("-")]
                          ),
                          _c(
                            "div",
                            { staticClass: "custom-input-number-field" },
                            [_vm._v(" " + _vm._s(_vm.inputTimeValue) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "custom-input-number-btn custom-input-number-btn-right",
                              attrs: { disabled: _vm.timeIncreaseDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.setInputTime("+")
                                },
                              },
                            },
                            [_vm._v("+")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "standart-modal-card-bottom" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-end" },
            [
              _c("Button", {
                staticClass: "standart-button-content-width mr-2",
                attrs: {
                  styleType: "secondary",
                  text: "Cancel",
                  type: "button",
                },
                on: { handleClick: _vm.closeModal },
              }),
              _c("Button", {
                staticClass: "standart-button-content-width",
                attrs: { text: "Accept", type: "submit" },
                on: { handleClick: _vm.acceptModal },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }