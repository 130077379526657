<template>
  <div v-if="syncedOpened" overlay-opacity="0.8">
    <div class="modal">
      <div class="modal-content">
        <div class="modal-top">
          <div class="modal-warning">
            <p><span>Warning:</span> Discount for following Wolt items will not be applied! Please check item configuration!</p>
          </div>
        </div>
        <div class="list">
          <ul class="modal-list">
            <li class="modal-item" v-for="item in data" :key="item.displayName">
              <strong>{{ item.displayName }}:</strong>
              <ul class="modal-item-list">
                <li v-for="discount in item.inCorrectDiscounts" :key="discount.id">
                  <span class="modal-item-id">{{ discount.id }}:</span
                  > <span>{{ discount.itemName }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="modal-bottom">
          <Button text="Close" class="standart-button-content-width mr-2" type="button" @handleClick="closeModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/commonComponents/Button';

export default {
  name: 'VenueInstanceModal',
  components: {
    Button,
  },
  props: {
    showInnerModal: Boolean,
    data: {
      type: [],
    },
    close: Function,
  },
  computed: {
    syncedOpened: {
      get() {
        return this.showInnerModal;
      },
      set(val) {
        console.log(val);
        return this.$emit('close');
      },
    },
  },
  methods: {
    closeModal() {
      this.syncedOpened = false;
    },
  },
  mounted() {
    console.log('Received data:', this.data, this.showInnerModal);
  },
};
</script>

<style lang="scss" scoped>
.modal-warning {
  display: inline-flex;
  gap: 3px;
  p {
    font-size: 20px;
    font-weight: 600;
    span {
      color: #5b7ffe;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
.list {
  max-height: 450px;
  overflow-y: scroll;
}
.modal {
  z-index: 99;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: rgb(254, 241, 225);
  padding: 25px;
  border-radius: 8px;
  &-top {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      color: #5b7ffe;
    }
  }
  &-bottom {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
  }
  &-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .modal-item-id{
      color: #5b7ffe;
      font-weight: 600;
    }
  }
  &-item {
    font-size: 18px;
    strong {
      color: #5b7ffe;
    }
    &-list {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
  }
}
</style>
