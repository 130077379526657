var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "roleForm",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.validateForm("roleForm")
            },
          },
          model: {
            value: _vm.isFormValid,
            callback: function ($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid",
          },
        },
        [
          _c("div", { staticClass: "d-flex align-center" }, [
            _c("img", {
              attrs: {
                src: require(`@/assets/icons/${
                  _vm.tabItems[_vm.activeTabId].icon
                }`),
                alt: "",
              },
            }),
            _c("h3", { staticClass: "form-title ml-4" }, [
              _vm._v(_vm._s(_vm.tabItems[_vm.activeTabId].name) + " info"),
            ]),
          ]),
          _c(
            "v-row",
            { staticClass: "mt-2" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "5" } },
                [
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mt-4",
                    attrs: {
                      label: "Login",
                      disabled: !_vm.isFormEditable,
                      rules: [_vm.rules.required],
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "7" } },
                [
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message mb-6 mt-4",
                    class: _vm.tabItems[_vm.activeTabId].selectRest
                      ? ""
                      : "mt-4",
                    attrs: {
                      type: "email",
                      label: "Email",
                      disabled: !_vm.isFormEditable,
                      rules: [_vm.rules.required, _vm.rules.email],
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tabItems[_vm.activeTabId].selectRest
            ? _c("v-autocomplete", {
                staticClass:
                  "standart-input-filled standart-input-no-message mt-4 mb-6",
                attrs: {
                  rules: [_vm.rules.required],
                  items: _vm.selectItems,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Restaurant",
                  loading: _vm.venuesLoading,
                  "loader-height": "5",
                  "no-data-text": "No restaurants in this group",
                  disabled: !_vm.isFormEditable || _vm.isManager,
                  filled: "",
                  rounded: "",
                  dense: "",
                },
                on: { focus: _vm.checkVenues },
                model: {
                  value: _vm.venueId,
                  callback: function ($$v) {
                    _vm.venueId = $$v
                  },
                  expression: "venueId",
                },
              })
            : _vm._e(),
          _vm.tabItems[_vm.activeTabId].selectRestGroup
            ? _c("v-autocomplete", {
                staticClass:
                  "standart-input-filled standart-input-no-message mt-4 mb-6",
                attrs: {
                  rules: [_vm.rules.required],
                  items: _vm.venueGroups,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Restaurant subgroups",
                  loading: _vm.venueGroupsLoading,
                  "loader-height": "5",
                  "no-data-text": "No restaurant subgroups",
                  disabled: !_vm.isFormEditable,
                  filled: "",
                  rounded: "",
                  dense: "",
                },
                on: { focus: _vm.checkVenueGroups },
                model: {
                  value: _vm.venueGroupId,
                  callback: function ($$v) {
                    _vm.venueGroupId = $$v
                  },
                  expression: "venueGroupId",
                },
              })
            : _vm._e(),
          _vm.tabItems[_vm.activeTabId].selectArea
            ? _c("v-autocomplete", {
                staticClass:
                  "standart-input-filled standart-input-no-message mt-4 mb-6",
                attrs: {
                  rules: [_vm.rules.required],
                  items: _vm.areas,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Restaurant groups",
                  loading: _vm.areasLoading,
                  "loader-height": "5",
                  "no-data-text": "No restaurant groups",
                  disabled: !_vm.isFormEditable,
                  filled: "",
                  rounded: "",
                  dense: "",
                },
                on: { focus: _vm.checkAreas },
                model: {
                  value: _vm.areaId,
                  callback: function ($$v) {
                    _vm.areaId = $$v
                  },
                  expression: "areaId",
                },
              })
            : _vm._e(),
          _c("v-text-field", {
            staticClass: "standart-input-filled standart-input-no-message mb-6",
            attrs: {
              label: "Phone number",
              disabled: !_vm.isFormEditable,
              rules: [_vm.rules.required],
              filled: "",
              rounded: "",
              dense: "",
            },
            model: {
              value: _vm.form.phoneNumber,
              callback: function ($$v) {
                _vm.$set(_vm.form, "phoneNumber", $$v)
              },
              expression: "form.phoneNumber",
            },
          }),
          _vm.tabRoles.OPERATOR == _vm.role && !_vm.isNew
            ? _c("v-textarea", {
                staticClass:
                  "standart-input-filled standart-input-no-message mb-6",
                attrs: {
                  label: "Refresh token",
                  disabled: !_vm.isFormEditable,
                  "no-resize": "",
                  rows: "3",
                  filled: "",
                  rounded: "",
                  dense: "",
                },
                model: {
                  value: _vm.refreshToken,
                  callback: function ($$v) {
                    _vm.refreshToken = $$v
                  },
                  expression: "refreshToken",
                },
              })
            : _vm._e(),
          !_vm.isNew
            ? _c("Button", {
                staticClass: "standart-button-content-width mb-4",
                attrs: {
                  text: _vm.isChangingPassword
                    ? "Hide password"
                    : "Change password",
                  type: "button",
                  styleType: "secondary",
                },
                on: {
                  handleClick: function ($event) {
                    _vm.isChangingPassword = !_vm.isChangingPassword
                  },
                },
              })
            : _vm._e(),
          _vm.isChangingPassword || _vm.isNew
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass:
                          "standart-input-filled standart-input-no-message standart-input-with-icon mb-6",
                        attrs: {
                          type: _vm.showPassword ? "text" : "password",
                          label: "Password",
                          disabled: !_vm.isFormEditable,
                          rules: [_vm.rules.required],
                          filled: "",
                          rounded: "",
                          dense: "",
                          "append-icon": _vm.showPassword
                            ? "mdi-eye"
                            : "mdi-eye-off",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showPassword = !_vm.showPassword
                          },
                        },
                        model: {
                          value: _vm.form.pwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pwd", $$v)
                          },
                          expression: "form.pwd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass:
                          "standart-input-filled standart-input-no-message standart-input-with-icon mb-6",
                        attrs: {
                          label: "Confirm password",
                          type: _vm.showConfirmPassword ? "text" : "password",
                          disabled: !_vm.isFormEditable,
                          rules: [_vm.rules.required],
                          filled: "",
                          rounded: "",
                          dense: "",
                          "append-icon": _vm.showConfirmPassword
                            ? "mdi-eye"
                            : "mdi-eye-off",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showConfirmPassword = !_vm.showConfirmPassword
                          },
                        },
                        model: {
                          value: _vm.confirmPwd,
                          callback: function ($$v) {
                            _vm.confirmPwd = $$v
                          },
                          expression: "confirmPwd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between" },
            [
              _c("Button", {
                staticClass: "standart-button-content-width",
                attrs: {
                  text: "Cancel",
                  type: "button",
                  styleType: "secondary",
                },
                on: { handleClick: _vm.handleCancel },
              }),
              _c("Button", {
                staticClass: "standart-button-content-width",
                attrs: { text: "Save", isLoading: _vm.isFormLoading },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }