var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticClass: "login-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "login-column d-flex align-center",
              attrs: { cols: "md-6 sm-12" },
            },
            [
              _c("h4", { staticClass: "version-block" }, [
                _vm._v("Version: 17.09.2024"),
              ]),
              _c(
                "div",
                { staticClass: "login-block" },
                [
                  _c("v-form", { attrs: { disabled: _vm.getLoading } }, [
                    _c("h2", { staticClass: "login-title" }, [
                      _vm._v(" Sign into "),
                      _c("br"),
                      _vm._v(" your "),
                      _c("span", { staticClass: "primary--text" }, [
                        _vm._v("account"),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c("v-text-field", {
                          staticClass: "standart-input-login",
                          attrs: {
                            name: "login",
                            placeholder: "Login",
                            solo: "",
                            flat: "",
                          },
                          model: {
                            value: _vm.authData.login,
                            callback: function ($$v) {
                              _vm.$set(_vm.authData, "login", $$v)
                            },
                            expression: "authData.login",
                          },
                        }),
                        _c("v-text-field", {
                          staticClass: "standart-input-login",
                          attrs: {
                            name: "password",
                            type: _vm.showPassword ? "text" : "password",
                            placeholder: "Password",
                            solo: "",
                            flat: "",
                            "append-icon": _vm.showPassword
                              ? "mdi-eye"
                              : "mdi-eye-off",
                          },
                          on: {
                            "click:append": function ($event) {
                              _vm.showPassword = !_vm.showPassword
                            },
                          },
                          model: {
                            value: _vm.authData.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.authData, "password", $$v)
                            },
                            expression: "authData.password",
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "standart-button standart-button-primary mt-4",
                            attrs: {
                              block: "",
                              color: "primary",
                              large: "",
                              loading: _vm.getLoading,
                              type: "submit",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onLogin.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Sign in ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "h2",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "margin-bottom": "20px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.error) + " ")]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              staticStyle: { "background-color": "#ffffff" },
              attrs: { cols: "md-6" },
            },
            [
              _c("img", {
                staticClass: "login-image",
                attrs: {
                  src: require("@/assets/images/delivery-animation.gif"),
                  alt: "login",
                },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }