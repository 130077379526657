<template>
  <v-dialog v-model="syncedOpened" width="600" overlay-opacity="0.8">
    <div class="standart-modal-card">
      <v-container>
        <h3 class="text-center my-3">Check instance statuses</h3>
        <v-row>
          <v-col cols="4" v-if="availablities?.woltInstances">
            <Button v-for="(instance) in availablities?.woltInstances" :key="instance.id + instance.providerName"
              :text="instance.providerName" class=" mb-1" type="button" @handleClick="checkWolt(instance.id)"
              :isLoading="isCheckingWolt" />
          </v-col>
          <v-col cols="4" v-if="availablities?.fudyInstances">
            <Button v-for="(instance) in availablities?.fudyInstances" :key="instance.id + instance.providerName"
              :text="instance.providerName" class=" mb-1" type="button" @handleClick="checkFudy(instance.id)"
              :isLoading="isCheckingFudy" />
          </v-col>
          <v-col cols="4" v-if="availablities?.boltInstances">
            <Button v-for="(instance) in availablities?.boltInstances" :key="instance.id + instance.providerName"
              :text="instance.providerName" class=" mb-1" type="button" @handleClick="checkBolt(instance.id)"
              :isLoading="isCheckingBolt" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';

export default {
  name: 'CheckInstancesModal',
  components: {
    Button
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: Boolean,
    availablities: {
      type: Object,
    },
    shouldPopulateForm: { type: Boolean, default: false },
    isCheckingFudy: { type: Boolean, default: false },
    isCheckingBolt: { type: Boolean, default: false },
    isCheckingWolt: { type: Boolean, default: false },
  },
  computed: {
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    checkWolt(instanceId) {
      this.$emit('checkWolt', instanceId)
    },
    checkBolt(instanceId) {
      this.$emit('checkBolt', instanceId)
    },
    checkFudy(instanceId) {
      this.$emit('checkFudy', instanceId)
    },
  },
};
</script>

<style lang="scss" scoped></style>