var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _vm.isLoading
        ? _c("StandartLoader")
        : _c(
            "v-row",
            { staticClass: "justify-center align-center" },
            [
              _c(
                "v-col",
                { attrs: { lg: "8", md: "10", sm: "12" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "text-decoration-none text-h6 font-weight-medium",
                      attrs: { to: "/lic-requests" },
                    },
                    [
                      _c("v-icon", { attrs: { color: "#5b7ffe" } }, [
                        _vm._v("mdi-arrow-left"),
                      ]),
                      _vm._v(" Back"),
                    ],
                    1
                  ),
                  _vm.getLicenseActions.length
                    ? _c("h3", { staticClass: "sidebar-title my-5" }, [
                        _vm._v("License actions for: " + _vm._s(_vm.venueName)),
                      ])
                    : _vm._e(),
                  !_vm.getLicenseActions.length
                    ? _c("p", { staticClass: "text-h4 text-center mt-8" }, [
                        _vm._v("No License Actions found"),
                      ])
                    : _vm._l(_vm.getLicenseActions, function (action) {
                        return _c(
                          "v-card",
                          {
                            key: action.id,
                            staticClass: "mx-auto my-5 p-5",
                            attrs: {
                              outlined: "",
                              elevation: "2",
                              rounded: "xl",
                            },
                          },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "d-flex flex-row justify-space-between",
                              },
                              [
                                _c("p", { staticClass: "text-h5" }, [
                                  _vm._v(
                                    "Modified by: " +
                                      _vm._s(action.userName) +
                                      "(" +
                                      _vm._s(action.roleNames[0]) +
                                      ")"
                                  ),
                                ]),
                                _c("p", { staticClass: "text-h5" }, [
                                  _vm._v(
                                    "Modified at: " +
                                      _vm._s(
                                        _vm.convertDateToLocal(
                                          action.modifiedDate
                                        )
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              _vm._l(action.modifiedData, function (field) {
                                return _c("div", { key: field.fieldName }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("p", { staticClass: "text-h6 pr-8" }, [
                                        _vm._v("License: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(field.fieldName.slice(0, 4))
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "text-h6 pr-8" }, [
                                        _vm._v("From: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              field.originData || "No data"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "text-h6" }, [
                                        _vm._v("To: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              field.modifiedData || "No data"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      }),
                ],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }