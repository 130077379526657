var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standart-card d-flex flex-column" },
    [
      _c("h3", [_vm._v("Filters: ")]),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-center justify-space-between mb-3 g-5 border-b-lg",
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticClass: "justify-center align-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-datetime-picker", {
                    attrs: {
                      textFieldProps: _vm.dateTimeTextFieldProps,
                      label: "Start Date",
                      dateFormat: _vm.dateFormat,
                      timeFormat: _vm.timeFormat,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "dateIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              { attrs: { color: "blue darken-2" } },
                              [_vm._v(" mdi-calendar-range ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "timeIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              { attrs: { color: "blue darken-2" } },
                              [_vm._v(" mdi-clock-outline ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.filters.actionPeriodStart,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "actionPeriodStart", $$v)
                      },
                      expression: "filters.actionPeriodStart",
                    },
                  }),
                  _c("v-datetime-picker", {
                    attrs: {
                      textFieldProps: _vm.dateTimeTextFieldProps,
                      label: "End Date",
                      dateFormat: _vm.dateFormat,
                      timeFormat: _vm.timeFormat,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "dateIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              { attrs: { color: "blue darken-2" } },
                              [_vm._v(" mdi-calendar-range ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "timeIcon",
                        fn: function () {
                          return [
                            _c(
                              "v-icon",
                              { attrs: { color: "blue darken-2" } },
                              [_vm._v(" mdi-clock-outline ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.filters.actionPeriodEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "actionPeriodEnd", $$v)
                      },
                      expression: "filters.actionPeriodEnd",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Company Name",
                      type: "text",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "companyName", $$v)
                      },
                      expression: "filters.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "justify-center align-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Venue",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.getIdNames,
                      "item-text": "name",
                      "item-value": "id",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.venueId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "venueId", $$v)
                      },
                      expression: "filters.venueId",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Group",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.getAreas,
                      "item-text": "name",
                      "item-value": "id",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.areaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "areaId", $$v)
                      },
                      expression: "filters.areaId",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Subgroup",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.getVenueGroups,
                      "item-text": "name",
                      "item-value": "id",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.venueGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "venueGroupId", $$v)
                      },
                      expression: "filters.venueGroupId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "justify-center align-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Wolt License",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.licenseStatuses,
                      "item-text": "label",
                      "item-value": "value",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.woltLicenseStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "woltLicenseStatus", $$v)
                      },
                      expression: "filters.woltLicenseStatus",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "bolt License",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.licenseStatuses,
                      "item-text": "label",
                      "item-value": "value",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.boltLicenseStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "boltLicenseStatus", $$v)
                      },
                      expression: "filters.boltLicenseStatus",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Fudy License",
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                      items: _vm.licenseStatuses,
                      "item-text": "label",
                      "item-value": "value",
                      clearable: "",
                    },
                    model: {
                      value: _vm.filters.fudyLicenseStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "fudyLicenseStatus", $$v)
                      },
                      expression: "filters.fudyLicenseStatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "table elevation-3",
        attrs: {
          headers: _vm.headers,
          items: _vm.getLicensesList,
          "item-key": "id",
          "items-per-page": 10,
          "multi-sort": "",
          "no-data-text": "No data",
          "loading-text": "Loading...",
        },
        scopedSlots: _vm._u([
          {
            key: "item.venueSelfSettings.companyName",
            fn: function ({ item }) {
              return [
                item.venueSelfSettings.companyName
                  ? _c("span", [
                      _vm._v(_vm._s(item.venueSelfSettings.companyName)),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]
            },
          },
          {
            key: "item.areaName",
            fn: function ({ item }) {
              return [
                !item.areaName
                  ? _c("span", [_vm._v("---")])
                  : _c("span", [_vm._v(_vm._s(item.areaName))]),
              ]
            },
          },
          {
            key: "item.group.name",
            fn: function ({ item }) {
              return [
                !item.group.name
                  ? _c("span", [_vm._v("---")])
                  : _c("span", [_vm._v(_vm._s(item.group.name))]),
              ]
            },
          },
          {
            key: "item.venueSelfSettings.woltExpiredDateUtc",
            fn: function ({ item }) {
              return [
                item.venueSelfSettings.woltExpiredDateUtc
                  ? _c("span", [
                      item.venueSelfSettings.woltStartDateUtc
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                `${_vm.convertDateToLocal(
                                  item.venueSelfSettings.woltStartDateUtc
                                )}`
                              ) + " - "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.convertDateToLocal(
                              item.venueSelfSettings.woltExpiredDateUtc
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]
            },
          },
          {
            key: "item.venueSelfSettings.boltExpiredDateUtc",
            fn: function ({ item }) {
              return [
                item.venueSelfSettings.boltExpiredDateUtc
                  ? _c("span", [
                      item.venueSelfSettings.boltStartDateUtc
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                `${_vm.convertDateToLocal(
                                  item.venueSelfSettings.boltStartDateUtc
                                )}`
                              ) + " - "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.convertDateToLocal(
                              item.venueSelfSettings.boltExpiredDateUtc
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]
            },
          },
          {
            key: "item.venueSelfSettings.fudyExpiredDateUtc",
            fn: function ({ item }) {
              return [
                item.venueSelfSettings.fudyExpiredDateUtc
                  ? _c("span", [
                      item.venueSelfSettings.fudyStartDateUtc
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                `${_vm.convertDateToLocal(
                                  item.venueSelfSettings.fudyStartDateUtc
                                )}`
                              ) + " - "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.convertDateToLocal(
                              item.venueSelfSettings.fudyExpiredDateUtc
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]
            },
          },
          {
            key: "item.venueSelfSettings.woltLicenseStatus",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(item.venueSelfSettings.woltLicenseStatus) + " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.venueSelfSettings.boltLicenseStatus",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(item.venueSelfSettings.boltLicenseStatus) + " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.venueSelfSettings.fudyLicenseStatus",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(item.venueSelfSettings.fudyLicenseStatus) + " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "ma-1 white--text",
                    attrs: {
                      color: "red",
                      disabled: !item.hasActions,
                      small: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onRowClick(item.id)
                      },
                    },
                  },
                  [_vm._v(" View ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }