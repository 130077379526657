var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "aggregator" }, [
        _vm._v(_vm._s(_vm.aggregator)),
      ]),
      _c("v-text-field", {
        staticClass: "standart-input-filled standart-input-no-message mb-6",
        attrs: {
          value: _vm.value,
          label: _vm.label,
          disabled: _vm.isDisabled,
          rules: _vm.rules,
          type: "text",
          color: "dark_grey",
          filled: "",
          rounded: "",
          dense: "",
        },
        on: { input: _vm.onInputChange },
        scopedSlots: _vm._u([
          {
            key: "append-outer",
            fn: function () {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c("v-icon", _vm._g({}, on), [
                              _vm._v(" mdi-help-circle "),
                            ]),
                          ]
                        },
                      },
                    ]),
                  },
                  [_vm._v(" " + _vm._s(_vm.tootltipText) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }