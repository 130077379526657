<template>
    <v-dialog v-model="syncedOpened" width="480" overlay-opacity="0.8">
        <div class="standart-modal-card">
            <v-form ref="changeVenueGroupForm" v-model="isFormValid" @submit.prevent="validateForm('changeVenueGroupForm')">
                <div class="standart-modal-card-top d-flex align-center justify-space-between">
                    <h3>Change subgroup</h3>
                    <v-btn @click="closeModal" icon color="black"><v-icon>mdi-close</v-icon> </v-btn>
                </div>
                <div class="standart-modal-card-middle">
                    <v-select v-model="venueGroupId" :items="venueGroups" item-text="name" item-value="id"
                        label="Select Group" :rules="[rules.required]" solo flat>
                    </v-select>
                </div>
                <div class="standart-modal-card-bottom">
                    <div class="d-flex align-center justify-end">
                        <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
                            @handleClick="closeModal" />
                        <Button text="Save" class="standart-button-content-width" type="submit" />
                    </div>
                </div>
            </v-form>
        </div>
    </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';

export default {
    name: 'ChangeVenueGroupModal',
    components: {
        Button,
    },
    model: {
        prop: 'opened',
        event: 'opened:update',
    },
    props: {
        opened: Boolean,
        formData: {
            type: [Object, String],
        },
        venueGroups: {
            type: Array,
            default: () => { }
        },
    },
    data() {
        return {
            venueGroupId: '',
            form: {},
            rules: {
                required: value => !!value || 'Required field',
            },
            textFieldKey: 100,
            isFormValid: false,
        };
    },
    watch: {
        formData(newVal) {
            if (newVal) {
                this.populateForm();
            }
        },
    },
    computed: {
        syncedOpened: {
            get() {
                return this.opened;
            },
            set(val) {
                return this.$emit('opened:update', val);
            },
        },
    },
    methods: {
        closeModal() {
            this.syncedOpened = false;
        },
        saveHandle() {
            let formData = { ...this.form, groupId: this.venueGroupId };
            this.$emit('saveForm', formData);
            this.clearForm();
        },
        populateForm() {
            this.venueGroupId = this.formData.groupId;
            this.form = this.formData
        },
        clearForm() {
            this.textFieldKey += 1;
            this.venueGroupId = '';
        },
        validateForm(ref) {
            this.isFormValid = this.$refs[ref].validate();
            if (this.isFormValid) {
                this.saveHandle();
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
