var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-md-4 pt-md-0", attrs: { fluid: "" } },
    [
      _c("h2", { staticClass: "sidebar-title mb-2" }, [
        _vm._v(_vm._s(_vm.getCurrentRest.name)),
      ]),
      _c(
        "div",
        { staticClass: "order-tab-flex pr-md-4" },
        _vm._l(_vm.showColumns, function (data) {
          return _c(
            "div",
            {
              key: data.value,
              staticClass: "order-tab-content",
              class: `col-lg-${data.cols} col-md-${data.mdCols}`,
            },
            [
              _c("div", { staticClass: "order-tab-header" }, [
                _c("h3", [
                  _vm._v(" " + _vm._s(data.title) + " "),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          _vm.getOrders(data.value)
                            ? _vm.getOrders(data.value).length
                            : 0
                        ) +
                        ")"
                    ),
                  ]),
                ]),
              ]),
              _vm._l(_vm.getOrders(data.value), function (order) {
                return _c(
                  "div",
                  { key: order.id },
                  [
                    _c("OrderCard", {
                      attrs: {
                        id: order.id,
                        status: order.statusId
                          ? order.statusId
                          : order.status && order.status.id
                          ? order.status.id
                          : null,
                        order: order,
                        state: order.stateId,
                        isLoading: order.isLoading,
                        page: _vm.page,
                        colValue: data.value,
                        orderErrorMsg: order.error
                          ? order.error.message
                            ? order.error.message
                            : ""
                          : "",
                        isCreatedInRKeeper: order.isCreatedInRKeeper,
                        adjustedTime: order.adjustedTimeInMinutes,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }