var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-lg-6 pa-md-0", attrs: { fluid: "" } },
    [_c("CardStatusBlock", { attrs: { page: 1 } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }