<template>
  <div class="standart-card d-flex flex-column">
    <h3>Filters: </h3>
    <div class="d-flex align-center justify-space-between mb-3 g-5 border-b-lg">
      <v-container>
        <v-row no-gutters class="justify-center align-center">
          <v-datetime-picker :textFieldProps="dateTimeTextFieldProps" label="Start Date"
            v-model="filters.actionPeriodStart" :dateFormat="dateFormat" :timeFormat="timeFormat">
            <template v-slot:dateIcon>
              <v-icon color="blue darken-2"> mdi-calendar-range </v-icon>
            </template>

            <template v-slot:timeIcon>
              <v-icon color="blue darken-2"> mdi-clock-outline </v-icon>
            </template>
          </v-datetime-picker>
          <v-datetime-picker :textFieldProps="dateTimeTextFieldProps" label="End Date" v-model="filters.actionPeriodEnd"
            :dateFormat="dateFormat" :timeFormat="timeFormat">
            <template v-slot:dateIcon>
              <v-icon color="blue darken-2"> mdi-calendar-range </v-icon>
            </template>

            <template v-slot:timeIcon>
              <v-icon color="blue darken-2"> mdi-clock-outline </v-icon>
            </template>
          </v-datetime-picker>

          <v-text-field v-model="filters.companyName" label="Company Name"
            class="standart-input-filled standart-input-no-message" type="text" color="dark_grey" filled rounded dense
            clearable></v-text-field>
        </v-row>
        <v-row no-gutters class="justify-center align-center">
          <v-autocomplete v-model="filters.venueId" label="Venue"
            class="standart-input-filled standart-input-no-message" color="dark_grey" filled rounded dense
            :items="getIdNames" item-text="name" item-value="id" clearable>
          </v-autocomplete>
          <v-autocomplete v-model="filters.areaId" label="Group" class="standart-input-filled standart-input-no-message"
            color="dark_grey" filled rounded dense :items="getAreas" item-text="name" item-value="id" clearable>
          </v-autocomplete>
          <v-autocomplete v-model="filters.venueGroupId" label="Subgroup"
            class="standart-input-filled standart-input-no-message" color="dark_grey" filled rounded dense
            :items="getVenueGroups" item-text="name" item-value="id" clearable>
          </v-autocomplete>
        </v-row>
        <v-row no-gutters class="justify-center align-center">
          <v-autocomplete v-model="filters.woltLicenseStatus" label="Wolt License"
            class="standart-input-filled standart-input-no-message" color="dark_grey" filled rounded dense
            :items="licenseStatuses" item-text="label" item-value="value" clearable>
          </v-autocomplete>
          <v-autocomplete v-model="filters.boltLicenseStatus" label="bolt License"
            class="standart-input-filled standart-input-no-message" color="dark_grey" filled rounded dense
            :items="licenseStatuses" item-text="label" item-value="value" clearable>
          </v-autocomplete>
          <v-autocomplete v-model="filters.fudyLicenseStatus" label="Fudy License"
            class="standart-input-filled standart-input-no-message" color="dark_grey" filled rounded dense
            :items="licenseStatuses" item-text="label" item-value="value" clearable>
          </v-autocomplete>
        </v-row>
      </v-container>
    </div>
    <v-data-table :headers="headers" :items="getLicensesList" item-key="id" :items-per-page="10"
      class="table elevation-3" multi-sort no-data-text="No data" loading-text="Loading...">
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.companyName="{ item }">
        <span v-if='item.venueSelfSettings.companyName'>{{ item.venueSelfSettings.companyName }}</span>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.areaName="{ item }">
        <span v-if='!item.areaName'>---</span>
        <span v-else>{{ item.areaName }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.group.name="{ item }">
        <span v-if='!item.group.name'>---</span>
        <span v-else>{{ item.group.name }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.woltExpiredDateUtc="{ item }">
        <span v-if='item.venueSelfSettings.woltExpiredDateUtc'>
          <span v-if='item.venueSelfSettings.woltStartDateUtc'>{{
            `${convertDateToLocal(item.venueSelfSettings.woltStartDateUtc)}` }} - </span>
          {{ convertDateToLocal(item.venueSelfSettings.woltExpiredDateUtc) }}
        </span>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.boltExpiredDateUtc="{ item }">
        <span v-if='item.venueSelfSettings.boltExpiredDateUtc'>
          <span v-if='item.venueSelfSettings.boltStartDateUtc'>{{
            `${convertDateToLocal(item.venueSelfSettings.boltStartDateUtc)}` }} - </span>
          {{ convertDateToLocal(item.venueSelfSettings.boltExpiredDateUtc) }}
        </span>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.fudyExpiredDateUtc="{ item }">
        <span v-if='item.venueSelfSettings.fudyExpiredDateUtc'>
          <span v-if='item.venueSelfSettings.fudyStartDateUtc'>{{
            `${convertDateToLocal(item.venueSelfSettings.fudyStartDateUtc)}` }} - </span>
          {{ convertDateToLocal(item.venueSelfSettings.fudyExpiredDateUtc) }}
        </span>
        <span v-else>---</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.woltLicenseStatus="{ item }">
        <span>
          {{ item.venueSelfSettings.woltLicenseStatus }}
        </span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.boltLicenseStatus="{ item }">
        <span>
          {{ item.venueSelfSettings.boltLicenseStatus }}
        </span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.venueSelfSettings.fudyLicenseStatus="{ item }">
        <span>
          {{ item.venueSelfSettings.fudyLicenseStatus }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <v-btn color="red" :disabled="!item.hasActions" class="ma-1 white--text" small
          @click.stop="onRowClick(item.id)">
          View
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import dateFormat from '@/mixins/date-format';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LicenseTable',
  mixins: [dateFormat],
  data() {
    return {
      headers: [
        {
          text: 'Venue',
          align: 'start',
          sortable: true,
          value: 'name',
          width: '150px'
        },
        {
          text: 'Company', value: 'venueSelfSettings.companyName', sortable: true, width: '150px'
        },
        { text: 'Group', value: 'areaName', sortable: true, width: '150px' },
        { text: 'Subgroup', value: 'group.name', sortable: true, width: '150px' },
        { text: 'Wolt License Status', value: 'venueSelfSettings.woltLicenseStatus', sortable: true, width: '160px' },
        { text: 'License Date', value: 'venueSelfSettings.woltExpiredDateUtc', sortable: true, width: '160px' },
        { text: 'Bolt License Status', value: 'venueSelfSettings.boltLicenseStatus', sortable: true, width: '160px' },
        { text: 'License Date', value: 'venueSelfSettings.boltExpiredDateUtc', sortable: true, width: '160px' },
        { text: 'Fudy License Status', value: 'venueSelfSettings.fudyLicenseStatus', sortable: true, width: '160px' },
        { text: 'License Date', value: 'venueSelfSettings.fudyExpiredDateUtc', sortable: true, width: '160px' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        }
      ],
      filters: {
        venueId: null,
        areaId: null,
        venueGroupId: null,
        companyName: null,
        search: null,
        woltLicenseStatus: null,
        boltLicenseStatus: null,
        fudyLicenseStatus: null,
        actionPeriodStart: null,
        actionPeriodEnd: null
      },
      licenseStatuses: [
        { label: 'Active', value: 1 },
        { label: 'Expired', value: 2 },
        { label: 'Unlimited', value: 3 },
        { label: 'No-License', value: 4 }
      ],
      dateTimeFormats: {
        dateOrder: 0,
        dateSeparator: '.',
      },
      dateTimeTextFieldProps: {
        class: 'standart-input-filled standart-input-no-message',
        filled: true,
        rounded: true,
        dense: true,
      },
    }
  },
  async mounted() {
    await this.fetchIdNames()
    await this.fetchVenueGroups()
    await this.fetchAreas()
    await this.fetchLicensesList()
  },
  computed: {
    ...mapGetters({
      getGeneralSettings: 'app/getGeneralSettings',
      isLoading: 'venues/isLoading',
      getLicensesList: 'venues/getLicensesList',
      getIdNames: 'venues/getIdNames',
      getAreas: 'areas/getList',
      getVenueGroups: 'venueGroups/getData',
    }),
    timeFormat() {
      let timeFormat = this.dateTimeFormats.timeFormat === 1 ? 'hh:mm' : 'HH:mm';
      return timeFormat;
    },
    dateFormat() {
      let dateSeparator = this.dateTimeFormats.dateSeparator;
      let dateFormat =
        this.dateTimeFormats.dateOrder === 1
          ? `dd${dateSeparator}MM${dateSeparator}yyyy`
          : `MM${dateSeparator}dd${dateSeparator}yyyy`;
      return dateFormat;
    },
  },
  watch: {
    getGeneralSettings() {
      this.setDateTimeFormats();
    },
    filters: {
      async handler(newFilters) {
        await this.fetchLicensesList(
          {
            ...newFilters,
            actionPeriodStart: newFilters.actionPeriodStart ? new Date(newFilters.actionPeriodStart).toISOString() : null,
            actionPeriodEnd: newFilters.actionPeriodEnd ? new Date(newFilters.actionPeriodEnd).toISOString() : null
          })
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchLicensesList: 'venues/fetchLicensesList',
      fetchIdNames: 'venues/fetchIdNames',
      fetchAreas: 'areas/fetchData',
      fetchVenueGroups: 'venueGroups/fetchData'
    }),
    convertDateToLocal(date) {
      return this.formatLicenseDate(this.dateTimeFormats, date)
    },
    onRowClick(id) {
      this.$router.push(`lic-requests/events/${id}`);
    },
  }
}
</script>
<style lang="scss">
.table {
  tbody {
    tr {
      td {
        padding: 10px 16px !important;
      }
    }
  }
}
</style>