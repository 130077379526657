var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "900", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c("div", { staticClass: "standart-modal-card" }, [
        _c(
          "div",
          {
            staticClass:
              "standart-modal-card-top d-flex align-center justify-space-between pb-1 order-info-top",
          },
          [
            _c("div", [
              _c("h3", [_vm._v(_vm._s(_vm.consumerName))]),
              _vm.consumerPhoneNumber
                ? _c("h3", { staticClass: "order-phone" }, [
                    _vm._v(" " + _vm._s(_vm.consumerPhoneNumber) + " "),
                  ])
                : _vm._e(),
              _vm.tips?.value
                ? _c("div", { staticClass: "d-flex align-center" }, [
                    _c(
                      "strong",
                      {
                        staticClass: "d-flex align-center",
                        staticStyle: { margin: "5px 5px 5px 0" },
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-cash-plus")]),
                        _vm._v(" Tips:"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.tips.value) + " " + _vm._s(_vm.tips.currency)
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm.courierInfo !== null &&
            Object.prototype.hasOwnProperty.call(_vm.order, "boltOrder")
              ? _c("div", { staticClass: "d-flex flex-column flex-wrap" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", [_c("b", [_vm._v("Courier info:  ")])]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.courierInfo?.partial_name) + ","),
                    ]),
                  ]),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", [
                      _c("b", [_vm._v("Phone: ")]),
                      _vm._v(_vm._s(_vm.courierInfo?.phone) + ",  "),
                    ]),
                    _c("span", [
                      _c("b", [_vm._v("Arrival time: ")]),
                      _vm._v(_vm._s(_vm.courierInfo?.arrivalTime)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.isDealerOrAdmin && _vm.page == 1
              ? _c(
                  "div",
                  { staticClass: "ml-auto mr-2" },
                  [
                    _c("Button", {
                      staticClass: "px-2",
                      attrs: { text: "Force reject", styleType: "warning" },
                      on: {
                        handleClick: function ($event) {
                          return _vm.buttonClickHandler(
                            "forceRejectOrderHandler"
                          )
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _vm.isDealerOrAdmin
                  ? _c("span", { staticClass: "mr-1" }, [
                      _c("b", [_vm._v("Order ID:")]),
                      _vm._v(" " + _vm._s(_vm.order.id)),
                    ])
                  : _vm._e(),
                _c("h3", { staticClass: "mr-2" }, [
                  _vm._v("#" + _vm._s(_vm.orderNumber)),
                ]),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", color: "black" },
                    on: { click: _vm.closeModal },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "standart-modal-card-middle order-info-middle" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    ref: "modalCardWrapper",
                    staticClass: "modal-card-wrapper",
                    attrs: { cols: _vm.showScrollBtns ? "11" : "12" },
                  },
                  [
                    _c(
                      "div",
                      { ref: "modalCardContent" },
                      [
                        _vm.consumerComment
                          ? _c(
                              "v-row",
                              { staticClass: "d-flex align-center mb-1" },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mx-1",
                                    attrs: {
                                      color: "blue darken-2",
                                      "text-color": "white",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white darken-2" } },
                                      [_vm._v(" mdi-comment-processing")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-sheet",
                                  { staticClass: "order-comment" },
                                  [
                                    _c("p", { staticClass: "ma-0" }, [
                                      _vm._v(_vm._s(_vm.consumerComment)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.allergy_info
                          ? _c(
                              "v-row",
                              { staticClass: "d-flex align-center" },
                              [
                                _vm.allergy_info.length
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          color: "blue darken-2",
                                          "text-color": "white",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "darken-2" } },
                                          [_vm._v("mdi-medical-bag")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-sheet",
                                  { staticClass: "order-comment" },
                                  [
                                    _c("p", { staticClass: "ma-0" }, [
                                      _vm._v(_vm._s(_vm.allergy_info)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center mt-3",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "order-info-inner d-flex align-center",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require(`@/assets/images/${_vm.aggregator}.svg`),
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "h3",
                                  { staticClass: "text-capitalize ml-2" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.entityOrderFields.delivery.how
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm.order[`${_vm.aggregator}Order`]
                                  ?.payment_type === "cash" &&
                                _vm.order[`${_vm.aggregator}Order`]
                                  ?.dine_in_details &&
                                _vm.order[`${_vm.aggregator}Order`]
                                  ?.payment_method === "cash"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { large: "", color: "blue" } },
                                      [_vm._v("mdi-cash")]
                                    )
                                  : _vm._e(),
                                _vm.table
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-auto mr-4" },
                                      [
                                        _vm._v("Table: "),
                                        _c("b", [_vm._v(_vm._s(_vm.table))]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("Button", {
                              staticClass: "standart-button-content-width",
                              attrs: {
                                text: "Print",
                                "is-visible": _vm.isPrintBtnVisible,
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.buttonClickHandler("onPrint")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.order.venue.instanceDisplayName)),
                          ]),
                        ]),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c("OrderItems", {
                                  attrs: {
                                    orderItems: _vm.orderItems,
                                    price: _vm.entityOrderFields.price,
                                    originalPrice:
                                      _vm.entityOrderFields.originalPrice,
                                    priceCurrency:
                                      _vm.entityOrderFields.priceCurrency,
                                    aggregator: _vm.aggregator,
                                    glovoCurrency: _vm.glovoCurrency,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("v-col", {
                              staticClass:
                                "d-flex flex-column justify-space-between",
                              attrs: { cols: "2" },
                            }),
                          ],
                          1
                        ),
                        _vm.hasErrorMessage
                          ? _c(
                              "div",
                              { staticClass: "error-info-block mt-2" },
                              [_c("p", [_vm._v(_vm._s(_vm.orderErrorMsg))])]
                            )
                          : _vm._e(),
                        _vm.hasErrorMessage
                          ? _c(
                              "div",
                              { staticClass: "error-info-detail" },
                              _vm._l(
                                Object.entries(this.order.error),
                                function (error, idx) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: idx,
                                      staticClass: "error-info-detail-row",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "error-info-detail-col",
                                          attrs: { cols: "4" },
                                        },
                                        [_vm._v(_vm._s(error[0]))]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "error-info-detail-col",
                                          attrs: { cols: "8" },
                                        },
                                        [_vm._v(_vm._s(error[1]))]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.showScrollBtns
                  ? _c(
                      "v-col",
                      { staticClass: "side-scroll-btns", attrs: { cols: "1" } },
                      [
                        _c("Button", {
                          staticClass: "pa-0",
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: { icon: "mdi-arrow-up-thick" },
                          on: {
                            handleClick: function ($event) {
                              return _vm.scrollModal("up")
                            },
                          },
                        }),
                        _c("Button", {
                          staticClass: "pa-0 mt-1",
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: { icon: "mdi-arrow-down-thick" },
                          on: {
                            handleClick: function ($event) {
                              return _vm.scrollModal("down")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.order[`${_vm.aggregator}Order`]?.payment_type === "cash" &&
            _vm.order[`${_vm.aggregator}Order`]?.dine_in_details &&
            _vm.order[`${_vm.aggregator}Order`]?.payment_method === "cash"
              ? _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(" Payment for this order must be collected by staff!"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.showCardBottom
          ? _c(
              "div",
              {
                staticClass:
                  "standart-modal-card-bottom d-flex justify-space-between order-info-bottom",
              },
              [
                _vm.page === 1
                  ? [
                      _vm.orderState.BAD !== _vm.order.stateId
                        ? [
                            _vm.orderStatus.RECEIVED === _vm.order.statusId
                              ? _c("Button", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    text:
                                      _vm.entityOrderFields.delivery.when ===
                                      "preorder"
                                        ? "Confirm"
                                        : "Accept",
                                    isDisabled: _vm.isAcceptDisabled,
                                  },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.buttonClickHandler(
                                        "editOrderStatus"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.orderStatus.PRODUCTION !== _vm.order.statusId &&
                            _vm.orderStatus.READY !== _vm.order.statusId
                              ? _c("Button", {
                                  attrs: {
                                    text: "Reject",
                                    styleType: "warning",
                                  },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.buttonClickHandler(
                                        "rejectOrderHandler"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.orderStatus.READY === _vm.order.statusId
                              ? _c("Button", {
                                  attrs: { text: "Pick Up" },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.buttonClickHandler(
                                        "editOrderStatus"
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm.orderState.BAD === _vm.order.stateId &&
                          (_vm.orderStatus.RECEIVED === _vm.order.statusId ||
                            _vm.orderStatus.PREORDER === _vm.order.statusId)
                        ? [
                            _c("Button", {
                              staticClass: "mr-2",
                              attrs: { text: "Retry" },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.buttonClickHandler(
                                    "retryValidateOrder"
                                  )
                                },
                              },
                            }),
                            _c("Button", {
                              staticClass: "mr-2",
                              attrs: { text: "Force" },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.buttonClickHandler("forceOrder")
                                },
                              },
                            }),
                            _c("Button", {
                              staticClass: "mr-2",
                              attrs: { text: "Reject", styleType: "warning" },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.buttonClickHandler(
                                    "rejectOrderHandler"
                                  )
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.orderStatus.PRODUCTION === _vm.order.statusId
                        ? _c("Button", {
                            attrs: { text: "Ready" },
                            on: {
                              handleClick: function ($event) {
                                return _vm.buttonClickHandler("editOrderStatus")
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm.orderStatus.PREORDER === _vm.order.statusId &&
                    _vm.page === 2
                  ? [
                      _c("Button", {
                        staticClass: "mr-2",
                        attrs: { text: "Edit" },
                        on: {
                          handleClick: function ($event) {
                            return _vm.buttonClickHandler("editOrderStatus")
                          },
                        },
                      }),
                      _c("Button", {
                        attrs: { text: "Move to Production" },
                        on: { handleClick: _vm.preorderHandler },
                      }),
                    ]
                  : _vm.orderStatus.DELAYED === _vm.order.statusId &&
                    _vm.page === 2
                  ? [
                      _c("Button", {
                        staticClass: "mr-2",
                        attrs: { text: "Edit" },
                        on: {
                          handleClick: function ($event) {
                            return _vm.buttonClickHandler("editOrderStatus")
                          },
                        },
                      }),
                      _c("Button", {
                        attrs: { text: "Move To Production" },
                        on: {
                          handleClick: function ($event) {
                            return _vm.buttonClickHandler("forceToProd")
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("ConfirmationPreorderModal", {
        attrs: {
          time: _vm.order.orderExtraProperties?.Bolt_Final_Delivery_Time,
        },
        on: {
          confirmPreorder: function ($event) {
            return _vm.buttonClickHandler("confirmPreorder")
          },
        },
        model: {
          value: _vm.showPreorderModal,
          callback: function ($$v) {
            _vm.showPreorderModal = $$v
          },
          expression: "showPreorderModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }