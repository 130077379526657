var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "main-header",
      attrs: { color: "grey", fixed: "", elevation: "0" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center justify-space-between",
          staticStyle: { width: "100%" },
        },
        [
          _c("div", { staticClass: "menu-links" }, [
            _c(
              "div",
              { staticClass: "menu-links-desk" },
              _vm._l(_vm.getMenuLinks, function (link) {
                return _c(
                  "router-link",
                  {
                    key: link.value,
                    staticClass: "menu_item",
                    attrs: { to: link.value },
                  },
                  [_vm._v(_vm._s(link.title))]
                )
              }),
              1
            ),
            _vm.getMenuLinks.length > 0
              ? _c(
                  "div",
                  { staticClass: "menu-links-mob" },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            dark: "",
                                            disabled:
                                              _vm.getMenuLinks.length === 0,
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getRoles &&
                                              _vm.getRoles?.[0].toLowerCase() ===
                                                "admin"
                                              ? "Menu"
                                              : "Orders"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1024702781
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          _vm._l(_vm.getMenuLinks, function (link) {
                            return _c(
                              "v-list-item",
                              { key: link.value },
                              [
                                _c(
                                  "v-list-item-title",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "menu_item",
                                        attrs: { to: link.value },
                                      },
                                      [_vm._v(_vm._s(link.title))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center ml-auto" },
            _vm._l(_vm.tabItems, function (tab) {
              return _c(
                "div",
                { key: tab.id, staticClass: "tab_link" },
                [
                  _vm.isRouteShow(tab.urlName)
                    ? _c(
                        "router-link",
                        {
                          staticClass: "tab_link_item",
                          class:
                            _vm.pageUrl === tab.url
                              ? "tab_link_item-active"
                              : "",
                          attrs: { to: tab.url },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require(`@/assets/icons/${tab.icon}`),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(tab.name))]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "d-flex up-down" }, [
            _c(
              "button",
              {
                staticClass: "py-3 mr-2",
                attrs: { styleType: "secondary", type: "button" },
                on: { click: _vm.scrollByUp },
              },
              [
                _c("img", {
                  attrs: { src: require(`@/assets/icons/up.svg`), alt: "" },
                }),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "py-3",
                attrs: { styleType: "secondary", type: "button" },
                on: { click: _vm.scrollByDown },
              },
              [
                _c("img", {
                  attrs: { src: require(`@/assets/icons/down.svg`), alt: "" },
                }),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.toggleSettingsDrawer },
                },
                [_c("v-icon", [_vm._v("mdi-cog-outline")])],
                1
              ),
              _vm.isOperator
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex align-center",
                      on: { click: _vm.logout },
                    },
                    [
                      _c("div", { staticClass: "menu_item d-flex" }, [
                        _c("img", {
                          staticStyle: { "padding-right": "5px" },
                          attrs: {
                            src: require("@/assets/icons/logout.svg"),
                            alt: "",
                          },
                        }),
                        _vm._v("Sign out "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }