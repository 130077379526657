var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.syncedOpened
    ? _c("div", { attrs: { "overlay-opacity": "0.8" } }, [
        _c("div", { staticClass: "modal" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _c("div", { staticClass: "list" }, [
              _c(
                "ul",
                { staticClass: "modal-list" },
                _vm._l(_vm.data, function (item) {
                  return _c(
                    "li",
                    { key: item.displayName, staticClass: "modal-item" },
                    [
                      _c("strong", [_vm._v(_vm._s(item.displayName) + ":")]),
                      _c(
                        "ul",
                        { staticClass: "modal-item-list" },
                        _vm._l(item.inCorrectDiscounts, function (discount) {
                          return _c("li", { key: discount.id }, [
                            _c("span", { staticClass: "modal-item-id" }, [
                              _vm._v(_vm._s(discount.id) + ":"),
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(discount.itemName))]),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-bottom" },
              [
                _c("Button", {
                  staticClass: "standart-button-content-width mr-2",
                  attrs: { text: "Close", type: "button" },
                  on: { handleClick: _vm.closeModal },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-top" }, [
      _c("div", { staticClass: "modal-warning" }, [
        _c("p", [
          _c("span", [_vm._v("Warning:")]),
          _vm._v(
            " Discount for following Wolt items will not be applied! Please check item configuration!"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }