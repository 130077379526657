export const rKeeperSettingsDesctiptions = {};
export const venueSelfSettingsDesctiptions = {
  companyName: 'Name of the company to which the restaurant belongs.',
  defaultCookingTimeInMinutes:
    'Default cooking time (in minutes), used as default cooking time for unattended auto acceptance of orders.',
  maxCookingTimeInMinutes: 'Maximum cooking time that will be offered for operator when accepting order.',
  defaultDelayTimeInMinutes: 'Default delay time (in minutes), used if delayOrderSaving + alwaysDefaultDelayTime.',
  autoAcceptTimeInSeconds:
    'Time between the arrival of new order, when operator may make manual choice of action before order is automatically accepted by isAutoAcceptEnabled (if used).',
  alwaysDefaultDelayTime: '',
  imageSourceId: 'Other venue on server which image file path to use for image urls (if not using its own).',
  isPrintOrderEnabled: 'Enables “Print” button on order cards.',
  isAutoAcceptEnabled:
    'Enables automatic acceptance of incoming orders – Will only work for valid rkeeper orders with no errors.',
  isAutoPickedUpEnabled:
    'If enabled orders will receive status picked up immediately after they are marked as ready and will not be visible in main screen.',
  isAutoSyncMenuEnabled:
    'Enables automatic and manual menu sync between rkeeper and delivery platforms. If disabled neither manual nor automatic menu update can be done.',
  isSingleModifierModeEnabled:
    'Enables sending item options (combo components) to delivery menu by combining them as single choice when applicable. Improves end-customer experience when they select item options.',
  isPayOrderAfterAcceptEnabled:
    'If enabled will immediately process order as paid in rkeeper after order reaches state production.',
  delayOrderSaving:
    'Enables delayed order saving in rkeeper - after accepting order, it will go to column “Delayed” and will be only moved.',
  useCustomTimeZone: 'Enables selection and usage of different time zone, rather than default one.',
  timeZone: '',
  useDayLigthSavingTime: 'If enabled system will adjust its timekeeping to accommodate daylight saving time changes',
};
