var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "order-card mb-4",
          class: _vm.isStatusChanging ? "overlay" : "",
          style: _vm.orderBackColor,
          attrs: { id: _vm.id },
          on: { click: _vm.onCardClickHandler },
        },
        [
          _vm.hasErrorMessage
            ? _c("div", { staticClass: "error-info-block" }, [
                _c("p", [_vm._v(_vm._s(_vm.orderErrorMsg))]),
              ])
            : _vm._e(),
          _vm.hasErrorMessage
            ? _c(
                "div",
                { staticClass: "error-info-detail" },
                _vm._l(Object.entries(this.order.error), function (error, idx) {
                  return _c(
                    "v-row",
                    {
                      key: idx,
                      staticClass: "error-info-detail-row",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "error-info-detail-col",
                          attrs: { cols: "4" },
                        },
                        [_vm._v(_vm._s(error[0]))]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "error-info-detail-col",
                          attrs: { cols: "8" },
                        },
                        [_vm._v(_vm._s(error[1]))]
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "align-center" },
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _c("h3", { staticClass: "order-title mb-1" }, [
                  _vm._v(_vm._s(_vm.consumerName)),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "3" } }, [
                _c("div", { staticClass: "order-type d-flex align-center" }, [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/images/${_vm.logo}.svg`),
                      alt: "",
                    },
                  }),
                  _c("h4", { staticClass: "text-capitalize" }, [
                    _vm._v(_vm._s(_vm.deliveryHow)),
                  ]),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "2" } }, [
                _c("h4", [_vm._v(_vm._s(_vm.order.status.name))]),
              ]),
              _c("v-col", { attrs: { cols: "1" } }, [
                _c("span", { staticClass: "order-title d-flex justify-end" }, [
                  _vm._v("#" + _vm._s(_vm.orderNumber)),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "2" } }, [
                _vm.aggregator === "boltOrder"
                  ? _c("span", { staticClass: "price" }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.formatPrice(_vm.price)) + " "),
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(_vm._s(_vm.priceCurrency)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.aggregator === "woltOrder" || _vm.aggregator === "fudyOrder"
                  ? _c("span", [
                      _vm.price
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.formatPrice(_vm.price)) + " "
                            ),
                            _c("span", { staticClass: "text-uppercase" }, [
                              _vm._v(_vm._s(_vm.priceCurrency)),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "2" } },
                [_vm._v(_vm._s(_vm.orderCreatedTime))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrderInfoModal", {
        attrs: {
          order: _vm.order,
          entityOrder: _vm.entityOrder,
          tips: _vm.tips,
          entityOrderFields: _vm.entityOrderFields,
          orderErrorMsg: _vm.orderErrorMsg,
          isPrintBtnVisible: _vm.isPrintBtnVisible,
          courier: _vm.courier,
        },
        on: { infoModalEvent: _vm.infoModalHandler },
        model: {
          value: _vm.isOrderInfoModalOpen,
          callback: function ($$v) {
            _vm.isOrderInfoModalOpen = $$v
          },
          expression: "isOrderInfoModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }