var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "480", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c("div", { staticClass: "standart-modal-card" }, [
        _c(
          "div",
          {
            staticClass:
              "standart-modal-card-top d-flex align-center justify-space-between",
          },
          [
            _c("h3", [_vm._v("Venue group")]),
            _c(
              "v-btn",
              {
                attrs: { icon: "", color: "black" },
                on: { click: _vm.closeModal },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "standart-modal-card-middle" },
          [
            _c("v-autocomplete", {
              staticClass:
                "standart-input-filled standart-input-no-message mb-6",
              attrs: {
                label: "Select group ID for new venue",
                rules: [_vm.rules.required],
                color: "dark_grey",
                items: _vm.getVenueGroups,
                "item-text": "name",
                "item-value": "id",
                loading: _vm.venueGroupsLoading,
                "loader-height": "10",
                filled: "",
                rounded: "",
                dense: "",
              },
              model: {
                value: _vm.groupId,
                callback: function ($$v) {
                  _vm.groupId = $$v
                },
                expression: "groupId",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "standart-modal-card-bottom" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-end" },
            [
              _c("Button", {
                staticClass: "standart-button-content-width mr-2",
                attrs: {
                  styleType: "secondary",
                  text: "Cancel",
                  type: "button",
                },
                on: { handleClick: _vm.closeModal },
              }),
              _c("Button", {
                staticClass: "standart-button-content-width",
                attrs: {
                  text: "Save",
                  type: "button",
                  "is-loading": _vm.venueGroupsLoading,
                },
                on: { handleClick: _vm.saveGroup },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }