<template>
  <v-container class="pa-6" fluid>
    <StandartLoader v-if="isLoading" />
    <v-row v-else class="justify-center align-center">
      <v-col lg="8" md="10" sm="12">
        <router-link to="/lic-requests" class="text-decoration-none text-h6 font-weight-medium">
          <v-icon color="#5b7ffe">mdi-arrow-left</v-icon>
          Back</router-link>
        <h3 v-if="getLicenseActions.length" class="sidebar-title my-5">License actions for: {{ venueName }}</h3>
        <p v-if="!getLicenseActions.length" class="text-h4 text-center mt-8">No License Actions found</p>
        <v-card v-else v-for="action in getLicenseActions" :key="action.id" class="mx-auto my-5 p-5" outlined
          elevation="2" rounded="xl">
          <v-card-title class="d-flex flex-row justify-space-between">
            <p class="text-h5">Modified by: {{ action.userName }}({{ action.roleNames[0] }})</p>
            <p class="text-h5">Modified at: {{ convertDateToLocal(action.modifiedDate) }}</p>
          </v-card-title>
          <v-card-text>
            <div v-for="field in action.modifiedData" :key="field.fieldName" class="">
              <div class="d-flex flex-row">
                <p class="text-h6 pr-8">License: <span>{{ field.fieldName.slice(0, 4) }}</span></p>
                <p class="text-h6 pr-8">From: <span>{{ field.originData || 'No data' }}</span></p>
                <p class="text-h6">To: <span>{{ field.modifiedData || 'No data' }}</span></p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>

</template>
<script>
import dateFormat from '@/mixins/date-format';
import { mapActions, mapGetters } from 'vuex';
import StandartLoader from '../components/StandartLoader.vue';

export default {
  name: 'LicenseEvents',
  mixins: [dateFormat],
  components: {
    StandartLoader
  },
  data() {
    return {
      dateTimeFormats: {
        dateOrder: 0,
        dateSeparator: '.',
      },
    }
  },
  async mounted() {
    await this.fetchLicenseActions(this.$route.params.venueId)
  },
  computed: {
    ...mapGetters({
      getGeneralSettings: 'app/getGeneralSettings',
      isLoading: 'venues/isLoading',
      getLicenseActions: 'venues/getLicenseActions',
    }),
    venueName() {
      return this.getLicenseActions[0]?.venueName
    }
  },
  watch: {
    getGeneralSettings() {
      this.setDateTimeFormats();
    },
    filters: {
      async handler(newFilters) {
        await this.fetchLicensesList(newFilters)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchLicenseActions: 'venues/fetchLicenseActions',
    }),
    convertDateToLocal(date) {
      return this.formatLicenseDate(this.dateTimeFormats, date)
    },
  }
}
</script>
<style lang="scss">
.table {
  tbody {
    tr {
      td {
        padding: 10px 16px !important;
      }
    }
  }
}
</style>