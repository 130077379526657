<template>
  <v-dialog v-model="syncedOpened" width="480" overlay-opacity="0.8">
    <div class="standart-modal-card">
      <div class="standart-modal-card-top d-flex align-center justify-space-between">
        <h3>Add instance</h3>
        <v-btn @click="closeModal" icon color="black"><v-icon>mdi-close</v-icon> </v-btn>
      </div>
      <div class="standart-modal-card-middle">
        <p>
          Are you sure you want to add new {{ instanceToAdd.slice(0, 4) }} instance?
        </p>
        <p>
          To apply changes, click 'Save' button.
        </p>
      </div>
      <div class="standart-modal-card-bottom">
        <div class="d-flex align-center justify-end">
          <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
            @handleClick="closeModal" />
          <Button styleType="primary" text="Add" class="standart-button-content-width mr-2" type="button"
            @handleClick="addHandler" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';

export default {
  name: 'AddInstanceModal',
  components: {
    Button,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: Boolean,
    instanceToAdd: [String, null],
  },
  computed: {
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    closeModal() {
      this.syncedOpened = false;
    },
    addHandler() {
      this.$emit('addInstance', this.instanceToAdd);
      this.closeModal();
    }
  },
};
</script>

<style lang="scss" scoped></style>
