var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "480", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c(
        "div",
        { staticClass: "standart-modal-card" },
        [
          _c(
            "v-form",
            {
              ref: "venueGroupForm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validateForm("venueGroupForm")
                },
              },
              model: {
                value: _vm.isFormValid,
                callback: function ($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "standart-modal-card-top d-flex align-center justify-space-between",
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.title))]),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "black" },
                      on: { click: _vm.closeModal },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "standart-modal-card-middle" },
                [
                  _c("v-textarea", {
                    key: _vm.textFieldKey,
                    staticClass:
                      "standart-input-filled standart-input-no-message",
                    attrs: {
                      label: "Comment",
                      rules: [_vm.rules.required],
                      color: "dark_grey",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "standart-modal-card-bottom" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-end" },
                  [
                    _c("Button", {
                      staticClass: "standart-button-content-width mr-2",
                      attrs: {
                        styleType: "secondary",
                        text: "Cancel",
                        type: "button",
                      },
                      on: { handleClick: _vm.closeModal },
                    }),
                    _c("Button", {
                      staticClass: "standart-button-content-width",
                      attrs: { text: "Send", type: "submit" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }