var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standart-card" },
    [
      _c("v-data-table", {
        staticClass: "elevation-3",
        attrs: {
          headers: _vm.headers,
          items: _vm.requestList,
          "items-per-page": 10,
        },
        on: { "click:row": _vm.rowClickHandle },
        scopedSlots: _vm._u([
          {
            key: "item.createUtc",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.convertDateToLocal(item.createUtc))),
                ]),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { color: "#000", bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              item.statusId === 1
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ma-1 white--text",
                                          attrs: {
                                            color: "green darken-2",
                                            "x-small": "",
                                            fab: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editItem(
                                                item,
                                                "accept"
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v(" mdi-check "),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Accept")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { color: "#000", bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              item.statusId === 1
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ma-1 white--text",
                                          attrs: {
                                            color: "red darken-2",
                                            "x-small": "",
                                            fab: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editItem(
                                                item,
                                                "reject"
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v(" mdi-window-close "),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Reject")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { color: "#000", bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ma-1 white--text",
                                      attrs: {
                                        color: "red darken-4",
                                        "x-small": "",
                                        fab: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v(" mdi-delete "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Delete")])]
                ),
              ]
            },
          },
        ]),
      }),
      _c("RequestReasonModal", {
        on: { sendReason: _vm.sendReasonHandler },
        model: {
          value: _vm.reasonModalShown,
          callback: function ($$v) {
            _vm.reasonModalShown = $$v
          },
          expression: "reasonModalShown",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }