var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "time_picker__container" },
    [
      _c("label", { staticClass: "mt-1" }, [_vm._v(_vm._s(_vm.title))]),
      _c("TimePicker", {
        style: _vm.required ? "border: 1px solid red" : "",
        attrs: {
          format: _vm.timeFormat,
          "disabled-dates": _vm.disabledDates,
          "input-width": "130px",
        },
        on: { close: _vm.handleClickSave, clear: _vm.clearTime },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function () {
              return [_c("v-icon", {}, [_vm._v("mdi-clock-time-four-outline")])]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.currentTime,
          callback: function ($$v) {
            _vm.currentTime = $$v
          },
          expression: "currentTime",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }