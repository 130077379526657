var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            { attrs: { lg: "8", md: "10", sm: "12" } },
            [
              _c("h2", { staticClass: "sidebar-title mt-2 mb-6" }, [
                _vm._v("Reports"),
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { col: "4" } },
                    [
                      _c("v-autocomplete", {
                        staticClass:
                          "standart-input-filled standart-input-no-message mb-6",
                        attrs: {
                          label: "Venue",
                          disabled: _vm.isOperatorOrManager,
                          color: "dark_grey",
                          items: _vm.getVenues,
                          "item-text": "name",
                          "item-value": "id",
                          loading: _vm.isVenueLoading,
                          "loader-height": "5",
                          filled: "",
                          rounded: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.venueId,
                          callback: function ($$v) {
                            _vm.venueId = $$v
                          },
                          expression: "venueId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { col: "4" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 30,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "Choose a date",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.date,
                                            callback: function ($$v) {
                                              _vm.date = $$v
                                            },
                                            expression: "date",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.datePickerMenu,
                            callback: function ($$v) {
                              _vm.datePickerMenu = $$v
                            },
                            expression: "datePickerMenu",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "show-adjacent-months": "" },
                            on: { input: _vm.onDatePickerClick },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("Button", {
                        attrs: { text: "Previous day", type: "button" },
                        on: { handleClick: _vm.prevDayOrders },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("Button", {
                        attrs: {
                          text: "Next day",
                          type: "button",
                          isDisabled: _vm.isToday,
                        },
                        on: { handleClick: _vm.nextDayOrders },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "standart-card" },
                [
                  _vm.isLoading
                    ? _c("StandartLoader")
                    : [
                        _vm.venueId
                          ? _c(
                              "v-row",
                              {
                                staticClass:
                                  "action-buttons d-flex justify-space-around align-start my-2 px-3",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-column",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("b", [_vm._v("Wolt: ")]),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.getWoltSale
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Rejected:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.getWoltRejected
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.woltSalesOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [
                                            _vm._v("Rejected Orders:"),
                                          ]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.woltRejectedOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Total Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.woltTotalOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-column",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("b", [_vm._v("Bolt: ")]),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.getBoltSale
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Rejected:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.getBoltRejected
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.boltSalesOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [
                                            _vm._v("Rejected Orders:"),
                                          ]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.boltRejectedOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Total Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.boltTotalOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-column",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("b", [_vm._v("Fudy: ")]),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.getFudySale
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Rejected:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.getFudyRejected
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.fudySalesOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [
                                            _vm._v("Rejected Orders:"),
                                          ]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.fudyRejectedOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Total Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.fudyTotalOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-column",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c("b", [_vm._v("Total: ")]),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.totalSales
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Rejected:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    _vm.totalRejected
                                                  )
                                                ) + " EUR"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Sales Orders:")]),
                                        ]),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "5",
                                              "align-self": "center",
                                            },
                                          },
                                          [
                                            _c("span", {}, [
                                              _vm._v(
                                                _vm._s(_vm.totalSalesOrders)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [
                                            _vm._v("Rejected Orders:"),
                                          ]),
                                        ]),
                                        _c("v-col", { attrs: { cols: "5" } }, [
                                          _c("span", {}, [
                                            _vm._v(
                                              _vm._s(_vm.totalRejectedOrders)
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c("v-col", { attrs: { cols: "7" } }, [
                                          _c("span", [_vm._v("Total Orders:")]),
                                        ]),
                                        _c("v-col", { attrs: { cols: "5" } }, [
                                          _c("span", {}, [
                                            _vm._v(_vm._s(_vm.totalOrders)),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.getOrders.length
                          ? _c("div", { staticClass: "text-center" }, [
                              _c("h2", [_vm._v(_vm._s(_vm.infoMsg))]),
                            ])
                          : _vm._l(_vm.getOrders, function (order) {
                              return _c(
                                "div",
                                { key: order.id },
                                [
                                  _c("ReportOrderCard", {
                                    attrs: {
                                      id: order.id,
                                      status: order.statusId
                                        ? order.statusId
                                        : order.status && order.status.id
                                        ? order.status.id
                                        : null,
                                      order: order,
                                      state: order.stateId,
                                      isLoading: order.isLoading,
                                      orderErrorMsg: order.error
                                        ? order.error.message
                                          ? order.error.message
                                          : ""
                                        : "",
                                      isCreatedInRKeeper:
                                        order.isCreatedInRKeeper,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }