var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { "drawer-show": _vm.setInputToggleDrawer } }),
      _c("router-view", { staticClass: "content" }),
      _c(
        "v-navigation-drawer",
        {
          attrs: { temporary: "", right: "", fixed: "", width: "340" },
          model: {
            value: _vm.isDrawerShow,
            callback: function ($$v) {
              _vm.isDrawerShow = $$v
            },
            expression: "isDrawerShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-block" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "drawer-block-top pa-5 d-flex align-center justify-space-between",
                },
                [
                  _c("h2", [_vm._v("Settings")]),
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", icon: "", color: "black" },
                      on: {
                        click: function ($event) {
                          return _vm.setInputToggleDrawer(false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("v-switch", {
                staticClass: "standart-switch px-4",
                attrs: { inset: "", label: "Sound notifications" },
                on: { change: _vm.switchSoundNotifSet },
                model: {
                  value: _vm.generalSettings.soundNotif,
                  callback: function ($$v) {
                    _vm.$set(_vm.generalSettings, "soundNotif", $$v)
                  },
                  expression: "generalSettings.soundNotif",
                },
              }),
              _c(
                "div",
                { staticClass: "pa-2" },
                [
                  _c("p", [_vm._v("Change Font-Size of Application")]),
                  _c(
                    "v-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("Button", {
                            attrs: {
                              text: "-",
                              type: "button",
                              styleType: "secondary",
                            },
                            on: { handleClick: _vm.decreaseFontSize },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { staticClass: "my-auto text-center" }, [
                        _vm._v(_vm._s(_vm.getFontSize)),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("Button", {
                            attrs: {
                              text: "+",
                              type: "button",
                              styleType: "secondary",
                            },
                            on: { handleClick: _vm.increaseFontSize },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("Button", {
                    attrs: {
                      text: "Apply",
                      type: "button",
                      styleType: "primary",
                    },
                    on: { handleClick: _vm.applyFontSize },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "drawer-block-middle pa-2" },
                [
                  _c("Button", {
                    attrs: {
                      text: "Reload Page",
                      type: "button",
                      styleType: "secondary",
                    },
                    on: { handleClick: _vm.reloadPage },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _vm.isDrawerFieldShow("status")
                ? _c(
                    "div",
                    { staticClass: "drawer-block-middle pa-2" },
                    [
                      _vm.hasAvailability
                        ? _c(
                            "div",
                            [
                              _vm._l(
                                _vm.currentVenue.availablities.woltInstances,
                                function (wolt, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: wolt.id + wolt.providerName,
                                      staticClass: "d-flex align-center mb-2",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "status-circle",
                                        style: `background-color: ${_vm.statusColor(
                                          "wolt",
                                          index
                                        )}`,
                                      }),
                                      _c("h3", [
                                        _vm._v(_vm._s(wolt.providerName)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.currentVenue.availablities.boltInstances,
                                function (bolt, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: bolt.id + bolt.providerName,
                                      staticClass: "d-flex align-center mb-2",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "status-circle",
                                        style: `background-color: ${_vm.statusColor(
                                          "bolt",
                                          index
                                        )}`,
                                      }),
                                      _c("h3", [
                                        _vm._v(_vm._s(bolt.providerName)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.currentVenue.availablities.fudyInstances,
                                function (fudy, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: fudy.id + fudy.providerName,
                                      staticClass: "d-flex align-center mb-2",
                                    },
                                    [
                                      _c("div", {
                                        style: `background-color: ${_vm.statusColor(
                                          "fudy",
                                          index
                                        )}`,
                                        attrs: { clgass: "status-circle" },
                                      }),
                                      _c("h3", [
                                        _vm._v(_vm._s(fudy.providerName)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.currentVenue.availablities.glovoInstances,
                                function (fudy, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: fudy.id + fudy.providerName,
                                      staticClass: "d-flex align-center mb-2",
                                    },
                                    [
                                      _c("div", {
                                        style: `background-color: ${_vm.statusColor(
                                          "glovo",
                                          index
                                        )}`,
                                        attrs: { clgass: "status-circle" },
                                      }),
                                      _c("h3", [
                                        _vm._v(_vm._s(fudy.providerName)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      (_vm.isWoltSettingsPresent ||
                        _vm.isBoltSettingsPresent ||
                        _vm.isFudySettingsPresent ||
                        _vm.isGlovoSettingsPresent) &&
                      _vm.hasAvailability
                        ? _c("Button", {
                            attrs: {
                              text: "Change status",
                              type: "button",
                              styleType: "secondary",
                            },
                            on: { handleClick: _vm.openVenueModal },
                          })
                        : _vm._e(),
                      _vm.isDrawerFieldShow("checkRk")
                        ? _c("Button", {
                            staticClass: "mt-3",
                            attrs: {
                              text: "Check Rkeeper",
                              type: "button",
                              isLoading: _vm.isCheckingRk,
                            },
                            on: {
                              handleClick: function ($event) {
                                return _vm.checkEntityConnection("rk")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.isDrawerFieldShow("checkProvider")
                        ? _c("Button", {
                            staticClass: "mt-3",
                            attrs: { text: "Check provider", type: "button" },
                            on: { handleClick: _vm.openInstanceCheckModal },
                          })
                        : _vm._e(),
                      _vm.isDrawerFieldShow("updateMenu")
                        ? _c("Button", {
                            staticClass: "mt-3",
                            attrs: {
                              text: "Update menu now",
                              type: "button",
                              styleType: "secondary",
                              isLoading: _vm.isLoading,
                            },
                            on: { handleClick: _vm.openMenuModal },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "drawer-block-middle pa-2" },
                [
                  _c("Button", {
                    attrs: {
                      text: _vm.showDateTimeSettings
                        ? "Hide date/time settings"
                        : "Show date/time settings",
                      type: "button",
                      styleType: "secondary",
                    },
                    on: {
                      handleClick: function ($event) {
                        _vm.showDateTimeSettings = !_vm.showDateTimeSettings
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.showDateTimeSettings
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px #a2a2a2 solid",
                        "border-radius": "15px",
                        margin: "0 5px 10px 5px",
                        padding: "5px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-5 d-flex align-center justify-space-between",
                        },
                        [
                          _c("p", [_vm._v("Time format")]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ma-0",
                                  model: {
                                    value: _vm.generalSettings.timeFormat,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.generalSettings,
                                        "timeFormat",
                                        $$v
                                      )
                                    },
                                    expression: "generalSettings.timeFormat",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "12 hours (AM/PM)",
                                      value: 1,
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "24 hours", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-5 d-flex align-center justify-space-between",
                        },
                        [
                          _c("p", [_vm._v("Order in date")]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ma-0",
                                  model: {
                                    value: _vm.generalSettings.dateOrder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.generalSettings,
                                        "dateOrder",
                                        $$v
                                      )
                                    },
                                    expression: "generalSettings.dateOrder",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "DD MM YYYY", value: 1 },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "MM DD YYYY", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-5 d-flex align-center justify-space-between",
                        },
                        [
                          _c("p", [_vm._v("Separator in date")]),
                          _c(
                            "div",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ma-0",
                                  model: {
                                    value: _vm.generalSettings.dateSeparator,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.generalSettings,
                                        "dateSeparator",
                                        $$v
                                      )
                                    },
                                    expression: "generalSettings.dateSeparator",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Point '.'", value: "." },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "Slash '/'", value: "/" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "px-2 d-flex align-center justify-space-between",
                        },
                        [
                          _c("Button", {
                            attrs: { text: "Save", type: "button" },
                            on: { handleClick: _vm.saveGeneralSettings },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.getUserData.roleNames?.[0].toLowerCase() === "operator"
            ? _c("div", { staticClass: "d-flex justify-center align-end" }, [
                _c(
                  "button",
                  {
                    staticClass: "standart-button-secondary logout-btn mb-3",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.logout.apply(null, arguments)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-logout")]), _vm._v("Sign out ")],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("VenueModal", {
        attrs: {
          "change-status": _vm.changeVenueStatus,
          availablities: _vm.getCurrentVenue.availablities,
          isLoading: _vm.getLoading,
        },
        model: {
          value: _vm.isVenueModalShow,
          callback: function ($$v) {
            _vm.isVenueModalShow = $$v
          },
          expression: "isVenueModalShow",
        },
      }),
      _c("MenuModal", {
        attrs: { venueId: _vm.getUserData.venueId },
        model: {
          value: _vm.isMenuModalShow,
          callback: function ($$v) {
            _vm.isMenuModalShow = $$v
          },
          expression: "isMenuModalShow",
        },
      }),
      _c("CheckInstancesModal", {
        attrs: {
          availablities: _vm.getCurrentVenue.availablities,
          isCheckingFudy: _vm.isCheckingFudy,
          isCheckingBolt: _vm.isCheckingBolt,
          isCheckingWolt: _vm.isCheckingWolt,
        },
        on: {
          checkWolt: (instanceId) =>
            _vm.checkEntityConnection("wolt", { instanceId }),
          checkBolt: (instanceId) =>
            _vm.checkEntityConnection("bolt", { instanceId }),
          checkFudy: (instanceId) =>
            _vm.checkEntityConnection("fudy", { instanceId }),
        },
        model: {
          value: _vm.isCheckInstancesModalShow,
          callback: function ($$v) {
            _vm.isCheckInstancesModalShow = $$v
          },
          expression: "isCheckInstancesModalShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }