var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "justify-center align-center" },
        [
          _c(
            "v-col",
            { attrs: { lg: "8", md: "10", sm: "12" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "tabs-bar",
                  attrs: { "align-with-title": "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [
                    _c("h2", { staticClass: "sidebar-title my-6" }, [
                      _vm._v("Requests"),
                    ]),
                  ]),
                  _vm.isAdmin
                    ? _c("v-tab", [
                        _c("h2", { staticClass: "sidebar-title my-6" }, [
                          _vm._v("Licences"),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "8", md: "10", sm: "12" } },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab-item", [_c("LicenseRequestsTable")], 1),
                  _c("v-tab-item", [_c("LicenseTable")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }