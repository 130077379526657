var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "1200", "overlay-opacity": "0.8", hidden: "" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c("div", { staticClass: "v-card" }, [
        _c("div", { ref: "menu", staticClass: "menues" }, [
          _c("h2", [
            _vm._v(
              _vm._s(
                _vm.filteredProviders[_vm.activeProvider][_vm.activeMenu]?.name
              )
            ),
          ]),
          _c("div", { staticClass: "menu" }, [
            _c(
              "div",
              { staticClass: "categories", attrs: { id: "categories-scroll" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-1",
                    attrs: { isLoading: _vm.isLoading, icon: "" },
                    on: { click: _vm.download },
                  },
                  [_c("v-icon", [_vm._v("mdi-file-arrow-up-down")])],
                  1
                ),
                _c("h3", [_vm._v("Provider")]),
                _c(
                  "ul",
                  { staticClass: "categories__list" },
                  _vm._l(_vm.filteredProviders, function (provider, menukey) {
                    return _c(
                      "li",
                      {
                        key: menukey,
                        class:
                          _vm.activeProvider === menukey
                            ? "categories__item categories__item_active"
                            : "categories__item",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveProvider(menukey)
                          },
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm._f("capitalize")(menukey.slice(0, 4)))
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("h3", [_vm._v("Page")]),
                _c(
                  "ul",
                  { staticClass: "categories__list" },
                  _vm._l(
                    _vm.filteredProviders[_vm.activeProvider],
                    function (menuItem, menuItemIndex) {
                      return _c(
                        "li",
                        {
                          key: menuItem.id,
                          class:
                            _vm.activeMenu === menuItemIndex
                              ? "categories__item categories__item_active"
                              : "categories__item",
                          on: {
                            click: function ($event) {
                              return _vm.setActiveMenu(menuItemIndex)
                            },
                          },
                        },
                        [_c("p", [_vm._v(_vm._s(menuItem?.name))])]
                      )
                    }
                  ),
                  0
                ),
                _c("h3", [_vm._v("Categories")]),
                _c(
                  "ul",
                  { staticClass: "categories__list" },
                  [
                    _c(
                      "li",
                      {
                        class:
                          _vm.activeCategory === "All disabled"
                            ? "categories__item categories__item_active"
                            : "categories__item",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveCategory("All disabled")
                          },
                        },
                      },
                      [
                        _c("p", [_vm._v("All disabled")]),
                        _c("span", [_vm._v(_vm._s(_vm.allDisabled?.length))]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class:
                          _vm.activeCategory === "All disabled from POS"
                            ? "categories__item categories__item_active"
                            : "categories__item",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveCategory(
                              "All disabled from POS"
                            )
                          },
                        },
                      },
                      [
                        _c("p", [_vm._v("All disabled from POS")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.allDisabledFromPOS.length)),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class:
                          _vm.activeCategory === "Broken Combos"
                            ? "categories__item categories__item_active"
                            : "categories__item",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveCategory("Broken Combos")
                          },
                        },
                      },
                      [
                        _c("p", [_vm._v("Broken Combos")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.allBrokenCombos.length)),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class:
                          _vm.activeCategory === "Options/Modifiers"
                            ? "categories__item categories__item_active"
                            : "categories__item",
                        on: {
                          click: function ($event) {
                            return _vm.setActiveCategory("Options/Modifiers")
                          },
                        },
                      },
                      [
                        _c("p", [_vm._v("Options/Modifiers")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.allOptionsModifiers.length)),
                        ]),
                      ]
                    ),
                    _vm._l(
                      _vm.filteredProviders[_vm.activeProvider][_vm.activeMenu]
                        .children?.[0].children,
                      function (category, catIndex) {
                        return _c(
                          "li",
                          {
                            key: category.id,
                            class:
                              _vm.activeCategory === catIndex
                                ? "categories__item categories__item_active"
                                : "categories__item",
                            on: {
                              click: function ($event) {
                                return _vm.setActiveCategory(catIndex)
                              },
                            },
                          },
                          [
                            _c("p", [_vm._v(_vm._s(category.name))]),
                            _c("span", [
                              _vm._v(_vm._s(category.children.length)),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dishes", attrs: { id: "dishes-scroll" } },
              [
                !_vm.activeDish[0] &&
                ![
                  "All disabled from POS",
                  "All disabled",
                  "Broken Combos",
                  "Options/Modifiers",
                  "Options/ComboComponents",
                ].includes(_vm.activeCategory)
                  ? _c("Button", {
                      staticClass:
                        "standart-button-content-width float-right mb-4 mx-3",
                      attrs: {
                        text: "Disable/Enable All dishes in Category",
                        type: "button",
                      },
                      on: { handleClick: _vm.disableAllDishesInCategory },
                    })
                  : _vm._e(),
                _vm.activeDish[0] &&
                _vm.activeDish[0]?.children &&
                _vm.activeDish[0].id[0] !== "i"
                  ? _c("Button", {
                      staticClass:
                        "standart-button-content-width float-right mb-4 mx-3",
                      attrs: {
                        text: "Disable/Enable All Items",
                        type: "button",
                      },
                      on: { handleClick: _vm.disableAllDishesInCombo },
                    })
                  : _vm._e(),
                _vm.activeDish &&
                !_vm.activeDish[0] &&
                ![
                  "All disabled",
                  "All disabled from POS",
                  "Broken Combos",
                  "Options/Modifiers",
                  "Options/ComboComponents",
                ].includes(_vm.activeCategory)
                  ? _c("div", { staticClass: "dish" }, [
                      _c(
                        "ul",
                        { staticClass: "dish__list" },
                        _vm._l(
                          _vm.filteredProviders[this.activeProvider][
                            this.activeMenu
                          ]?.children?.[0]?.children[_vm.activeCategory]
                            .children,
                          function (dish) {
                            return _c(
                              "li",
                              {
                                key: dish.id,
                                staticClass: "dish__item",
                                on: {
                                  click: function ($event) {
                                    return _vm.setActiveDish(dish)
                                  },
                                },
                              },
                              [
                                _c("p", [_vm._v(_vm._s(dish.name))]),
                                dish.isBrokenCombo || dish.isEmpty
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { large: "", color: "red" },
                                          },
                                          [_vm._v("mdi-alert-circle")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                dish.children && dish.children?.length
                                  ? _c("span", [_vm._v("+Options")])
                                  : _vm._e(),
                                dish.children && dish.children?.length
                                  ? _c("span", [_vm._v("+Options")])
                                  : _vm._e(),
                                dish.isRequiredCombo
                                  ? _c("span", [_vm._v("Required")])
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    class: _vm.getDishClass(dish),
                                    attrs: {
                                      disabled:
                                        _vm.getButtonDisabledState(dish),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.setIsEnabled(dish)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getDishStatus(dish)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.activeDish &&
                !_vm.activeDish[0] &&
                _vm.activeCategory === "All disabled"
                  ? _c("div", { staticClass: "dish" }, [
                      _c(
                        "ul",
                        { staticClass: "dish__list" },
                        _vm._l(_vm.allDisabled, function (disabled) {
                          return _c(
                            "li",
                            {
                              key: disabled.dish.id,
                              staticClass: "dish__item",
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveDish(disabled.dish)
                                },
                              },
                            },
                            [
                              _c("p", [_vm._v(_vm._s(disabled.dish.name))]),
                              _c("span", [_vm._v(_vm._s(disabled.status))]),
                              _c(
                                "button",
                                {
                                  class: disabled.dish.isEnabled
                                    ? "enabled"
                                    : "disabled",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.setIsEnabled(disabled.dish)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        disabled.dish.isEnabled
                                          ? "Enabled"
                                          : "Disabled"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.activeDish &&
                !_vm.activeDish[0] &&
                _vm.activeCategory === "All disabled from POS"
                  ? _c("div", { staticClass: "dish" }, [
                      _c(
                        "ul",
                        { staticClass: "dish__list" },
                        _vm._l(_vm.allDisabledFromPOS, function (disabled) {
                          return _c(
                            "li",
                            {
                              key: disabled.dish.id,
                              staticClass: "dish__item",
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveDish(disabled.dish)
                                },
                              },
                            },
                            [
                              _c("p", [_vm._v(_vm._s(disabled.dish.name))]),
                              _c("span", [_vm._v(_vm._s(disabled.status))]),
                              _c(
                                "button",
                                {
                                  staticClass: "disabled",
                                  attrs: { disabled: "true" },
                                },
                                [_vm._v("Disabled from POS")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "disabled",
                                  attrs: { disabled: "true" },
                                },
                                [_vm._v("Disabled from POS")]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.activeDish &&
                !_vm.activeDish[0] &&
                _vm.activeCategory === "Broken Combos"
                  ? _c("div", { staticClass: "dish" }, [
                      _c(
                        "ul",
                        { staticClass: "dish__list" },
                        _vm._l(_vm.allBrokenCombos, function (broken) {
                          return _c(
                            "li",
                            {
                              key: broken.dish.id,
                              staticClass: "dish__item",
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveDish(broken.dish)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(broken.dish.name))]),
                              _c("span", [_vm._v(_vm._s(broken.status))]),
                              _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "red" } },
                                    [_vm._v("mdi-alert-circle")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "disabled",
                                  attrs: { disabled: "true" },
                                },
                                [_vm._v("Broken")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "disabled",
                                  attrs: { disabled: "true" },
                                },
                                [_vm._v("Broken")]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.activeDish &&
                !_vm.activeDish[0] &&
                _vm.activeCategory === "Options/Modifiers"
                  ? _c("div", { staticClass: "dish" }, [
                      _c(
                        "ul",
                        { staticClass: "dish__list" },
                        _vm._l(_vm.sortedModifiers, function (modifier) {
                          return _c(
                            "li",
                            {
                              key: modifier.id,
                              staticClass: "dish__item",
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveDish(modifier)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(modifier.name))]),
                              modifier.isBrokenCombo || modifier.isEmpty
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { large: "", color: "red" } },
                                        [_vm._v("mdi-alert-circle")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  class: _vm.getDishClass(modifier),
                                  attrs: {
                                    disabled:
                                      _vm.getButtonDisabledState(modifier),
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleModiEnabled(modifier)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getDishStatus(modifier)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.activeDish[0]
                  ? _c("div", [
                      _c("div", { staticClass: "disha-detail" }, [
                        _c("div", { staticClass: "disha-detail__header" }, [
                          _c(
                            "button",
                            {
                              staticClass: "disha-detail__header_back",
                              on: { click: _vm.closeDish },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require(`@/assets/icons/back.svg`),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v(" back")]),
                            ]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.activeDish[0].name))]),
                          _vm.activeDish[0].isRequiredCombo
                            ? _c("span", [_vm._v("Required")])
                            : _vm._e(),
                          _c(
                            "button",
                            {
                              class: _vm.getDishClass(_vm.activeDish[0]),
                              attrs: { disabled: "true" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.setIsEnabled(_vm.activeDish[0])
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getDishStatus(_vm.activeDish[0])) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _vm.activeDish[0].children
                          ? _c("div", [
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.activeDish[0].children,
                                  function (i) {
                                    return _c(
                                      "li",
                                      {
                                        key: i.id,
                                        staticClass: "dish__item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setActiveDish(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("p", [_vm._v(_vm._s(i.name))]),
                                        i.children && i.children?.length
                                          ? _c("span", [_vm._v("+Options")])
                                          : _vm._e(),
                                        i.isRequiredCombo
                                          ? _c("span", [_vm._v("Required")])
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            class: _vm.getDishClass(i),
                                            attrs: {
                                              disabled:
                                                _vm.getButtonDisabledState(i),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.setIsEnabled(i)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.getDishStatus(i)) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "v-card-actions" }, [
          _c(
            "div",
            { staticClass: "buttons-actions" },
            [
              _c("div", { staticClass: "up-down" }, [
                _c(
                  "button",
                  {
                    staticClass: "standart-button-content-width mr-2 px-2",
                    attrs: { styleType: "secondary", type: "button" },
                    on: { click: _vm.scrollSidebarByUp },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require(`@/assets/icons/up.svg`),
                        alt: "Scroll up",
                      },
                    }),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "standart-button-content-width mr-2 px-2",
                    attrs: { styleType: "secondary", type: "button" },
                    on: { click: _vm.scrollSidebarByDown },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require(`@/assets/icons/down.svg`),
                        alt: "Scroll down",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "up-down" }, [
                _c(
                  "button",
                  {
                    staticClass: "standart-button-content-width mr-2 px-2",
                    attrs: { styleType: "secondary", type: "button" },
                    on: { click: _vm.scrollByUp },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require(`@/assets/icons/up.svg`),
                        alt: "Scroll up",
                      },
                    }),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "standart-button-content-width mr-2 px-2",
                    attrs: { styleType: "secondary", type: "button" },
                    on: { click: _vm.scrollByDown },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require(`@/assets/icons/down.svg`),
                        alt: "Scroll down",
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", [
                _c("p", [_vm._v("Last update:")]),
                _c("span", [_vm._v(_vm._s(_vm.lastUpdateTime))]),
              ]),
              _c(
                "div",
                { staticClass: "d-flex align-center justify-end" },
                [
                  _c("Button", {
                    staticClass: "standart-button-content-width mr-2",
                    attrs: {
                      text: "Update menu",
                      type: "button",
                      isLoading: _vm.isLoading,
                    },
                    on: { handleClick: _vm.updateMenu },
                  }),
                  _c("Button", {
                    staticClass: "standart-button-content-width mr-2",
                    attrs: {
                      styleType: "secondary",
                      text: "Cancel",
                      type: "button",
                    },
                    on: { handleClick: _vm.closeModal },
                  }),
                  _c("Button", {
                    staticClass: "standart-button-content-width",
                    attrs: {
                      text: "Save",
                      type: "button",
                      isLoading: _vm.isLoading,
                    },
                    on: { handleClick: _vm.save },
                  }),
                ],
                1
              ),
              _c("VenueInstanceModal", {
                attrs: {
                  data: _vm.woltsInCorrectDiscounts,
                  showInnerModal: _vm.showInnerModal,
                },
                on: {
                  close: function ($event) {
                    _vm.showInnerModal = false
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }