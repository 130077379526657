var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c(
        "div",
        { staticClass: "standart-modal-card" },
        [
          _c(
            "v-container",
            [
              _c("h3", { staticClass: "text-center my-3" }, [
                _vm._v("Check instance statuses"),
              ]),
              _c(
                "v-row",
                [
                  _vm.availablities?.woltInstances
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        _vm._l(
                          _vm.availablities?.woltInstances,
                          function (instance) {
                            return _c("Button", {
                              key: instance.id + instance.providerName,
                              staticClass: "mb-1",
                              attrs: {
                                text: instance.providerName,
                                type: "button",
                                isLoading: _vm.isCheckingWolt,
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.checkWolt(instance.id)
                                },
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.availablities?.fudyInstances
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        _vm._l(
                          _vm.availablities?.fudyInstances,
                          function (instance) {
                            return _c("Button", {
                              key: instance.id + instance.providerName,
                              staticClass: "mb-1",
                              attrs: {
                                text: instance.providerName,
                                type: "button",
                                isLoading: _vm.isCheckingFudy,
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.checkFudy(instance.id)
                                },
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.availablities?.boltInstances
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        _vm._l(
                          _vm.availablities?.boltInstances,
                          function (instance) {
                            return _c("Button", {
                              key: instance.id + instance.providerName,
                              staticClass: "mb-1",
                              attrs: {
                                text: instance.providerName,
                                type: "button",
                                isLoading: _vm.isCheckingBolt,
                              },
                              on: {
                                handleClick: function ($event) {
                                  return _vm.checkBolt(instance.id)
                                },
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }