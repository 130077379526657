<template>
  <div>
    <div class="order-card mb-4" :id="id" :class="isStatusChanging ? 'overlay' : ''" @click="onCardClickHandler"
      :style="orderBackColor">
      <div v-if="hasErrorMessage" class="error-info-block">
        <p>{{ orderErrorMsg }}</p>
      </div>
      <div v-if="hasErrorMessage" class="error-info-detail">
        <v-row no-gutters class="error-info-detail-row" v-for="(error, idx) in Object.entries(this.order.error)"
          :key="idx">
          <v-col class="error-info-detail-col" cols="4">{{ error[0] }}</v-col>
          <v-col class="error-info-detail-col" cols="8">{{ error[1] }}</v-col>
        </v-row>
      </div>
      <v-row class="align-center">
        <v-col cols="2">
          <h3 class="order-title mb-1">{{ consumerName }}</h3>
        </v-col>

        <v-col cols="3">
          <div class="order-type d-flex align-center">
            <img :src="require(`@/assets/images/${logo}.svg`)" alt="" />
            <h4 class="text-capitalize">{{ deliveryHow }}</h4>
          </div>
        </v-col>

        <v-col cols="2">
          <h4>{{ order.status.name }}</h4>
        </v-col>

        <v-col cols="1">
          <span class="order-title d-flex justify-end">#{{ orderNumber }}</span>
        </v-col>
        <v-col cols="2">
          <span v-if="aggregator === 'boltOrder'" class="price">
            <span>
              {{ formatPrice(price) }}&nbsp;<span class="text-uppercase">{{ priceCurrency }}</span>
            </span>
          </span>
          <span v-if="aggregator === 'woltOrder' || aggregator === 'fudyOrder'">
            <span v-if="price">
              {{ formatPrice(price) }} <span class="text-uppercase">{{ priceCurrency }}</span>
            </span>
          </span>
        </v-col>
        <v-col cols="2" class="text-center">{{ orderCreatedTime }}</v-col>
      </v-row>
    </div>

    <OrderInfoModal v-model="isOrderInfoModalOpen" :order="order" :entityOrder="entityOrder" :tips="tips"
      :entityOrderFields="entityOrderFields" :orderErrorMsg="orderErrorMsg" :isPrintBtnVisible="isPrintBtnVisible"
      :courier="courier" @infoModalEvent="infoModalHandler" />
  </div>
</template>

<script>
import dayjs from '@/main.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ORDER_STATUS, ORDER_STATE, TOAST_TYPES } from '@/data';
import OrderInfoModal from '@/components/modals/OrderInfoModal.vue';
import apiMixin from '../mixins/api-mixin';
import dateFormat from '@/mixins/date-format';

export default {
  name: 'ReportOrderCard',
  components: { OrderInfoModal },
  props: {
    id: {
      type: String,
    },
    order: {
      type: Object,
    },
    status: {
      type: Number,
      default: 0,
    },
    state: {
      type: Number,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    orderErrorMsg: {
      type: String,
      default: '',
    },
    isCreatedInRKeeper: {
      type: Boolean,
    },
  },
  mixins: [apiMixin, dateFormat],
  data() {
    return {
      isOpen: false,
      orderStatus: ORDER_STATUS,
      isStatusChanging: false,
      orderState: ORDER_STATE,
      consumerName: '',
      orderNumber: '',
      consumerComment: '',
      orderItems: [],
      price: '',
      originalPrice: '',
      phone: '',
      deliveryHow: '',
      priceCurrency: '',
      courier: null,
      tips: {
        currency: '',
        value: ''
      },
      isOrderInfoModalOpen: false,
      orderCreatedTime: '',
      dateTimeFormats: {
        dateOrder: 0,
        dateSeparator: '.',
        timeFormat: 0,
      },
      entityOrder: {},
      entityOrderFields: {
        customerName: '',
        comment: '',
        phone: '',
        orderNumber: '',
        price: '',
        originalPrice: '',
        priceCurrency: '',
        pickupEta: '',
        delivery: {
          how: '',
          when: '',
        },
      },
      isCommentExist: false,
    };
  },
  mounted() {
    this.orderCreatedTime = this.formatDateTime({
      dateOrder: 1,
      dateSeparator: '.',
      timeFormat: 2,
    }, this.order.createUtc)
    //dayjs.utc(this.order.createUtc).local().format('YYYY.MM.DD HH:mm');
  },
  computed: {
    ...mapGetters({
      getCurrentVenue: 'app/getCurrentVenue',
      getRoles: 'app/getRoles',
      getGeneralSettings: 'app/getGeneralSettings',
      getCurrentRest: 'app/getCurrentRest',
    }),
    orderBackColor() {
      return this.orderState.BAD === this.state ? 'background-color: #ffe6e6' : '';
    },
    isPrintBtnVisible() {
      if (this.getRoles && this.getRoles[0]) {
        return (
          (this.getRoles[0].toLowerCase() === 'manager' || this.getRoles[0].toLowerCase() === 'operator') &&
          this.getCurrentVenue.settings &&
          this.getCurrentVenue.settings.venueSelfSettings.isPrintOrderEnabled
        );
      } else return false;
    },
    aggregator() {
      if (Object.prototype.hasOwnProperty.call(this.order, 'woltOrder')) {
        return 'woltOrder';
      } else if (Object.prototype.hasOwnProperty.call(this.order, 'fudyOrder')) {
        return 'fudyOrder'
      }
      return 'boltOrder';
    },
    logo() {
      if (Object.prototype.hasOwnProperty.call(this.order, 'woltOrder')) {
        return 'wolt';
      } else if (Object.prototype.hasOwnProperty.call(this.order, 'fudyOrder')) {
        return 'fudy'
      }
      return 'bolt';
    },
    hasErrorMessage() {
      return this.orderStatus.INCORRECT == this.colValue && this.orderErrorMsg;
    },
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        this.entityOrder = this.order[this.aggregator];

        if (this.aggregator === 'woltOrder') {
          this.consumerName = this.entityOrder.consumer_name;
          this.consumerComment = this.entityOrder.consumer_comment;
          this.orderNumber = this.entityOrder.order_number;
          this.phone = this.entityOrder.consumer_phone_number;
          this.orderItems = this.entityOrder.items;
          this.price = this.splitPrice(this.entityOrder.price ? this.entityOrder.price.amount : this.entityOrder.basket_price.total.amount);
          this.originalPrice =
            this.entityOrder.items.reduce((itemsPrice, item) => {
              const { originalPrice } = JSON.parse(item.pos_id);
              const optionsPrice = item.options.reduce((sum, option) => {
                sum += option.price.amount;
                return sum;
              }, 0);
              itemsPrice += (originalPrice * 100 + optionsPrice) * item.count;
              return itemsPrice;
            }, 0) / 100;
          this.priceCurrency = this.entityOrder.price ? this.entityOrder.price.currency : this.entityOrder.basket_price.total.currency;
          this.deliveryHow = this.entityOrder.delivery.type;
          this.deliveryWhen = this.entityOrder.type;
          this.modifiedTime = this.entityOrder.modified_at;
        }

        if (this.aggregator === 'fudyOrder') {
          this.consumerName = this.entityOrder.consumer_name;
          this.consumerComment = this.entityOrder.consumer_comment;
          this.orderNumber = this.entityOrder.order_number;
          this.phone = this.entityOrder.consumer_phone_number;
          this.orderItems = this.entityOrder.items;
          this.price = this.splitPrice(this.entityOrder.price.amount);
          this.originalPrice =
            this.entityOrder.items.reduce((itemsPrice, item) => {
              const { originalPrice } = JSON.parse(item.pos_id);
              const optionsPrice = item.options.reduce((sum, option) => {
                sum += option.price.amount;
                return sum;
              }, 0);
              itemsPrice += (originalPrice * 100 + optionsPrice) * item.count;
              return itemsPrice;
            }, 0) / 100;
          this.priceCurrency = this.entityOrder.price.currency;
          this.deliveryHow = this.entityOrder.delivery.type;
          this.deliveryWhen = this.entityOrder.type;
          this.modifiedTime = this.entityOrder.modified_at;
        }

        if (this.aggregator === 'boltOrder') {
          this.consumerName = this.entityOrder.customer.partial_name;
          this.consumerComment = this.entityOrder.user_note;
          this.orderNumber = this.entityOrder.order_reference_id;
          this.phone = this.entityOrder.customer.phone;
          this.orderItems = this.entityOrder.items;
          this.price = this.entityOrder.total_order_price.value;
          this.originalPrice = this.entityOrder.total_order_price.original_value;
          this.priceCurrency = this.entityOrder.total_order_price.currency;
          this.deliveryHow = this.entityOrder.order_type;
          this.modifiedTime = this.entityOrder.created_datetime;
          this.deliveryWhen = '';
          this.courier = Object.prototype.hasOwnProperty.call(this.entityOrder, 'courier')
            ? this.entityOrder.courier
            : null;
          this.tips.value = this?.order?.orderExtraProperties?.markIsPaidFromBolt?.waiter_tip?.value || ''
          this.tips.currency = this?.order?.orderExtraProperties?.markIsPaidFromBolt?.waiter_tip?.currency || ''
        }

        this.entityOrderFields.customerName = this.consumerName;
        this.entityOrderFields.comment = this.consumerComment;
        this.entityOrderFields.orderNumber = this.orderNumber;
        this.entityOrderFields.phone = this.phone;
        this.entityOrderFields.orderItems = this.orderItems;
        this.entityOrderFields.price = this.price;
        this.entityOrderFields.originalPrice = this.originalPrice;
        this.entityOrderFields.priceCurrency = this.priceCurrency;
        this.entityOrderFields.delivery.how = this.deliveryHow;
        this.entityOrderFields.delivery.when = this.deliveryWhen;

        if (this.consumerComment) {
          this.isCommentExist = true;
        } else {
          if (this.aggregator === 'boltOrder') {
            let doesItemHaveComment = this.orderItems.some(item => !!item.user_note);
            this.isCommentExist = doesItemHaveComment;
          }
        }
      },
    },
    isLoading(val) {
      this.isStatusChanging = val;
    },
    courier(val) {
      if (val) {
        this.courier = val;
      }
    },
  },
  methods: {
    ...mapActions('orders', ['printOrder']),
    ...mapMutations('orders', ['updateOrder']),
    splitPrice(value) {
      return (value / 100).toFixed(2);
    },
    getItemPrice(item) {
      if (this.aggregator === 'woltOrder') {
        if (item.total_price) {
          return `${this.splitPrice(item.total_price.amount)} ${item.total_price.currency}`;
        }
        return `${this.splitPrice(item.item_price.total.amount)} ${item.item_price.total.currency}`;
      }
      if (this.aggregator === 'fudyOrder') {
        return `${this.splitPrice(item.total_price.amount)} ${item.total_price.currency}`;
      }
      if (this.aggregator === 'boltOrder') {
        return `${this.format(item.unit_item_price.value)} ${item.unit_item_price.currency}`;
      }
    },
    getOptionPrice(option) {
      if (this.aggregator === 'woltOrder' || this.aggregator === 'fudyOrder') {
        return `${this.splitPrice(option.price.amount)} ${option.price.currency}`;
      }
      if (this.aggregator === 'boltOrder') {
        return `${this.formatPrice(option.unit_item_price.value)} ${option.unit_item_price.currency}`;
      }
    },

    infoModalHandler(event) {
      this[event]();
      this.isOrderInfoModalOpen = false;
    },
    onPrint() {
      this.printOrder(this.id).then(() => {
        this.$toast.open({ message: 'Successfully sent to print!', type: TOAST_TYPES.SUCCESS });
      });
    },
    onCardClickHandler() {
      this.isOrderInfoModalOpen = !this.isOrderInfoModalOpen;
    },
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 24px 32px;
}

@media screen and (max-width: 1536px) {
  .order-card {
    padding: 16px;
  }
}

.order-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 0.15px;
}

.order-phone {
  color: #636363;
}

.order-comment {
  position: relative;
  background-color: #333;
  color: #f6f5fa;
  padding: 12px 16px;
  border-radius: 10px;
  font-size: .875rem;
  letter-spacing: 0.3px;

  &:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    top: -10px;
    left: 40px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #333;
  }
}

.order-type {
  display: inline-block;
  text-align: center;
  color: #333;
  letter-spacing: 0.15px;

  &-icon {
    display: inline-block;
    margin-bottom: 10px;

    i {
      color: #333;
    }
  }

  h4 {
    font-weight: 400;
    display: inline-block;
    margin-left: 10px;
  }

  &-closed {
    h4 {
      margin-left: 10px;
    }
  }
}

.price i {
  position: relative;
  color: gray;

  &::after {
    border-bottom: 0.2em solid gray;
    content: '';
    left: 0;
    margin-top: calc(0.2em / 2 * -1);
    position: absolute;
    right: 0;
    top: 50%;
    rotate: -5deg;
  }
}

.order-status-tag {
  font-weight: 400;
  font-size: .75rem;
  color: #fff;
  background-color: #02b66f;
  padding: 4px 8px;
  border-radius: 24px;
}

.error-info-block {
  background-color: #f9302c;
  color: #fff;
  font-weight: 500;
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 16px;
  height: 30px;

  p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

.error-info-detail {
  &-row {
    margin-bottom: 10px;
  }

  &-col {
    font-weight: bold;
  }
}
</style>
