var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c("h2", { staticClass: "sidebar-title mt-2 mb-6" }, [
        _vm._v(_vm._s(this.pageName)),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "h2",
                { staticClass: "sidebar-title sidebar-title-small mb-6" },
                [_vm._v("Restaurant groups")]
              ),
              _vm.getUserData.roleNames[0].toLowerCase() === "admin"
                ? _c("v-text-field", {
                    staticClass: "standart-input mt-3",
                    attrs: {
                      solo: "",
                      label: "Find restaurant group",
                      flat: "",
                      "prepend-inner-icon": "mdi-magnify",
                      type: "text",
                    },
                    on: { input: _vm.handleAreaSearch },
                  })
                : _vm._e(),
              _c("GroupTab", {
                attrs: {
                  list: _vm.areas,
                  isArea: false,
                  activeTabId: _vm.activeAreaId,
                  showDots: false,
                },
                on: { tabClick: _vm.tabClick },
              }),
            ],
            1
          ),
          _c("v-col", [
            _c(
              "div",
              { staticClass: "standart-card" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "col-lg-10 col-9" },
                      [
                        _c("v-text-field", {
                          staticClass: "standart-input",
                          attrs: {
                            solo: "",
                            label: `Find ${this.pageNameSingular}`,
                            flat: "",
                            "prepend-inner-icon": "mdi-magnify",
                            type: "text",
                          },
                          on: { input: _vm.handleSearch },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "standart-button standart-button-secondary",
                            attrs: {
                              to: _vm.isLoading
                                ? "#"
                                : `/${this.pageNameSingular}/new`,
                            },
                          },
                          [_vm._v("New " + _vm._s(this.pageNameSingular))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("EntityList", {
                  attrs: {
                    isLoading: _vm.isEntitiesLoading,
                    list: _vm.entityList,
                    isSearched: _vm.isSearched,
                    isShowEmail: true,
                    detailUrl: this.pageNameSingular,
                    groupId: this.getActiveAreaId,
                  },
                  on: { handleDelete: _vm.deleteEntityItem },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }