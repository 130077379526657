var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", "overlay-opacity": "0.8" },
      model: {
        value: _vm.syncedOpened,
        callback: function ($$v) {
          _vm.syncedOpened = $$v
        },
        expression: "syncedOpened",
      },
    },
    [
      _c("div", { staticClass: "standart-modal-card" }, [
        _c(
          "div",
          {
            staticClass:
              "standart-modal-card-top d-flex align-center justify-space-between",
          },
          [
            _c("h3", [_vm._v("Provider status settings")]),
            _c(
              "v-btn",
              {
                attrs: { icon: "", color: "black" },
                on: { click: _vm.closeModal },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "standart-modal-card-middle" }, [
          _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "d-flex justify-space-between" },
                [
                  _vm.availablities?.woltInstances
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        _vm._l(
                          _vm.availablities.woltInstances,
                          function (instance, index) {
                            return _c(
                              "div",
                              {
                                key: instance.id + instance.providerName,
                                staticClass: "status-tag mb-1",
                              },
                              [
                                _c("div", {
                                  staticClass: "status-circle",
                                  style: `background-color: ${_vm.statusColor(
                                    "wolt",
                                    index
                                  )}`,
                                }),
                                _c("h3", [
                                  _vm._v(_vm._s(instance.providerName)),
                                ]),
                                _c("Button", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    text: instance.isAvailable
                                      ? "Close "
                                      : "Open ",
                                    type: "button",
                                  },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.changeVenueStatus(
                                        1,
                                        !instance.isAvailable,
                                        instance.id
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.availablities?.boltInstances
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        _vm._l(
                          _vm.availablities.boltInstances,
                          function (instance, index) {
                            return _c(
                              "div",
                              {
                                key: instance.id + instance.providerName,
                                staticClass: "status-tag mb-1",
                              },
                              [
                                _c("div", {
                                  staticClass: "status-circle",
                                  style: `background-color: ${_vm.statusColor(
                                    "bolt",
                                    index
                                  )}`,
                                }),
                                _c("h3", [
                                  _vm._v(_vm._s(instance.providerName)),
                                ]),
                                _c("Button", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    text: instance.isAvailable
                                      ? "Close "
                                      : "Open ",
                                    type: "button",
                                  },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.changeVenueStatus(
                                        2,
                                        !instance.isAvailable,
                                        instance.id
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm.availablities?.glovoInstances
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        _vm._l(
                          _vm.availablities.glovoInstances,
                          function (instance, index) {
                            return _c(
                              "div",
                              {
                                key: instance.id + instance.providerName,
                                staticClass: "status-tag mb-1",
                              },
                              [
                                _c("div", {
                                  staticClass: "status-circle",
                                  style: `background-color: ${_vm.statusColor(
                                    "glovo",
                                    index
                                  )}`,
                                }),
                                _c("h3", [
                                  _vm._v(_vm._s(instance.providerName)),
                                ]),
                                _c("Button", {
                                  staticClass: "mb-1",
                                  attrs: {
                                    text: instance.isAvailable
                                      ? "Close "
                                      : "Open ",
                                    type: "button",
                                  },
                                  on: {
                                    handleClick: function ($event) {
                                      return _vm.changeVenueStatus(
                                        4,
                                        !instance.isAvailable,
                                        instance.id
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "standart-modal-card-bottom" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-end" },
            [
              _c("Button", {
                staticClass: "standart-button-content-width mr-2",
                attrs: {
                  styleType: "secondary",
                  text: "Cancel",
                  type: "button",
                },
                on: { handleClick: _vm.closeModal },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }