var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("h2", { staticClass: "sidebar-title mb-6" }, [
                _vm._v("Restaurants"),
              ]),
              _vm.getUserData.roleNames?.[0].toLowerCase() === "admin"
                ? _c("Button", {
                    staticClass: "mb-3",
                    attrs: {
                      styleType: "secondary",
                      text: "New restaurant group",
                    },
                    on: { handleClick: _vm.openAreaModal },
                  })
                : _vm._e(),
              _c("Button", {
                staticClass: "mb-3",
                attrs: {
                  styleType: "secondary",
                  text: "New restaurant subgroup",
                },
                on: { handleClick: _vm.openModal },
              }),
              _vm.getUserData.roleNames?.[0].toLowerCase() === "admin"
                ? _c("v-text-field", {
                    staticClass: "standart-input mt-3",
                    attrs: {
                      solo: "",
                      label: "Find restaurant group",
                      flat: "",
                      "prepend-inner-icon": "mdi-magnify",
                      type: "text",
                    },
                    on: { input: _vm.handleAreaSearch },
                  })
                : _vm._e(),
              _c("v-text-field", {
                staticClass: "standart-input",
                attrs: {
                  solo: "",
                  label: "Find restaurant subgroup",
                  flat: "",
                  "prepend-inner-icon": "mdi-magnify",
                  type: "text",
                },
                on: { input: _vm.handleVenueGroupSearch },
              }),
              _c("GroupTab", {
                attrs: {
                  isArea: _vm.isArea,
                  list: _vm.isArea ? _vm.getAreasList : _vm.getVenueGroupsData,
                  activeFolderId: _vm.activeAreaId,
                  activeTabId: _vm.activeVenueGroupId,
                  showDots: true,
                },
                on: {
                  getAreasData: _vm.getAreasData,
                  getVenueGroups: _vm.getVenueGroups,
                  folderClick: _vm.folderClick,
                  tabClick: _vm.tabClick,
                },
              }),
            ],
            1
          ),
          _c("v-col", [
            _c(
              "div",
              { staticClass: "standart-card mt-15" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "col-lg-10 col-9" },
                      [
                        _c("v-text-field", {
                          staticClass: "standart-input",
                          attrs: {
                            solo: "",
                            label: "Find restaurant",
                            flat: "",
                            "prepend-inner-icon": "mdi-magnify",
                            type: "text",
                          },
                          on: { input: _vm.handleVenueSearch },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "standart-button standart-button-secondary",
                            attrs: { to: _vm.isLoading ? "#" : "/venue/new" },
                          },
                          [_vm._v("New restaurant")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("EntityList", {
                  attrs: {
                    isLoading: _vm.isVenueLoading,
                    list: _vm.entityList,
                    isSearched: _vm.isSearched,
                    detailUrl: "venue",
                    groupId: _vm.getActiveVenueGroupId,
                  },
                  on: { handleDelete: _vm.deleteEntityItem },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("Modal", {
        attrs: { areas: _vm.getAreasList, title: "New restaurant subgroup" },
        on: { saveForm: _vm.submitVenueGroup },
        model: {
          value: _vm.isModalOpen,
          callback: function ($$v) {
            _vm.isModalOpen = $$v
          },
          expression: "isModalOpen",
        },
      }),
      _c("AreaModal", {
        attrs: { title: "New restaurant group" },
        on: { saveAreaForm: _vm.submitArea },
        model: {
          value: _vm.isAreaModalOpen,
          callback: function ($$v) {
            _vm.isAreaModalOpen = $$v
          },
          expression: "isAreaModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }