var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: _vm.isVisible ? `standart-button ${_vm.buttonType}` : `hidden`,
      attrs: { disabled: _vm.isDisabled },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isLoading
        ? [_c("span", [_vm._v(_vm._s(_vm.text))])]
        : _c("v-progress-circular", {
            staticClass: "button-loader",
            attrs: { indeterminate: "", color: "white" },
          }),
      _vm.icon
        ? [
            _c("v-icon", { attrs: { color: "white" } }, [
              _vm._v(_vm._s(_vm.icon)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }