var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          position: "relative",
          top: "40%",
          "text-align": "center",
        },
      },
      [_c("h1", [_vm._v("Redirect ...")]), _c("h1", [_vm._v("Please wait")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }