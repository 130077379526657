var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-list" },
    [
      !_vm.isTabSelected
        ? _c("div", [
            _c("h3", { staticClass: "info-title" }, [
              _vm._v("Select restaurant or restaurant group"),
            ]),
          ])
        : _vm._e(),
      _vm.list.length < 1 && _vm.isTabSelected
        ? _c("div", [
            _vm.isSearched
              ? _c("h3", { staticClass: "info-title" }, [
                  _vm._v("Sorry, we couldn't find any results"),
                ])
              : _c("h3", { staticClass: "info-title" }, [
                  _vm._v("No data in this group"),
                ]),
          ])
        : _vm._e(),
      [
        _vm.list.length > 0
          ? _c(
              "div",
              { staticClass: "entity-list-header" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "entity-list-header-row align-center",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c("v-col", { staticClass: "checkbox-col" }, [
                      _c(
                        "div",
                        { staticClass: "entity-list-header-col" },
                        [
                          _c("v-checkbox", {
                            staticClass: "standart-checkbox entity-checkbox",
                            attrs: { large: "" },
                            on: { click: _vm.selectAll },
                            model: {
                              value: _vm.isCheckedAll,
                              callback: function ($$v) {
                                _vm.isCheckedAll = $$v
                              },
                              expression: "isCheckedAll",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("v-col", [
                      _c("div", { staticClass: "entity-list-header-col" }, [
                        _vm.isShowEmail
                          ? _c(
                              "span",
                              { staticClass: "entity-list-item-title" },
                              [_vm._v("Login")]
                            )
                          : _vm._e(),
                        !_vm.isShowEmail
                          ? _c(
                              "span",
                              { staticClass: "entity-list-item-title" },
                              [_vm._v("Restaurant name")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.isShowEmail
                      ? _c("v-col", { attrs: { cols: "7" } }, [
                          _c("div", { staticClass: "entity-list-header-col" }, [
                            _c("span", [_vm._v("Email")]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "entity-list-item" },
            [
              _c(
                "v-row",
                { staticClass: "align-center", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "checkbox-col" },
                    [
                      _c("v-checkbox", {
                        staticClass: "standart-checkbox entity-checkbox",
                        attrs: { value: item.id, large: "" },
                        model: {
                          value: _vm.checkedItems,
                          callback: function ($$v) {
                            _vm.checkedItems = $$v
                          },
                          expression: "checkedItems",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "entity-list-item-title",
                          attrs: { to: `${_vm.detailUrl}/${item.id}` },
                        },
                        [_vm._v(_vm._s(item.name || item.userName))]
                      ),
                    ],
                    1
                  ),
                  !_vm.isShowEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "2" },
                        },
                        [
                          _c("span", { staticClass: "entity-list-item-date" }, [
                            _vm._v("Wolt exp. date: "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatDateTime(
                                    _vm.dateTimeFormats,
                                    item.settings.venueSelfSettings
                                      .woltExpiredDateUtc
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isShowEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "2" },
                        },
                        [
                          _c("span", { staticClass: "entity-list-item-date" }, [
                            _vm._v("Fudy exp. date: "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatDateTime(
                                    _vm.dateTimeFormats,
                                    item.settings.venueSelfSettings
                                      .fudyExpiredDateUtc
                                  )
                                )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isShowEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "2" },
                        },
                        [
                          _c("span", { staticClass: "entity-list-item-date" }, [
                            _vm._v("Bolt exp. date: "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatDateTime(
                                    _vm.dateTimeFormats,
                                    item.settings.venueSelfSettings
                                      .boltExpiredDateUtc
                                  )
                                )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.isShowEmail
                    ? _c("v-col", { attrs: { cols: "2" } }, [
                        _c("span", [_vm._v(_vm._s(item.email))]),
                      ])
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end" },
                    [
                      _vm.$route.name === "venues"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              staticStyle: { float: "right" },
                              attrs: { loading: _vm.isLoading },
                              on: {
                                click: function ($event) {
                                  return _vm.openMenuModal(item.id)
                                },
                              },
                            },
                            [_vm._v("Update menus")]
                          )
                        : _vm._e(),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            auto: "",
                            transition: "none",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "", icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-vertical"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm.$route.name === "venues"
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "list-item-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            `new-orders-id/${item.id}`
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Orders ")]
                                  )
                                : _vm._e(),
                              _vm.$route.name !== "venues"
                                ? _c(
                                    "v-list-item",
                                    {
                                      staticClass: "red--text list-item-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(item.id)
                                        },
                                      },
                                    },
                                    [_vm._v(" Delete ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
      ],
      _c("MenuModal", {
        attrs: { venueId: _vm.venueId },
        model: {
          value: _vm.isMenuModalShow,
          callback: function ($$v) {
            _vm.isMenuModalShow = $$v
          },
          expression: "isMenuModalShow",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }