<template>
  <v-app class="app-wrapper">
    <v-main>
      <div class="preloader" v-if="showProgress">
        <v-progress-circular indeterminate :size="70" :width="7" color="primary" />
      </div>
      <transition>
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { HUB_RETRY_DELAY, TOAST_TYPES } from '@/data'

export default {
  name: 'App',
  data: () => ({
    showNotAssignedAlert: false,
    showExpiredAlert: false,
    notAssignedAlertMsg: '',
    expiredAlertMsg: '',
    allowAlert: true,
  }),
  async mounted() {
    await this.$orderHub.start()

    this.$orderHub.client.on('ForceRefreshPage', async () => {
      console.log('ForceRefreshPage in 5 second')
      this.reloadPage()
    });
  },
  beforeDestroy() {
    this.$orderHub.client.off('ForceRefreshPage');
    this.$orderHub.stop()
  },
  watch: {
    getCurrentVenue: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.checkAndUpdateLicense();
          this.changeShowExpMsg(true);
        }
      },
    },
    '$route.name': {
      handler(val) {
        if (val === 'login' || val === null) {
          this.allowAlert = false;
        } else {
          this.allowAlert = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getCurrentVenue: 'app/getCurrentVenue',
      getShowExpMsg: 'app/getShowExpMsg',
    }),
    showProgress() {
      return this.getLoading;
    },
    isInitApp() {
      return this.getInit;
    },
    getUserAuth() {
      return this.getAuth;
    },
  },
  methods: {
    ...mapMutations({
      changeShowExpMsg: 'app/changeShowExpMsg',
    }),
    reloadPage() {
      setTimeout(async () => {
        try {
          // Удаление всех зарегистрированных Service Workers
          if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (const registration of registrations) {
              await registration.unregister();
            }
            console.log('Service Worker cache cleared.');
          }

          // Очистка кэша через Cache Storage API
          if ('caches' in window) {
            const cacheNames = await caches.keys();
            for (const cacheName of cacheNames) {
              await caches.delete(cacheName);
            }
            console.log('Browser cache cleared.');
          }

          // Очистка localStorage, кроме версии
          const version = localStorage.getItem('version'); // Сохраняем версию
          localStorage.clear(); // Полностью очищаем localStorage
          if (version) {
            localStorage.setItem('version', version); // Восстанавливаем версию
          }
          console.log('LocalStorage cleared, version preserved.');

          // Полная очистка cookies
          // const cookies = document.cookie.split(';');
          // for (const cookie of cookies) {
          //   const [key] = cookie.split('=');
          //   const trimmedKey = key.trim();

          //   // Удаление cookie
          //   document.cookie = `${trimmedKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          // }
          // console.log('All cookies cleared.');

          // Перезагрузка страницы с обходом кэша
          window.location.href = window.location.origin + `?timestamp=${Date.now()}`;
        } catch (error) {
          console.error('Failed to clear cache:', error);
        }

      }, HUB_RETRY_DELAY)
    },
    checkAndUpdateLicense() {
      const services = [
        { name: 'Wolt', isActive: this.getCurrentVenue.woltSettings?.some(i => i.isEnabled), isLicensed: this.getCurrentVenue.isWoltLicensed, hasLicenseDate: Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings?.venueSelfSettings, 'woltExpiredDateUtc') },
        { name: 'Bolt', isActive: this.getCurrentVenue.boltSettings?.some(i => i.isEnabled), isLicensed: this.getCurrentVenue.isBoltLicensed, hasLicenseDate: Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings?.venueSelfSettings, 'boltExpiredDateUtc') },
        { name: 'Fudy', isActive: this.getCurrentVenue.fudySettings?.some(i => i.isEnabled), isLicensed: this.getCurrentVenue.isFudyLicensed, hasLicenseDate: Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings?.venueSelfSettings, 'fudyExpiredDateUtc') },
        { name: 'Glovo', isActive: this.getCurrentVenue.glovoSettings?.some(i => i.isEnabled), isLicensed: this.getCurrentVenue.isFudyLicensed, hasLicenseDate: Object.prototype.hasOwnProperty.call(this.getCurrentVenue.settings?.venueSelfSettings, 'glovoExpiredDateUtc') }
      ];

      const expiredServices = services
        .filter(service => service.isActive && !service.isLicensed)
        .map(service => service.name);

      if (expiredServices.length > 0 && this.allowAlert && this.getShowExpMsg) {
        this.$toast.open({ message: `${expiredServices.join(', ')} license${expiredServices.length > 1 ? 's are' : ' is'} expired!`, type: TOAST_TYPES.ERROR, duration: 5000 });
      }

      const notAssignedServices = services
        .filter(service => service.isActive && !service.hasLicenseDate)
        .map(service => service.name);

      if (notAssignedServices.length > 0 && this.allowAlert && this.getShowExpMsg) {
        this.$toast.open({ message: `${notAssignedServices.join(', ')} license${notAssignedServices.length > 1 ? 's are' : ' is'} not assigned!`, type: TOAST_TYPES.ERROR, duration: 5000 });
      }
    },
    changeExpLicenseHandler() {
      this.changeShowExpMsg(false);
    },
  },
};
</script>

<style lang="scss">
//1024 * 768
.main-header {
  .v-toolbar__content {
    border-bottom: 1px solid #b4b4b4;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
</style>
