<template>
  <v-dialog v-model="syncedOpened" width="480" overlay-opacity="0.8">
    <div class="standart-modal-card">
      <div class="standart-modal-card-top d-flex align-center justify-space-between">
        <h3>Are you sure?</h3>
        <v-btn @click="closeModal" icon color="black"><v-icon>mdi-close</v-icon> </v-btn>
      </div>
      <div class="standart-modal-card-middle">
        <p>
          Pre-order is due {{ date }} , after {{ minutes }} minutes, are you sure you want to move it to
          production
          now?
        </p>
      </div>
      <div class="standart-modal-card-bottom">
        <div class="d-flex align-center justify-end">
          <Button styleType="secondary" text="No" class="standart-button-content-width mr-2" type="button"
                  @handleClick="closeModal" />
          <Button styleType="primary" text="Yes" class="standart-button-content-width mr-2" type="button"
                  @handleClick="handler" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';
import dayjs from 'dayjs'

export default {
  name: 'ConfirmationPreorderModal.vue',
  components: {
    Button,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: Boolean,
    isLoading: Boolean,
    time: String,
  },
  data() {
    return {

    };
  },
  computed: {
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
    minutes() {
      return dayjs(this.time).diff(dayjs(), 'm')
    },
    date() {
      return dayjs(this.time).format('DD.MM.YYYY HH:mm')
    }
  },
  methods: {
    closeModal() {
      this.syncedOpened = false;
    },
    handler() {
      this.$emit('confirmPreorder')
      this.closeModal();
    }
  },
};
</script>

<style lang="scss" scoped></style>