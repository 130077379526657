import { mapGetters } from 'vuex';
import dayjs from '@/main.js';
import { UNLIMITED_LICENSE_TIME } from '@/data';

export default {
  data() {
    return {
      isStatusChanging: false,
    };
  },
  mounted() {
    this.setDateTimeFormats();
  },
  computed: {
    ...mapGetters({
      getGeneralSettings: 'app/getGeneralSettings',
    }),
  },
  watch: {
    getGeneralSettings() {
      this.setDateTimeFormats();
    },
  },
  methods: {
    setDateTimeFormats() {
      this.dateTimeFormats.dateOrder = this.getGeneralSettings.dateOrder;
      this.dateTimeFormats.dateSeparator = this.getGeneralSettings.dateSeparator;
      this.dateTimeFormats.timeFormat = this.getGeneralSettings.timeFormat;
    },
    isDateUnlimited(date) {
      return dayjs(date).isSame(dayjs(UNLIMITED_LICENSE_TIME));
    },
    formatDateTime(format, date) {
      if (!date || this.isDateUnlimited(date)) {
        return 'Unlimited';
      }
      const lastChar = date[date.length - 1];
      if (lastChar != 'Z') {
        date += 'Z';
      }
      const { dateSeparator, dateOrder, timeFormat } = format;

      const formattedDate = dayjs(new Date(date)).format(timeFormat === 1 ? 'hh:mm A' : 'HH:mm');
      const isSameDay = dayjs().isSame(dayjs(date), 'day');
      if (!isSameDay) {
        let dateToOutput = dayjs(date).format(
          dateOrder === 1 ? `DD${dateSeparator}MM${dateSeparator}YYYY` : `MM${dateSeparator}DD${dateSeparator}YYYY`,
        );
        return `${dateToOutput}, ${formattedDate}`;
      } else {
        return formattedDate;
      }
    },
    formatLicenseDate(format, date) {
      // if (!date) {
      //   return '';
      // }
      const { dateSeparator, dateOrder } = format;
      const lastChar = date[date.length - 1];
      if (lastChar != 'Z') {
        date += 'Z';
      }
      const dateFormat =
        dateOrder === 1 ? `DD${dateSeparator}MM${dateSeparator}YYYY` : `MM${dateSeparator}DD${dateSeparator}YYYY`;
      return dayjs(date).format(dateFormat);
    },
    licenseStatusFromDate(date) {
      if (this.isDateUnlimited(date)) {
        return 'Unlimited';
      } else if (dayjs(date).isBefore(dayjs())) {
        return 'Expired';
      } else if (dayjs().isBefore(dayjs(date))) {
        return 'Active Limited';
      } else {
        return 'No License';
      }
    },
  },
};
