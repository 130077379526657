var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "justify-center mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "v-row",
                { staticClass: "justify-space-between mb-3" },
                [
                  _c("v-col", [
                    _c("h2", { staticClass: "display-1 mb-3" }, [
                      _vm._v("Admin List"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "2",
                            color: "primary",
                            large: "",
                            exact: "",
                            to: "/admin-detail/new",
                          },
                        },
                        [_vm._v("Add admin")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-4", attrs: { elevation: "2" } },
                _vm._l(_vm.getList, function (adminItem) {
                  return _c(
                    "v-row",
                    { key: adminItem.id, staticClass: "align-center" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: `/admin-detail/${adminItem.id}` } },
                            [_vm._v(_vm._s(adminItem.userName))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }