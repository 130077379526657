var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-2" },
      _vm._l(_vm.orderItems, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "order-product-item mb-2" },
          [
            _c("span", [
              _vm._v(_vm._s(item.count || item.qty || item.quantity) + " x "),
            ]),
            _c("span", [_vm._v(_vm._s(item.name) + " ")]),
            _vm.aggregator === "wolt"
              ? _c(
                  "span",
                  { staticClass: "text-uppercase order-product-item-price" },
                  [
                    _vm.getOriginalItemPrice(item) >
                    _vm.splitPrice(
                      item.unit_price
                        ? item.unit_price.amount
                        : item.item_price.total.amount
                    )
                      ? _c("i", [
                          _vm._v(
                            "  " + _vm._s(_vm.getOriginalItemPrice(item)) + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "  " +
                        _vm._s(
                          _vm.splitPrice(
                            item.unit_price
                              ? item.unit_price.amount
                              : item.item_price.total.amount
                          )
                        ) +
                        " " +
                        _vm._s(
                          item.unit_price
                            ? item.unit_price.currency
                            : item.item_price.total.currency
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.aggregator === "fudy"
              ? _c(
                  "span",
                  { staticClass: "text-uppercase order-product-item-price" },
                  [
                    _vm.getOriginalItemPrice(item) >
                    _vm.splitPrice(item.unit_price.amount)
                      ? _c("i", [
                          _vm._v(
                            "  " + _vm._s(_vm.getOriginalItemPrice(item)) + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      "  " +
                        _vm._s(_vm.splitPrice(item.unit_price.amount)) +
                        " " +
                        _vm._s(item.total_price.currency) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.aggregator === "bolt"
              ? _c(
                  "span",
                  { staticClass: "text-uppercase order-product-item-price" },
                  [
                    item.unit_item_price?.original_value
                      ? _c("i", [
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.formatPrice(
                                  item.unit_item_price.original_value
                                )
                              ) +
                              "   "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatPrice(item.unit_item_price.value)) +
                        " " +
                        _vm._s(item.unit_item_price.currency) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.aggregator === "glovo"
              ? _c(
                  "span",
                  { staticClass: "text-uppercase order-product-item-price" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatPrice(item.price)) +
                        " " +
                        _vm._s(_vm.glovoCurrency) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            item.user_note
              ? _c("div", { staticClass: "order-product-item-comment" }, [
                  _vm._v(_vm._s(item.user_note)),
                ])
              : _vm._e(),
            item?.options?.length > 0 || item?.attributes?.length
              ? [
                  _c("p", { staticClass: "pl-4 mt-2 mb-2" }, [
                    _vm._v("OPTIONS:"),
                  ]),
                  item?.options?.length
                    ? _c(
                        "div",
                        _vm._l(item?.options, function (option) {
                          return _c(
                            "p",
                            { key: option.id, staticClass: "pl-4 mt-2 mb-2" },
                            [
                              !(
                                /\d\sx\s/.test(option.value) ||
                                /\d\sx\s/.test(option.name)
                              )
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(option.count || option.qty) + " x "
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(_vm._s(option.value || option.name)),
                              ]),
                              _vm.aggregator === "wolt" ||
                              _vm.aggregator === "fudy"
                                ? _c(
                                    "span",
                                    { staticClass: "text-uppercase" },
                                    [
                                      _vm._v(
                                        " (" +
                                          _vm._s(
                                            _vm.splitPrice(option.price.amount)
                                          ) +
                                          " " +
                                          _vm._s(option.price.currency) +
                                          ") "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.aggregator === "bolt"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-uppercase order-product-option-price",
                                    },
                                    [
                                      _vm._v(" ( "),
                                      option.unit_item_price.original_value
                                        ? _c("i", [
                                            _vm._v(
                                              "  " +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    option.unit_item_price
                                                      .original_value
                                                  )
                                                ) +
                                                "   "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPrice(
                                              option.unit_item_price.value
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            option.unit_item_price.currency
                                          ) +
                                          " ) "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  item?.attributes
                    ? _c(
                        "div",
                        _vm._l(item?.attributes, function (option) {
                          return _c(
                            "p",
                            { key: option.id, staticClass: "pl-4 mt-2 mb-2" },
                            [
                              !(
                                /\d\sx\s/.test(option.value) ||
                                /\d\sx\s/.test(option.name)
                              )
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        option.count ||
                                          option.qty ||
                                          option.quantity
                                      ) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c("span", [
                                _vm._v(_vm._s(option.value || option.name)),
                              ]),
                              _vm.aggregator === "glovo"
                                ? _c(
                                    "span",
                                    { staticClass: "text-uppercase" },
                                    [
                                      _vm._v(
                                        " (" +
                                          _vm._s(_vm.splitPrice(option.price)) +
                                          " " +
                                          _vm._s(_vm.glovoCurrency) +
                                          ") "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
    _c(
      "p",
      { staticClass: "mt-2 d-flex justify-space-between order-price-items" },
      [
        _vm.orderItems
          ? _c("span", [
              _vm._v(_vm._s(_vm.orderItems?.length) + " "),
              _vm.orderItems.length < 2
                ? _c("span", [_vm._v("item")])
                : _c("span", [_vm._v("items")]),
            ])
          : _vm._e(),
        +_vm.originalPrice > 0 && +_vm.originalPrice > _vm.price
          ? _c("span", { staticClass: "order-product-item-price" }, [
              _c("i", [
                _vm._v(" " + _vm._s(_vm.formatPrice(_vm.originalPrice)) + " "),
              ]),
              _vm._v(" " + _vm._s(_vm.formatPrice(_vm.price)) + " "),
              _c("span", { staticClass: "text-uppercase" }, [
                _vm._v(_vm._s(_vm.priceCurrency)),
              ]),
            ])
          : _vm.price
          ? _c("span", [
              _vm._v(" " + _vm._s(_vm.formatPrice(_vm.price)) + " "),
              _vm.glovoCurrency
                ? _c("span", { staticClass: "text-uppercase" }, [
                    _vm._v(_vm._s(_vm.glovoCurrency)),
                  ])
                : _c("span", [_vm._v("priceCurrency")]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }